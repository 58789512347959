import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { useTranslations } from "next-intl";
import FetchTriggerNodesInput from "features/admin/components/EditTriggerNodes/FetchTriggerNodesInput";
import { useStoreState } from "store/hooks";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TriggerNodesEditAndSelectionDialog from "features/admin/components/EditTriggerNodes/TriggerNodesEditAndSelectionDialog";
import Button from "@mui/material/Button";
import { setTriggerNodesFetchedBySentence } from "store/actions/admin";
import { useDispatch } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

type EditTriggerNodesProps = { onChange: (data: any) => void };

const EditTriggerNodes = ({ onChange }: EditTriggerNodesProps) => {
  const t = useTranslations();
  const triggerNodesFetchedBySentence = useStoreState(
    (state) => state.triggerNodesFetchedBySentence
  );
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [sentence, setSentence] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelButton = () => {
    dispatch(setTriggerNodesFetchedBySentence(null));
  };

  useEffect(() => {
    return () => {
      dispatch(setTriggerNodesFetchedBySentence(null));
    };
  }, []);

  return (
    <>
      <DialogContent sx={{ minWidth: 400, padding: "8px 0px" }}>
        <FetchTriggerNodesInput sentence={sentence} setSentence={setSentence} />
        {triggerNodesFetchedBySentence &&
        Object.keys(triggerNodesFetchedBySentence).length > 0 ? (
          <>
            <Typography
              sx={{
                marginTop: "8px",
                color: "#009979b5",
                display: "flex",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <CheckCircleOutlinedIcon />
              Wir haben Trigger Nodes gefunden, die Ihren abgefragten Satz
              enthalten.
            </Typography>
            <Typography sx={{ marginTop: "8px" }}>
              Möchten Sie diese editieren?
            </Typography>
            <Stack
              direction={"row"}
              sx={{ alignItems: "center", gap: "12px", padding: "12px" }}
            >
              <Button variant={"text"} onClick={handleCancelButton}>
                Abbrechen
              </Button>
              <Button variant={"contained"} onClick={handleClickOpen}>
                Editieren
              </Button>
            </Stack>
          </>
        ) : triggerNodesFetchedBySentence &&
          Object.keys(triggerNodesFetchedBySentence).length === 0 ? (
          <Typography
            sx={{
              marginTop: "8px",
              color: "var(--yellow)",
              display: "flex",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            <InfoOutlinedIcon />
            Es konnten keine Trigger Nodes gefunden werden, die den Satz
            beinhalten.
          </Typography>
        ) : undefined}

        {triggerNodesFetchedBySentence && open && (
          <TriggerNodesEditAndSelectionDialog
            sentence={sentence}
            open={open}
            triggerNodesFetchedBySentence={triggerNodesFetchedBySentence}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />
        )}
      </DialogContent>
    </>
  );
};

export default EditTriggerNodes;
