import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { useTranslations } from "next-intl";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import debounce from "lodash.debounce";
import { InputNumber } from "rsuite";

const SentenceReformulation = (props: any) => {
  const { onChange } = props;
  const t = useTranslations();

  const [count, setCount] = useState(1);
  const [sentence, setSentence] = useState("");

  const debouncedOnChange = useMemo(() => debounce(onChange, 100), [onChange]);

  useEffect(() => {
    if (count && sentence && onChange) {
      debouncedOnChange({
        count: count,
        sentence: sentence,
      });
    }
  }, [count, sentence, debouncedOnChange]);

  return (
    <>
      <DialogContent sx={{ minWidth: 400, pr: 6 }}>
        <Divider variant="middle" sx={{ mb: 2 }} />
        <label>{t("Admin.reformulation_count")}</label>
        <InputNumber
          onChange={(event: any) => {
            setCount(event);
          }}
          color="primary"
        />
        <Divider sx={{ my: 2 }} />
        <TextField
          onChange={(event: any) => {
            setSentence(event.target?.value);
          }}
          color="primary"
          size="small"
          label={t("Admin.reformulation_sentence")}
        />
      </DialogContent>
    </>
  );
};

export default SentenceReformulation;
