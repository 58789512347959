import Actions from "../../constants";

export const setCollection = (
  currentState: any,
  action: { type: any; payload: any }
) => {
  if (action.type !== Actions.SET_COLLECTION) {
    return currentState;
  }

  return action.payload;
};
export const setCollections = (
  currentState: any,
  action: { type: any; payload: any }
) => {
  if (action.type !== Actions.SET_COLLECTIONS) {
    return currentState;
  }

  return action.payload;
};

export const setCollectionsUpsertStatus = (
  currentState: any,
  action: {
    type: any;
    payload?: any;
    option: any;
    error?: any;
    collectionId?: any;
  }
) => {
  if (
    action.type === Actions.UPSERT_COLLECTION_FULFILLED &&
    action.option === "remove"
  ) {
    const currentStateCopy = { ...currentState };

    if (
      currentStateCopy["removingDocuments"].every(
        (removeInfo: { status: string }) => removeInfo.status !== "pending"
      )
    ) {
      return currentState;
    }
    const indexToModify = currentStateCopy["removingDocuments"].findIndex(
      (removeInfo: any) => removeInfo.collectionId === action.collectionId
    );
    currentStateCopy["removingDocuments"][indexToModify]["status"] = "success";
    return currentStateCopy;
  } else if (
    action.type === Actions.UPSERT_COLLECTION_FAILED &&
    action.option === "remove"
  ) {
    const currentStateCopy = { ...currentState };
    if (
      currentStateCopy["removingDocuments"].every(
        (removeInfo: { status: string }) => removeInfo.status !== "pending"
      )
    ) {
      return currentState;
    }
    const indexToModify = currentStateCopy["removingDocuments"].findIndex(
      (removeInfo: any) => removeInfo.collectionId === action.collectionId
    );
    currentStateCopy["removingDocuments"][indexToModify]["status"] = "success";
    return currentStateCopy;
  } else if (
    action.type === Actions.UPSERT_COLLECTION_FULFILLED &&
    action.option === "add"
  ) {
    const currentStateCopy = { ...currentState };
    if (currentStateCopy["addingDocuments"].status !== "pending") {
      return currentState;
    }
    currentStateCopy["addingDocuments"].status = "success";
    return currentStateCopy;
  } else if (
    action.type === Actions.UPSERT_COLLECTION_FAILED &&
    action.option === "add"
  ) {
    const currentStateCopy = { ...currentState };
    if (currentStateCopy["addingDocuments"].status !== "pending") {
      return currentState;
    }
    currentStateCopy["addingDocuments"].status = "failed";
    return currentStateCopy;
  } else if (action.type !== Actions.SET_COLLECTIONS_UPSERT_STATUS) {
    return currentState;
  }

  return action.payload;
};

export const setIsTrashCollection = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_IS_TRASH_COLLECTION) {
    return currentState;
  }

  return action.payload;
};
