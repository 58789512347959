import { useMemo } from "react";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { createEpicMiddleware } from "redux-observable";
import { composeWithDevTools } from "redux-devtools-extension";
import rootEpic from "./epics";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const layoutPersistConfig = {
  key: "layout",
  storage: storage,
  timeout: 1,
  whitelist: [
    "drawerState",
    "copilotDrawerState",
    "copilotColorMode",
    "userSettings",
    "selectedTickets",
  ],
};

let store;

const persistedReducer = persistReducer(layoutPersistConfig, rootReducer);
let persistor;
export const getOrInitializePersistor = (store) => {
  if (!persistor) {
    persistor = persistStore(store);
  }
  return persistor;
};
export default function configureStore(initialState) {
  const epicMiddleware = createEpicMiddleware();
  const logger = createLogger({ collapsed: true }); // log every action to see what's happening behind the scenes.
  const reduxMiddleware = composeWithDevTools(
    applyMiddleware(epicMiddleware, logger)
  );

  const store = createStore(persistedReducer, initialState, reduxMiddleware);
  epicMiddleware.run(rootEpic);

  return store;
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? configureStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = configureStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
