import Actions from "../constants";

export const setRentalUnitTenants = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SET_RENTAL_UNIT_TENANTS) {
    return currentState;
  }

  return action.payload;
};
