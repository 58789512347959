import Actions from "../constants";

export const setQnaDownload = (currentState, action) => {
  if (action.type !== Actions.SET_QNA_DOWNLOAD) {
    return currentState;
  }
  return action.payload;
};

export const setQnaUpload = (currentState, action) => {
  if (action.type !== Actions.SET_QNA_UPLOAD) {
    return currentState;
  }
  return action.payload;
};

export const setQnaDeleteAll = (currentState, action) => {
  if (action.type !== Actions.SET_DELETE_QNAS) {
    return currentState;
  }
  return action.payload;
};

export const setAdminResponse = (currentState, action) => {
  if (action.type !== Actions.SET_ADMIN_RESPONSE) {
    return currentState;
  }
  return action.payload;
};
export const setTriggerNodesFetchedBySentence = (currentState, action) => {
  if (action.type !== Actions.SET_TRIGGER_NODES_FETCHED_BY_SENTENCE) {
    return currentState;
  }
  return action.payload;
};
