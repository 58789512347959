import Actions from "../constants";
import { getKeycloakToken } from "lib/util";
import { of } from "rxjs";

export const fetchTagCategories = () => ({
  type: Actions.FETCH_TAG_CATEGORIES,
  token: getKeycloakToken(),
});
export const fetchTagTopics = () => ({
  type: Actions.FETCH_TAG_TOPICS,
  token: getKeycloakToken(),
});
export const fetchTagReasons = () => ({
  type: Actions.FETCH_TAG_REASONS,
  token: getKeycloakToken(),
});
export const fetchTagProblems = () => ({
  type: Actions.FETCH_TAG_PROBLEMS,
  token: getKeycloakToken(),
});
export const fetchTagsBySenderId = (data: any) => ({
  type: Actions.FETCH_TAGS_BY_SENDERID,
  token: getKeycloakToken(),
  payload: data,
});

export const createTagElement = (data: any) => ({
  type: Actions.CREATE_TAG_ELEMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const createTagElementFulfilled = () => ({
  type: Actions.CREATE_TAG_ELEMENT_FULFILLED,
});

export const editTagElement = (data: any) => ({
  type: Actions.EDIT_TAG_ELEMENT,
  payload: data,
  token: getKeycloakToken(),
});

export const editTagElementFulfilled = () => ({
  type: Actions.EDIT_TAG_ELEMENT_FULFILLED,
});

export const createTagCategory = (data: any) => ({
  type: Actions.CREATE_TAG_CATEGORY,
  payload: data,
  token: getKeycloakToken(),
});

export const createTagCategoryFulfilled = () => ({
  type: Actions.CREATE_TAG_CATEGORY_FULFILLED,
});
export const createTagTopic = (data: any) => ({
  type: Actions.CREATE_TAG_TOPIC,
  payload: data,
  token: getKeycloakToken(),
});

export const createTagTopicFulfilled = () => ({
  type: Actions.CREATE_TAG_TOPIC_FULFILLED,
});

export const createTagReason = (data: any) => ({
  type: Actions.CREATE_TAG_REASON,
  payload: data,
  token: getKeycloakToken(),
});

export const createTagReasonFulfilled = () => ({
  type: Actions.CREATE_TAG_REASON_FULFILLED,
});

export const createTagProblem = (data: any) => ({
  type: Actions.CREATE_TAG_PROBLEM,
  payload: data,
  token: getKeycloakToken(),
});

export const createTagProblemFulfilled = () => ({
  type: Actions.CREATE_TAG_PROBLEM_FULFILLED,
});

export const setTagCategories = (data: any) => ({
  type: Actions.SET_TAG_CATEGORIES,
  payload: data,
});
export const setTagTopics = (data: any) => ({
  type: Actions.SET_TAG_TOPICS,
  payload: data,
});
export const setTagReasons = (data: any) => ({
  type: Actions.SET_TAG_REASONS,
  payload: data,
});
export const setTagProblems = (data: any) => ({
  type: Actions.SET_TAG_PROBLEMS,
  payload: data,
});
export const setTagsBySenderId = (data: any) => ({
  type: Actions.SET_TAGS_BY_SENDERID,
  payload: data,
});

export const addTagToMessage = (data: any) => ({
  type: Actions.ADD_TAG_TO_MESSAGE,
  payload: data,
  token: getKeycloakToken(),
});

export const addTagToMessageFulfilled = () => ({
  type: Actions.ADD_TAG_TO_MESSAGE_FULFILLED,
});

export const deleteTagFromMessage = (data: any) => ({
  type: Actions.DELETE_TAG_FROM_MESSAGE,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteTagFromMessageFulfilled = (data: any) => ({
  type: Actions.DELETE_TAG_FROM_MESSAGE_FULFILLED,
  payload: data,
});
export const fetchTags = () => ({
  type: Actions.FETCH_TAGS,
  token: getKeycloakToken(),
});

export const setTags = (data: any) => ({
  type: Actions.SET_TAGS,
  payload: data,
});

export const changeReviewStatus = (data: any) => ({
  type: Actions.CHANGE_REVIEW_STATUS,
  payload: data,
  token: getKeycloakToken(),
});
export const changeReviewStatusFulfilled = () => ({
  type: Actions.CHANGE_REVIEW_STATUS_FULFILLED,
});

export const changePriority = (data: any) => ({
  type: Actions.CHANGE_PRIORITY,
  payload: data,
  token: getKeycloakToken(),
});

export const changePriorityFulfilled = () => ({
  type: Actions.CHANGE_PRIORITY_FULFILLED,
});

export const addCommentToTag = (data: any) => ({
  type: Actions.ADD_COMMENT_TO_TAG,
  payload: data,
  token: getKeycloakToken(),
});

export const addAdditionalAttributesToTag = (
  sender_id: string,
  tag_id: string,
  payload: any
) => ({
  type: Actions.ADD_ADDITIONAL_ATTRIBUTES_TO_TAG,
  payload: payload,
  tag_id: tag_id,
  sender_id: sender_id,
  token: getKeycloakToken(),
});
