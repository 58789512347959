import {
  bindActionCreators,
  Store,
  ActionCreator,
  ActionCreatorsMapObject,
} from "redux";
import {
  useStore as useStoreRedux,
  useSelector,
  useDispatch as reduxUseDispatch,
  TypedUseSelectorHook,
} from "react-redux";
import { ReduxState } from "../types";

export const useStoreState: TypedUseSelectorHook<ReduxState> = useSelector;
