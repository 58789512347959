import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  createOneOffCampaignFulfilled,
  deleteOneOffCampaignFulfilled,
  setOneOffCampaign,
  setOneOffCampaigns,
  setCampaignTypes,
} from "../actions/campaigns";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchOneOffCampaignsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_ONEOFF_CAMPAIGNS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaigns?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setOneOffCampaigns(response.response);
        }),
        catchError((error) => {
          return of(setOneOffCampaigns(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchOneOffCampaignEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_ONEOFF_CAMPAIGN),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaign/${action.payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setOneOffCampaign(response.response.result);
        }),
        catchError((error) => {
          return of(setOneOffCampaigns(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const createOneOffCampaignsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_ONEOFF_CAMPAIGN),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaign`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload, language: state$.value.language },
      }).pipe(
        concatMap((response) => {
          return of(createOneOffCampaignFulfilled(), setOneOffCampaigns(null));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const deleteOneOffCampaignEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_ONEOFF_CAMPAIGN),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaign/${action.payload}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return deleteOneOffCampaignFulfilled(action.payload);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchOngoingCampaignsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_ONGOING_CAMPAIGNS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaigns/ongoing?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setOngoingCampaigns(response.response);
        }),
        catchError((error) => {
          return of(setOngoingCampaigns(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchOngoingCampaignEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_ONGOING_CAMPAIGN),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaigns/ongoing/${action.payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setOngoingCampaign(response.response.result);
        }),
        catchError((error) => {
          return of(setOngoingCampaigns(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const createOngoingCampaignsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_ONGOING_CAMPAIGN),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaigns/ongoing`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload, language: state$.value.language },
      }).pipe(
        concatMap((response) => {
          return of(
            createOngoingCampaignFulfilled(),
            setOngoingCampaigns(null)
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const deleteOngoingCampaignEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_ONGOING_CAMPAIGN),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaigns/ongoing/${action.payload}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return deleteOngoingCampaignFulfilled(action.payload);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchCampaignTypesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CAMPAIGN_TYPES),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/campaign_types?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setCampaignTypes(response.response);
        }),
        catchError((error) => {
          return of(setCampaignTypes(null), errorMessage(error.message));
        })
      );
    })
  );
};
