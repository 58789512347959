import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

// export const fetchRentalUnitTenants = ({
//   id = "",
//   //how many tickets to skip
//   offset = 0,
//   //how many tickets to fetch
//   // limit=50,
//   sync_external = false,
//   active = "",
//   end_date = "",
//   start_date = "",
//   birth_date = "",
//   phone = "",
//   email = "",
//   street = "",
//   postcode = "",
//   city = "",
//   last_name = "",
//   first_name = "",
//   client_id = "",
//   contract_type = "",
//   contract_name = "",
//   contract_id = "",
//   user_id = "",
//   rental_unit_id = "",
// } = {}) => ({
//   type: Actions.FETCH_RENTAL_UNIT_TENANTS,
//   payload: {
//     id,
//     offset,
//     // limit,
//     sync_external,
//     active,
//     end_date,
//     start_date,
//     birth_date,
//     phone,
//     email,
//     street,
//     postcode,
//     city,
//     last_name,
//     first_name,
//     client_id,
//     contract_type,
//     contract_name,
//     contract_id,
//     user_id,
//     rental_unit_id,
//   },
//   token: getKeycloakToken(),
// });

// export const fetchRentalUnitTenants = (data: any) => ({
//   type: Actions.FETCH_RENTAL_UNIT_TENANTS,
//   payload: data,
//   token: getKeycloakToken(),
// });

export const fetchRentalUnitTenants = () => ({
  type: Actions.FETCH_RENTAL_UNIT_TENANTS,
  token: getKeycloakToken(),
});

export const setRentalUnitTenants = (data: any) => ({
  type: Actions.SET_RENTAL_UNIT_TENANTS,
  payload: data,
});
