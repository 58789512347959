import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchUsedFacebookPage = () => ({
  type: Actions.FETCH_USED_FACEBOOK_PAGE,
  token: getKeycloakToken(),
});

export const fetchFacebookPages = () => ({
  type: Actions.FETCH_FACEBOOK_PAGES,
  token: getKeycloakToken(),
});

export const setFacebookToken = (payload) => ({
  type: Actions.SET_FACEBOOK_TOKEN,
  payload: payload,
});

export const setFacebookPages = (payload) => ({
  type: Actions.SET_FACEBOOK_PAGES,
  payload: payload,
});

export const setUsedFacebookPage = (payload) => ({
  type: Actions.SET_USED_FACEBOOK_PAGE,
  payload: payload,
});

export const createFacebookPage = (pageId) => ({
  type: Actions.CREATE_FACEBOOK_PAGE,
  pageId: pageId,
  token: getKeycloakToken(),
});

export const deleteLinkedFacebookPage = () => ({
  type: Actions.DELETE_LINKED_FACEBOOK_PAGE,
  token: getKeycloakToken(),
});

export const createFacebookPageFulfilled = () => ({
  type: Actions.CREATE_FACEBOOK_PAGE_FULFILLED,
});

export const createWebChannelConfig = (domain, payload = undefined) => ({
  type: Actions.CREATE_WEB_CHANNEL_CONFIG,
  domain: domain,
  payload: payload,
  token: getKeycloakToken(),
});

export const createWebChannelConfigFulfilled = () => ({
  type: Actions.CREATE_WEB_CHANNEL_CONFIG_FULFILLED,
});

export const fetchUsedWebChannelConfig = () => ({
  type: Actions.FETCH_USED_WEB_CHANNEL_CONFIG,
  token: getKeycloakToken(),
});

export const setUsedWebChannelConfig = (payload) => ({
  type: Actions.SET_USED_WEB_CHANNEL_CONFIG,
  payload: payload,
});

export const deleteLinkedWebChannelConfig = (payload) => ({
  type: Actions.DELETE_LINKED_WEB_CHANNEL_CONFIG,
  payload: payload,
  token: getKeycloakToken(),
});
