import Actions from "../constants";

export const showSuccessSnackbar = (
  message,
  anchorOrigin,
  duration,
  customTranslation
) => ({
  type: Actions.SNACKBAR_SUCCESS,
  message: message,
  anchorOrigin: anchorOrigin,
  duration: duration,
  customTranslation: customTranslation,
});

export const showErrorSnackbar = (
  message,
  anchorOrigin,
  duration,
  customTranslation
) => ({
  type: Actions.SNACKBAR_ERROR,
  message: message,
  anchorOrigin: anchorOrigin,
  duration: duration,
  customTranslation: customTranslation,
});

export const showWarningSnackbar = (
  message,
  anchorOrigin,
  duration,
  customTranslation
) => ({
  type: Actions.SNACKBAR_WARNING,
  message: message,
  anchorOrigin: anchorOrigin,
  duration: duration,
  customTranslation: customTranslation,
});
export const showInfoSnackbar = (
  message,
  anchorOrigin,
  duration,
  customTranslation
) => ({
  type: Actions.SNACKBAR_INFO,
  message: message,
  anchorOrigin: anchorOrigin,
  duration: duration,
  customTranslation: customTranslation,
});

export const clearSnackbar = (payload) => ({
  type: Actions.SNACKBAR_CLEAR,
  payload: payload,
});
