import Actions from "../constants";

export const FETCH_STATE = {
  FETCHING: "FETCHING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const setFetchState = (currentState, action) => {
  switch (action.type) {
    case Actions.SAVE_DIALOGUE_GRAPH_INFO:
      return FETCH_STATE.FETCHING;
    case Actions.SAVE_DIALOGUE_GRAPH_INFO_FULFILLED:
      return FETCH_STATE.SUCCESS;
    default:
      return currentState;
  }
};
