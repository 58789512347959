import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { ajax } from "rxjs/ajax";
import {
  setMSLoginLink,
  setMSLoginState,
  unlinkMSAccountFulfilled,
} from "../actions/integrations";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchMSLoginLinkEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_MS_LOGIN_LINK),
    mergeMap(() => {
      return ajax
        .get(`${REACT_APP_ACTION_HOST}/ms/login-url`, {
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        })
        .pipe(
          map((response) => {
            let result = response?.response;
            return setMSLoginLink(result?.url?.auth_uri);
          }),
          catchError((error) => {
            return of(setMSLoginLink(null));
          })
        );
    })
  );

export const fetchMSLoginStateEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_MS_LOGIN_STATE),
    mergeMap(() => {
      return ajax
        .get(`${REACT_APP_ACTION_HOST}/ms/login-state`, {
          "Content-Type": "application/json",
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        })
        .pipe(
          map((response) => {
            let result = response?.response;
            return setMSLoginState(result);
          }),
          catchError((error) => {
            return of(setMSLoginState(null));
          })
        );
    })
  );

export const unlinkMSAccountEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(ActionConstants.UNLINK_MS_ACCOUNT),
    mergeMap(() => {
      return ajax
        .post(`${REACT_APP_ACTION_HOST}/ms/unlink`, null, {
          "Content-Type": "application/json",
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        })
        .pipe(
          map((response) => {
            return setMSLoginState(null);
          }),
          catchError((error) => {
            return of(unlinkMSAccountFulfilled());
          })
        );
    })
  );
