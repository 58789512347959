import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import { useTranslations } from "next-intl";
import Divider from "@mui/material/Divider";
import UtteranceEditor from "./UtteranceEditor";
import Box from "@mui/material/Box";
import { RawDraftContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import TextField from "@mui/material/TextField";
import debounce from "lodash.debounce";
import SimpleSwitchInput from "features/dialogue-editor/components/DialogueGraph/components/SimpleSwitchInput";

const UtteranceAdminEditor = (props: any) => {
  const { onChange } = props;
  const t = useTranslations();

  const [allowUserInput, setAllowUserInput] = useState(false);
  const [utterKey, setUtterKey] = useState("");

  const [utterText, setUtterText] = useState<
    Array<RawDraftContentState | string>
  >([""]);

  const debouncedOnChange = useMemo(() => debounce(onChange, 100), [onChange]);

  useEffect(() => {
    if (utterKey && utterText && onChange) {
      let converted = utterText
        .map((e) => draftToHtml(e as RawDraftContentState))
        .join("\n\n");
      debouncedOnChange({
        utterance_key: utterKey,
        text: converted,
        allowUserInput: allowUserInput,
      });
    }
  }, [utterKey, utterText, debouncedOnChange, allowUserInput]);

  return (
    <>
      <DialogContent sx={{ minWidth: 400, pr: 6 }}>
        <Divider variant="middle" sx={{ mb: 2 }} />
        <TextField
          onChange={(event: any) => {
            setUtterKey(event.target?.value);
          }}
          color="primary"
          size="small"
          label={t("Admin.utter_key")}
        />
        <Divider sx={{ my: 2 }} />
        <Box>
          <UtteranceEditor
            utteranceTexts={utterText}
            responses={utterText}
            setResponses={setUtterText}
          />
        </Box>
        <Box>
          <SimpleSwitchInput
            nodeValue={allowUserInput}
            label={t(
              "UtteranceNodeConfig.utter_node_settings_allow_user_input"
            )}
            onChange={(data: any) => {
              setAllowUserInput(!!data);
            }}
          />
        </Box>
      </DialogContent>
    </>
  );
};

export default UtteranceAdminEditor;
