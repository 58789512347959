import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslations } from "next-intl";
import InputAdornment from "@mui/material/InputAdornment";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import InsertLink from "@mui/icons-material/InsertLink";
import { useState } from "react";

export default function MediaDialog(props) {
  const t = useTranslations();

  const [value, setValue] = useState("");

  const handleChange = (e) => setValue(e.target.value);

  const handleClose = () => {
    props.onClose(value);
  };

  return (
    <Dialog open={props.open} onClose={handleClose} sx={{ minWidth: "500" }}>
      <DialogTitle>{t("Select media")}</DialogTitle>
      <Tabs sx={{ margin: 0 }} onChange={() => {}} value="2" centered>
        <Tab label={t("Upload your file")} value="1" disabled />
        <Tab label={t("From URL")} value="2" />
        <Tab label={t("Embed Video")} value="3" disabled />
      </Tabs>
      <DialogContent>
        {/*<DialogContentText>*/}
        {/*  {t("Enter a valid URL")}*/}
        {/*</DialogContentText>*/}
        <TextField
          autoFocus
          onChange={handleChange}
          margin="dense"
          label={t("Link")}
          type="url"
          fullWidth
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InsertLink />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
        <Button onClick={() => props.onClose(value)}>
          {t("custom_mapping_save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
