import Actions from "../constants";

export const setFaqs = (currentState, action) => {
  if (action.type !== Actions.SET_FAQS) {
    return currentState;
  }

  return action.payload;
};

export const setTrainStatus = (currentState, action) => {
  if (action.type !== Actions.SET_TRAIN_STATUS) {
    return currentState;
  }

  return action.payload;
};
