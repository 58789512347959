import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  createContactFulfilled,
  deleteContactFulfilled,
  mergeContactFulfilled,
  setContact,
  setContacts,
  syncContactFulfilled,
} from "../actions/contacts";
import getConfig from "lib/config";
import { showErrorSnackbar } from "../actions/snackbar";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchContactsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CONTACTS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/contacts`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setContacts(response.response);
        }),
        catchError((error) => {
          return of(setContacts(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchContactEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CONTACT),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/contact/${action.payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setContact(response.response.result);
        }),
        catchError((error) => {
          return of(setContacts(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const createContactsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_CONTACT),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/contact`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload },
      }).pipe(
        concatMap((response) => {
          return of(createContactFulfilled(action.payload), setContacts(null));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const deleteContactEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_CONTACT),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/contact/${action.payload}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return deleteContactFulfilled(action.payload);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const mergeContactEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.MERGE_CONTACT),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/contact/${action.payload.contactId}/merge/${action.payload.otherContactId}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          if (state$.value.contact?.id === action.payload.contactId) {
            // pseudo merge of contacts so that state is updated
            let reference =
              state$.value.contacts?.find(
                (e) => e.id === action.payload.contactId
              ) || {};
            Object.keys(reference).forEach(
              (k) =>
                !reference[k] &&
                reference[k] !== undefined &&
                delete reference[k]
            );

            const updatedContact = {
              ...(state$.value.contacts?.find(
                (e) => e.id === action.payload.otherContactId
              ) || {}),
              ...reference,
            };

            return of(
              mergeContactFulfilled(action.payload),
              setContact(updatedContact)
            );
          }
          return of(mergeContactFulfilled(action.payload));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const syncContactEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.SYNC_CONTACT),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/contact/${action.payload}/sync`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          if (response.response) {
            return of(
              setContact(response.response),
              syncContactFulfilled(response.response)
            );
          } else {
            return of(
              errorMessage("response is null"),
              showErrorSnackbar("sync failed", {
                vertical: "top",
                horizontal: "right",
              })
            );
          }
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
