const b4ypurple = {
  50: "#FDF2FF",
  100: "#F1CAF8",
  200: "#E4A5F1",
  300: "#CD65E6",
  400: "#B434D8",
  500: "#9A11C6",
  600: "#8100B0",
  700: "#690094",
  800: "#4F0074",
  900: "#360052",
};

export default b4ypurple;
