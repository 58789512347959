import Actions from "../constants";

export const setConversations = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATIONS) {
    return currentState;
  }

  return action.payload;
};

export const setConversation = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATION) {
    return currentState;
  }

  return action.payload;
};

export const setConversationsCount = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATIONS_COUNT) {
    return currentState;
  }

  return action.payload;
};

export const setConversationsCountUnassigned = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATIONS_COUNT_UNASSIGNED) {
    return currentState;
  }

  return action.payload;
};

export const setConversationsCountAssigned = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATIONS_COUNT_ASSIGNED) {
    return currentState;
  }

  return action.payload;
};

export const setConversationTracker = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATION_TRACKER) {
    return currentState;
  }

  return action.payload;
};

export const setAgentAssistResponse = (currentState, action) => {
  if (action.type !== Actions.SET_AGENT_ASSIST_RESPONSE) {
    return currentState;
  }

  return action.payload;
};

export const setConversationIdentity = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATION_IDENTITY) {
    return currentState;
  }

  return {
    ...currentState,
    [action.conversationId]: action.payload,
  };
};

export const setTenantIdentity = (currentState, action) => {
  if (action.type !== Actions.SET_TENANT_IDENTITY) {
    return currentState;
  }
  return action.tenantId;
};

export const setTranslatedConversation = (currentState, action) => {
  if (action.type !== Actions.SET_TRANSLATED_CONVERSATION) {
    return currentState;
  }

  return action.payload;
};
export const setConversationAttachments = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATION_ATTACHMENTS) {
    return currentState;
  }

  return action.payload;
};
export const setAttachmentFile = (currentState, action) => {
  if (action.type !== Actions.SET_ATTACHMENT_FILE) {
    return currentState;
  }

  return action.payload;
};

export const setTicketAnswer = (currentState, action) => {
  if (action.type !== Actions.SET_TICKET_ANSWER) {
    return currentState;
  }

  return action.payload;
};

export const setCopiedConversation = (currentState, action) => {
  if (action.type !== Actions.SET_CONVERSATION_EVENTS_FULFILLED) {
    return currentState;
  }

  return action.payload;
};
