import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslations } from "next-intl";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { useStoreState } from "store/hooks";
import { fetchMessagePrediction } from "store/actions/nlp";
import dynamic from "next/dynamic";
import InputAdornment from "@mui/material/InputAdornment";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
const ReactJson = dynamic(
  () => {
    return import("react-json-view");
  },
  { ssr: false }
);

const MessagePredictionDialog = (props: any) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const t = useTranslations("MessagePredictionDialog");
  const [query, setQuery] = useState("");

  const messagePrediction = useStoreState((state) => state.messagePrediction);

  const handleClose = () => onClose();
  const handleSubmit = () => {
    if (query) dispatch(fetchMessagePrediction(query));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        minWidth: "500",
        "& .MuiDialog-container": { alignItems: "baseline" },
      }}
    >
      <DialogTitle>{t("title")}</DialogTitle>
      <Divider variant="middle" />
      <DialogContent sx={{ width: "100%" }}>
        <DialogContentText>{t("description")}</DialogContentText>
        <TextField
          size="small"
          fullWidth
          value={query}
          autoFocus
          placeholder={t("text_placeholder")}
          onChange={(event) => setQuery(event.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <OnlinePredictionIcon />
              </InputAdornment>
            ),
          }}
        />
        <Divider variant="middle" sx={{ my: 1 }} />
        {/* @ts-ignore */}
        <ReactJson src={messagePrediction || {}} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("cancel")}</Button>
        <Button color="error" variant="contained" onClick={handleSubmit}>
          {t("submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessagePredictionDialog;
