import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchCannedResponses = () => ({
  type: Actions.FETCH_CANNED_RESPONSES,
  token: getKeycloakToken(),
});

export const setCannedResponses = (data) => ({
  type: Actions.SET_CANNED_RESPONSES,
  payload: data,
});

export const fetchCannedResponse = (data) => ({
  type: Actions.FETCH_CANNED_RESPONSE,
  payload: data,
  token: getKeycloakToken(),
});

export const setCannedResponse = (data) => ({
  type: Actions.SET_CANNED_RESPONSE,
  payload: data,
});

export const createCannedResponse = (data) => ({
  type: Actions.CREATE_CANNED_RESPONSE,
  payload: data,
  token: getKeycloakToken(),
});

export const createCannedResponseFulfilled = () => ({
  type: Actions.CREATE_CANNED_RESPONSE_FULFILLED,
});

export const deleteCannedResponse = (data) => ({
  type: Actions.DELETE_CANNED_RESPONSE,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteCannedResponseFulfilled = (data) => ({
  type: Actions.DELETE_CANNED_RESPONSE_FULFILLED,
  payload: data,
});
