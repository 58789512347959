import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchContacts = () => ({
  type: Actions.FETCH_CONTACTS,
  token: getKeycloakToken(),
});

export const setContacts = (data) => ({
  type: Actions.SET_CONTACTS,
  payload: data,
});

export const fetchContact = (data) => ({
  type: Actions.FETCH_CONTACT,
  payload: data,
  token: getKeycloakToken(),
});

export const setContact = (data) => ({
  type: Actions.SET_CONTACT,
  payload: data,
});

export const createContact = (data) => ({
  type: Actions.CREATE_CONTACT,
  payload: data,
  token: getKeycloakToken(),
});

export const createContactFulfilled = () => ({
  type: Actions.CREATE_CONTACT_FULFILLED,
});

export const deleteContact = (data) => ({
  type: Actions.DELETE_CONTACT,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteContactFulfilled = (data) => ({
  type: Actions.DELETE_CONTACT_FULFILLED,
  payload: data,
});

export const mergeContact = (data) => ({
  type: Actions.MERGE_CONTACT,
  payload: data,
  token: getKeycloakToken(),
});

export const mergeContactFulfilled = (data) => ({
  type: Actions.MERGE_CONTACT_FULFILLED,
  payload: data,
});

export const syncContact = (data) => ({
  type: Actions.SYNC_CONTACT,
  payload: data,
  token: getKeycloakToken(),
});

export const syncContactFulfilled = (data) => ({
  type: Actions.SYNC_CONTACT_FULFILLED,
  payload: data,
});
