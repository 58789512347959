import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchRentalUnitArticles = (data: any) => ({
  type: Actions.FETCH_RENTAL_UNIT_ARTICLES,
  payload: data,
  token: getKeycloakToken(),
});

export const setRentalUnitArticles = (data: any) => ({
  type: Actions.SET_RENTAL_UNIT_ARTICLES,
  payload: data,
});
