import React from "react";
import TagElementCreation from "features/tags/components/TagElementCreation";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import Box from "@mui/material/Box";

type AddNewElementProps = {
  fetchAll: any;
  element: any;
  parentId?: any;
  id?: any;
};

export default function AddNewElement({
  fetchAll,
  element,
  parentId,
  id,
}: AddNewElementProps): JSX.Element {
  return (
    <Box
      className={"add_new_element"}
      sx={{
        alignSelf: "flex-end",
        m: "8px 0px 12px 0px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "8px 12px 8px 16px",
        backgroundColor: "#fff",
        borderRadius: "9px",
        // ...(element === "category" && {
        //   boxShadow:
        //     "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        // }),
      }}
    >
      <TagElementCreation
        fetchAll={fetchAll}
        element={element}
        parentId={parentId}
        id={id}
      />
    </Box>
  );
}
