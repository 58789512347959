const b4yorange = {
  50: "#FFF9F2",
  100: "#FFF0DA",
  200: "#FFE4C2",
  300: "#FFC489",
  400: "#FFA45A",
  500: "#FA8638",
};

export default b4yorange;
