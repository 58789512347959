export function lighten(hex: any, percent: number) {
  // Convert hex to RGB first
  let [r, g, b] = hexToRGB(hex);
  // Convert to HSL
  // @ts-ignore
  let [h, s, l]: number[] = rgbToHsl(r, g, b);
  // Increase the lightness value
  l += percent / 100;
  l = Math.min(l, 1); // Ensure it doesn't exceed 1
  // Convert back to RGB
  [r, g, b] = hslToRgb(h, s, l);
  return rgbToHex(r, g, b);
}

export function hexToRGB(hex: string) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return [r, g, b];
}

export function hexToRGBAString(hex: string) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, 1)`;
}

export function rgbToHsl(r: number, g: number, b: number) {
  (r /= 255), (g /= 255), (b /= 255);
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    // @ts-ignore
    h /= 6;
  }

  return [h, s, l];
}

export function hslToRgb(h: number, s: number, l: number) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    let hue2rgb = function hue2rgb(p: number, q: number, t: number) {
      if (t < 0) {
        t += 1;
      }
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);

    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function rgbToHex(r: number, g: number, b: number) {
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

export function hexToRgba(hex: string, alpha = 1) {
  // Remove the hash at the beginning if it's there
  hex = hex.replace(/^#/, "");

  // If the hex is three characters, expand it to six characters
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Parse the red, green, and blue values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA string with the specified alpha
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function argbToRgba(argb: number) {
  const alpha = ((argb >> 24) & 255) / 255; // Extract and normalize the alpha component
  const red = (argb >> 16) & 255; // Extract the red component
  const green = (argb >> 8) & 255; // Extract the green component
  const blue = argb & 255; // Extract the blue component

  return `rgba(${red}, ${green}, ${blue}, ${alpha.toFixed(2)})`;
}

export const ANNOTATIONS_HIGHLIGHT_COLORS = [
  { color: "hsl(36deg 100% 50%)", backgroundColor: "hsl(36deg 100% 88.61%)" },
  { color: "hsl(122 80% 50% / 1)", backgroundColor: "hsl(122 67% 88% / 1)" },
  { color: "hsl(0deg 80% 50%)", backgroundColor: "hsl(0deg 100% 90.77%)" },
  {
    color: "hsl(288.43deg 80% 50%)",
    backgroundColor: "hsl(288.43deg 100% 91.41%)",
  },
  {
    color: "hsl(210.89deg 80% 50%)",
    backgroundColor: "hsl(210.89deg 100% 92.71%)",
  },
];

export function applyOpacity(rgba: string, opacity: any) {
  // Extract the RGBA components from the input string
  const matches = rgba.match(
    /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d*(?:\.\d+)?))?\s*\)$/i
  );

  if (!matches) {
    throw new Error("Invalid RGBA format");
  }

  // Adjust the alpha value
  return `rgba(${matches[1]}, ${matches[2]}, ${matches[3]}, ${opacity})`;
}
