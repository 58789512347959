// returns only Id
import { Collection } from "types/Interfaces/smartDoc";
import { upsertCollection } from "store/actions/smartDoc/collections";
import { showErrorSnackbar } from "store/actions/snackbar";

export const DEFAULT_COLLECTIONS: string[] = [
  "_favorites",
  "_read_later",
  "_trash",
];
export const DEFAULT_COLLECTIONS_IDS: string[] = [
  "00000000-0000-0000-0000-000000000000",
  "00000000-0000-0000-0000-000000000001",
  "00000000-0000-0000-0000-000000000002",
];
export const DEFAULT_COLLECTIONS_IDS_NOT_TRASH: string[] = [
  "00000000-0000-0000-0000-000000000000",
  "00000000-0000-0000-0000-000000000001",
];
export const COLLECTIONS_WITHOUT_DOCUMENTS: string = "_empty";
export const TRASH_COLLECTION = ["_trash"];
export const TRASH_COLLECTION_ID = "00000000-0000-0000-0000-000000000002";
export const FAVORITES_COLLECTION_ID = "00000000-0000-0000-0000-000000000000";
export const READ_LATER_COLLECTION_ID = "00000000-0000-0000-0000-000000000001";
export const searchChildCollectionTree = (
  currentId: string,
  collections?: Collection[]
) => {
  if (collections) {
    let array: string[] = [currentId];
    const search = (id: string) => {
      const children = collections.filter((coll) => coll.parent_id === id);
      const childIds = children.map((c) => c.id);
      if (childIds.length > 0) {
        array = [...array, ...childIds];
        children.map((coll) => search(coll.id));
      }
    };
    search(currentId);
    return array;
  }

  return [];
};

export const searchParentCollectionTreeName = (
  currentColl: Collection,
  collections?: Collection[],
  t?: any
) => {
  if (collections) {
    let array: string[] = [currentColl.name];
    const search = (c: Collection) => {
      const parentIdx = collections.findIndex(
        (collection) => collection.id === c.parent_id
      );
      if (parentIdx > -1) {
        array = [collections[parentIdx].name, ...array];
        search(collections[parentIdx]);
      }
    };
    search(currentColl);
    if (array.length === 1) {
      const systemCollection = DEFAULT_COLLECTIONS.includes(array[0]);
      if (systemCollection && t) {
        array[0] = t(array[0]);
        return array;
      } else {
        return array;
      }
    }

    return array;
  }
  return [];
};

// get explicit path like hello -> world -> one
export const searchParentCollectionTreeNumber = (
  currentColl: Collection,
  collections?: Collection[]
) => {
  if (collections) {
    let array: string[] = [currentColl.id];
    const search = (c: Collection) => {
      const parentIdx = collections.findIndex(
        (coll) => coll.id === c.parent_id
      );
      if (parentIdx > -1) {
        array = [collections[parentIdx].id, ...array];
        search(collections[parentIdx]);
      }
    };
    search(currentColl);
    return array;
  }
  return [];
};

export const addDocumentToCollection = (
  selectedDocuments: string[],
  collectionId: string,
  collections: Collection[],
  dispatch: any,
  copilot?: boolean
) => {
  const collectionToUpdate: Collection | undefined | null = collections.find(
    (collection) => collection.id === collectionId
  );

  let documentsToPost = [];
  const newDocumentIdsPayload = selectedDocuments.map((documentId) => {
    return { document_id: documentId };
  });

  if (
    collectionToUpdate &&
    collectionToUpdate.documents &&
    collectionToUpdate?.documents.length > 0
  ) {
    //I assume dnd package modifies redux collections state, because collections gets mutated without an explicit dispatch
    //of setCollections. I can't find the reason why, so I will filter as workaround for now
    const previousDocumentIds = collectionToUpdate?.documents
      ?.filter((documentId) => !selectedDocuments.includes(documentId))
      .map((documentId) => {
        return { document_id: documentId };
      });
    documentsToPost = [...previousDocumentIds, ...newDocumentIdsPayload];
  } else {
    documentsToPost = [...newDocumentIdsPayload];
  }

  const payload = JSON.stringify({
    id: collectionToUpdate?.id,
    documents: documentsToPost,
  });

  dispatch(upsertCollection(payload, collectionToUpdate?.id, copilot, "add"));
};

export const removeDocumentsFromCollection = (
  selectedDocuments: string[],
  collectionId: string,
  collections: Collection[],
  dispatch: any,
  copilot?: boolean
) => {
  const collectionToUpdate: Collection | undefined | null = collections.find(
    (collection) => collection.id === collectionId
  );
  if (!collectionToUpdate) return showErrorSnackbar("Collection not found");
  const updatedCollectionDocuments: string[] = [
    ...collectionToUpdate?.documents,
  ];
  selectedDocuments.forEach((documentId) => {
    const indexToRemove = collectionToUpdate?.documents?.findIndex(
      (docId) => docId === documentId
    );

    // @ts-ignore

    if (indexToRemove !== undefined && indexToRemove > -1) {
      updatedCollectionDocuments.splice(indexToRemove, 1);
    }
  });

  const newCollectionDocumentsPayload = updatedCollectionDocuments.map(
    (documentId) => {
      return { document_id: documentId };
    }
  );
  // const payload=[JSON.stringify({
  //     id: collectionToUpdate?.id,
  //     documents: newCollectionDocumentsPayload,
  //   },JSON.stringify({
  //     id: collectionToUpdate?.id,
  //     documents: newCollectionDocumentsPayload,
  //   },JSON.stringify({
  //     id: collectionToUpdate?.id,
  //     documents: newCollectionDocumentsPayload,
  //   }]
  const payload = JSON.stringify({
    id: collectionToUpdate?.id,
    documents: newCollectionDocumentsPayload,
  });
  dispatch(
    upsertCollection(payload, collectionToUpdate?.id, copilot, "remove")
  );
};

export const addDocumentToCollectionNew = (
  selectedDocuments: string[],
  collectionId: string,
  documents: string[],
  dispatch: any,
  copilot?: boolean
) => {
  let documentsToPost = [];
  const newDocumentIdsPayload = selectedDocuments.map((documentId) => {
    return { document_id: documentId };
  });

  if (documents && documents.length > 0) {
    const previousDocumentIds = documents
      ?.filter((documentId) => !selectedDocuments.includes(documentId))
      .map((documentId) => {
        return { document_id: documentId };
      });
    documentsToPost = [...previousDocumentIds, ...newDocumentIdsPayload];
  } else {
    documentsToPost = [...newDocumentIdsPayload];
  }

  const payload = JSON.stringify({
    id: collectionId,
    documents: documentsToPost,
  });

  dispatch(upsertCollection(payload, collectionId, copilot, "add"));
};

export const removeDocumentsFromCollectionNew = (
  selectedDocuments: string[],
  collectionId: string,
  documents: string[],
  dispatch: any,
  copilot?: boolean
) => {
  let updatedCollectionDocuments: string[] = [];
  if (documents && documents.length > 0) {
    updatedCollectionDocuments = [...documents];
  }
  selectedDocuments.forEach((documentId) => {
    const indexToRemove = updatedCollectionDocuments.findIndex(
      (docId) => docId === documentId
    );

    if (indexToRemove !== undefined && indexToRemove > -1) {
      updatedCollectionDocuments.splice(indexToRemove, 1);
    }
  });

  const newCollectionDocumentsPayload = updatedCollectionDocuments.map(
    (documentId) => {
      return { document_id: documentId };
    }
  );

  const payload = JSON.stringify({
    id: collectionId,
    documents: newCollectionDocumentsPayload,
  });
  dispatch(upsertCollection(payload, collectionId, copilot, "remove"));
};
