import { Observable, of } from "rxjs";
import { switchMap, map, catchError, debounceTime } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST, REACT_APP_AGENT_HOST } = publicRuntimeConfig;
import { setFaqs, setTrainStatus } from "../actions/faqs";
import { getAgentUrl } from "lib/util";

export const fetchFaqsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_FAQS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/${state$.value.dialogueGraphId}/faqs?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${action.token}`,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    map((response) => {
      return setFaqs(response.response.result);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );
};

export const fetchTrainStatusEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TRAIN_STATUS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${getAgentUrl(
          state$.value.language
        )}/question_and_answers/train/check?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${action.token}`,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    map((response) => {
      return setTrainStatus(response.response.status);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );
};
