import { combineEpics } from "redux-observable";
import * as dialogues from "./dialogues";
import * as conversations from "./conversations";
import * as events from "./events";
import * as synonyms from "./synonyms";
import * as tickets from "./tickets";
import * as rentalUnitTickets from "./rentalUnitTickets";
import * as rentalUnitTenants from "./rentalUnitTenants";
import * as rentalUnitArticles from "./rentalUnitArticles";
import * as channels from "./channels";
import * as utterances from "./utterances";
import * as files from "./files";
import * as faqs from "./faqs";
import * as jumpGraphs from "./jumpGraphs";
import * as cannedResponses from "./cannedResponses";
import * as labels from "./labels";
import * as tags from "./tags";
import * as cases from "./cases";
import * as contacts from "./contacts";
import * as users from "./users";
import * as campaigns from "./campaigns";
import * as nlp from "./nlp";
import * as decisionTrees from "./decisionTrees";
import * as admin from "./admin";
import * as integrations from "store/epics/integrations";
import * as documents from "./smartDoc/documents";
import * as collections from "./smartDoc/collections";

export default combineEpics(
  ...Object.values(dialogues),
  ...Object.values(conversations),
  ...Object.values(events),
  ...Object.values(synonyms),
  ...Object.values(tickets),
  ...Object.values(rentalUnitTickets),
  ...Object.values(rentalUnitTenants),
  ...Object.values(rentalUnitArticles),
  ...Object.values(channels),
  ...Object.values(utterances),
  ...Object.values(files),
  ...Object.values(faqs),
  ...Object.values(jumpGraphs),
  ...Object.values(cannedResponses),
  ...Object.values(labels),
  ...Object.values(tags),
  ...Object.values(cases),
  ...Object.values(contacts),
  ...Object.values(users),
  ...Object.values(campaigns),
  ...Object.values(nlp),
  ...Object.values(decisionTrees),
  ...Object.values(admin),
  ...Object.values(integrations),
  ...Object.values(documents),
  ...Object.values(collections)
);
