import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
} from "rxjs";
import {
  switchMap,
  map,
  catchError,
  debounceTime,
  concatMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";

import getConfig from "../../lib/config";
import { setRentalUnitArticles } from "store/actions/rentalUnitArticles";
import { Action } from "redux";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchRentalUnitArticlesEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<
        unknown,
        | {
            type: string;
            payload: any;
          }
        | { type: string; error: any }
      >
    ) => any;
  },
  state$: { value: { tenant: any } }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_RENTAL_UNIT_ARTICLES),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/rental_unit/articles?id=${action.payload.id}&offset=${action.payload.offset}&limit=${action.payload.limit}&sync_external=${action.payload.sync_external}&active=${action.payload.active}&external=${action.payload.external}&ticket_type_1=${action.payload.ticket_type_1}&ticket_type_2=${action.payload.ticket_type_2}&ticket_type_3=${action.payload.ticket_type_3}&ticket_type_4=${action.payload.ticket_type_4}&room_type=${action.payload.room_type}&description=${action.payload.description}&client_id=${action.payload.client_id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setRentalUnitArticles(response.response.articles));
        }),
        catchError((error) => {
          return of(setRentalUnitArticles(null), errorMessage(error.message));
        })
      );
    })
  );
};
