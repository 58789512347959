import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { useForm } from "react-hook-form";
import { dirtyValues } from "components/forms/util";
import { useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useRouter } from "next/router";
import { SxProps } from "@mui/material/styles";
import { createTagElement } from "store/actions/tags";
import TextInput from "components/forms/TextInput";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import getConfig from "lib/config";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const { publicRuntimeConfig } = getConfig();

const { REACT_APP_TENANT } = publicRuntimeConfig;

interface Topic {
  id: string;
  name: string;
}

interface Reason {
  id: string;
  name: string;
}

interface Problem {
  id: string;
  name: string;
}

const TagElementCreation = ({
  fetchAll,
  element,
  parentId,
  id,
}: {
  fetchAll: any;
  element: string;
  parentId?: string;
  id?: string;
}) => {
  const theme = useTheme();

  const inputStyles: SxProps = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      transition: "border-color ease-in-out 0.3s",
      border: "none",

      "& fieldset.MuiOutlinedInput-notchedOutline": { border: "none" },
      "&.Mui-focused": {
        border: "none",
        boxShadow: "none",
        "& input::placeholder": {
          color: "transparent",
        },
      },
      "& .MuiOutlinedInput-input": {
        fontWeight: "500",
        padding: 0,
        fontSize: "16px",
      },
    },
    "& input::placeholder,textarea::placeholder": {
      color: "#6a4580",
      opacity: 0.8,
      ...(REACT_APP_TENANT === "aar" && {
        color: theme.palette.primary.main,
      }),
    },
    "&:hover .MuiOutlinedInput-root": {
      borderColor: "#8b61a5",
      ...(REACT_APP_TENANT === "aar" && {
        borderColor: "#3498ff",
      }),
    },
  };

  const t = useTranslations("Tags");

  // const Fields = {
  //   type: element,
  //   [`${element}_value`]: `${element}_value`,
  //   parent_id: "parent_id",
  // };

  const [isLoading, setIsLoading] = useState(false);
  const [elementValue, setElementValue] = useState("");

  const dispatch = useDispatch();

  // const defaultValues = {
  //   [Fields[`${element}_value`]]: "",
  // };
  // const methods = useForm({ defaultValues });
  // const { reset, control, watch, formState, getValues } = methods;
  //
  // // @ts-ignore
  // const watchInput = watch(Fields[`${element}_value`], []);
  // // needed so that initial formstate is correct https://github.com/react-hook-form/react-hook-form/issues/1146
  // // noinspection JSUnusedLocalSymbols
  // const { dirtyFields } = formState;

  const onSubmit = () => {
    // const changedData = dirtyValues(formState.dirtyFields, getValues());

    const payload: {
      [key: string]: {
        [key: string]: string | null;
      };
    } = {
      [`${element}Payload`]: {
        type: element,
        parent_id: null,
        [`${element}_value`]: "",
      },
    };

    if (elementValue.length > 0) {
      payload[`${element}Payload`][`${element}_value`] = elementValue;

      if (parentId) {
        payload[`${element}Payload`].parent_id = parentId;
      }
    }

    if (Object.keys(payload[`${element}Payload`]).length !== 0) {
      dispatch(createTagElement({ ...payload[`${element}Payload`] }));
    }
    setElementValue("");

    // reset(undefined);
    setTimeout(() => {
      fetchAll();
    }, 250);
  };

  const renderLoading = () => {
    return <CircularProgress sx={{ mx: "auto", mt: 2 }} size={92} />;
  };
  return (
    <>
      {isLoading ? (
        renderLoading()
      ) : (
        <>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              justifyContent: "space-between",
              width: "100%",
              "& input,textarea,input::placeholder,textarea::placeholder": {
                // fontSize: "14px",
                // ...(element !== "category" && {
                fontSize: "16px",
                // }),
              },
            }}
          >
            {/*<TextInput*/}
            {/*  sx={{*/}
            {/*    ...inputStyles,*/}
            {/*  }}*/}
            {/*  name={Fields[`${element}_value`]}*/}
            {/*  control={control}*/}
            {/*  variant="outlined"*/}
            {/*  size="small"*/}
            {/*  placeholder={t(`${Fields[`${element}_value`]}_placeholder`)}*/}
            {/*  rules={{ required: t(`${Fields[`${element}_value`]}_required`) }}*/}
            {/*  onKeyDown={(e) => {*/}
            {/*    const values = getValues();*/}
            {/*    if (*/}
            {/*      e.key === "Enter" &&*/}
            {/*      values[`${element}_value`].length > 0*/}
            {/*    ) {*/}
            {/*      onSubmit();*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}
            <TextField
              sx={{
                ...inputStyles,
              }}
              value={elementValue}
              variant="outlined"
              size="small"
              placeholder={t(`${element}_value_placeholder`)}
              onFocus={() => {}}
              onChange={(e) => {
                setElementValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && elementValue.length > 0) {
                  onSubmit();
                }
              }}
            />
            {/*<IconButton*/}
            {/*  sx={{*/}
            {/*    // width: "25px",*/}
            {/*    // height: "25px",*/}
            {/*    // "& .MuiSvgIcon-root": {*/}
            {/*    //   width: "0.9em",*/}
            {/*    //   height: "0.9em",*/}
            {/*    // },*/}
            {/*    // ...(element !== "category" && {*/}
            {/*    width: "22px",*/}
            {/*    height: "22px",*/}
            {/*    "& .MuiSvgIcon-root": {*/}
            {/*      fontSize: "1.25rem",*/}
            {/*    },*/}
            {/*    // }),*/}
            {/*    display: "flex",*/}
            {/*    justifyContent: "center",*/}
            {/*    alignItems: "center",*/}
            {/*    borderRadius: "50%",*/}
            {/*    backgroundColor: "#360052ba",*/}
            {/*    ...(REACT_APP_TENANT === "aar" && {*/}
            {/*      backgroundColor: theme.palette.primary.main,*/}
            {/*    }),*/}
            {/*    ...(watchInput.length === 0 && {*/}
            {/*      backgroundColor: "#e7e7e7",*/}
            {/*    }),*/}
            {/*    "&:hover": {*/}
            {/*      backgroundColor: "#360052e0",*/}
            {/*      ...(REACT_APP_TENANT === "aar" && {*/}
            {/*        backgroundColor: "#002964",*/}
            {/*      }),*/}
            {/*    },*/}
            {/*  }}*/}
            {/*  onClick={() => {*/}
            {/*    const values = getValues();*/}
            {/*    if (values[`${element}_value`].length > 0) {*/}
            {/*      onSubmit();*/}
            {/*    }*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <AddIcon*/}
            {/*    fontSize={"medium"}*/}
            {/*    sx={{*/}
            {/*      fill: "#fff",*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</IconButton>*/}
          </Stack>
        </>
      )}
    </>
  );
};

export default TagElementCreation;
