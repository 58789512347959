import { ThemeColors, ChatPluginThemeColors } from "types/Interfaces/copilot";

type ThemeMode = "dark" | "light";
export const copilot_fields_variables_mapping: Record<
  ThemeMode,
  Record<keyof ThemeColors, string>
> = {
  dark: {
    copilotColorPrimary: "--copilot-dark-color-primary",
    copilotColorPrimaryRgb: "--copilot-dark-color-primary-rgb",
    copilotColorSurfaceTint: "--copilot-dark-color-surface-tint",
    copilotColorOnPrimary: "--copilot-dark-color-on-primary",
    copilotColorPrimaryContainer: "--copilot-dark-color-primary-container",
    copilotColorOnPrimaryContainer: "--copilot-dark-color-on-primary-container",
    copilotColorSecondary: "--copilot-dark-color-secondary",
    copilotColorSecondaryRgb: "--copilot-dark-color-secondary-rgb",
    copilotColorOnSecondary: "--copilot-dark-color-on-secondary",
    copilotColorSecondaryContainer: "--copilot-dark-color-secondary-container",
    copilotColorOnSecondaryContainer:
      "--copilot-dark-color-on-secondary-container",
    copilotColorTertiary: "--copilot-dark-color-tertiary",
    copilotColorOnTertiary: "--copilot-dark-color-on-tertiary",
    copilotColorTertiaryContainer: "--copilot-dark-color-tertiary-container",
    copilotColorOnTertiaryContainer:
      "--copilot-dark-color-on-tertiary-container",
    copilotColorError: "--copilot-dark-color-error",
    copilotColorOnError: "--copilot-dark-color-on-error",
    copilotColorErrorContainer: "--copilot-dark-color-error-container",
    copilotColorErrorContainerRgb: "--copilot-dark-color-error-container-rgb",
    copilotColorOnErrorContainer: "--copilot-dark-color-on-error-container",
    copilotColorBackground: "--copilot-dark-color-surface-container",
    copilotColorOnBackground: "--copilot-dark-color-on-surface",
    copilotColorSurface: "--copilot-dark-color-surface",
    copilotColorOnSurface: "--copilot-dark-color-on-surface",
    copilotColorOnSurfaceRgb: "--copilot-dark-color-on-surface-rgb",
    copilotColorSurfaceVariant: "--copilot-dark-color-surface-variant",
    copilotColorOnSurfaceVariant: "--copilot-dark-color-on-surface-variant",
    copilotColorOutline: "--copilot-dark-color-outline",
    copilotColorOutlineVariant: "--copilot-dark-color-outline-variant",
    copilotColorShadow: "--copilot-dark-color-shadow",
    copilotColorScrim: "--copilot-dark-color-scrim",
    copilotColorScrimRgb: "--copilot-dark-color-scrim-rgb",
    copilotColorInverseSurface: "--copilot-dark-color-inverse-surface",
    copilotColorInverseOnSurface: "--copilot-dark-color-inverse-on-surface",
    copilotColorInversePrimary: "--copilot-dark-color-inverse-primary",
    copilotColorPrimaryFixed: "--copilot-dark-color-primary-fixed",
    copilotColorOnPrimaryFixed: "--copilot-dark-color-on-primary-fixed",
    copilotColorPrimaryFixedDim: "--copilot-dark-color-primary-fixed-dim",
    copilotColorOnPrimaryFixedVariant:
      "--copilot-dark-color-on-primary-fixed-variant",
    copilotColorSecondaryFixed: "--copilot-dark-color-secondary-fixed",
    copilotColorOnSecondaryFixed: "--copilot-dark-color-on-secondary-fixed",
    copilotColorSecondaryFixedDim: "--copilot-dark-color-secondary-fixed-dim",
    copilotColorOnSecondaryFixedVariant:
      "--copilot-dark-color-on-secondary-fixed-variant",
    copilotColorTertiaryFixed: "--copilot-dark-color-tertiary-fixed",
    copilotColorOnTertiaryFixed: "--copilot-dark-color-on-tertiary-fixed",
    copilotColorTertiaryFixedDim: "--copilot-dark-color-tertiary-fixed-dim",
    copilotColorOnTertiaryFixedVariant:
      "--copilot-dark-color-on-tertiary-fixed-variant",
    copilotColorSurfaceDim: "--copilot-dark-color-surface-dim",
    copilotColorSurfaceBright: "--copilot-dark-color-surface-bright",
    copilotColorSurfaceContainerLowest:
      "--copilot-dark-color-surface-container-lowest",
    copilotColorSurfaceContainerLow:
      "--copilot-dark-color-surface-container-low",
    copilotColorSurfaceContainer: "--copilot-dark-color-surface-container",
    copilotColorSurfaceContainerHigh:
      "--copilot-dark-color-surface-container-high",
    copilotColorSurfaceContainerHighest:
      "--copilot-dark-color-surface-container-highest",
    copilotColorChatMessageBackground:
      "--copilot-dark-color-chat-message-background",
    copilotColorChatMessageText: "--copilot-dark-color-chat-message-text",
    copilotColorChatBotMessageText:
      "--copilot-dark-color-chat-bot-message-text",
    copilotColorChatBotMessageBackground:
      "--copilot-dark-color-chat-bot-message-background",
  },
  light: {
    copilotColorPrimary: "--copilot-light-color-primary",
    copilotColorPrimaryRgb: "--copilot-light-color-primary-rgb",
    copilotColorSurfaceTint: "--copilot-light-color-surface-tint",
    copilotColorOnPrimary: "--copilot-light-color-on-primary",
    copilotColorPrimaryContainer: "--copilot-light-color-primary-container",
    copilotColorOnPrimaryContainer:
      "--copilot-light-color-on-primary-container",
    copilotColorSecondary: "--copilot-light-color-secondary",
    copilotColorSecondaryRgb: "--copilot-light-color-secondary-rgb",
    copilotColorOnSecondary: "--copilot-light-color-on-secondary",
    copilotColorSecondaryContainer: "--copilot-light-color-secondary-container",
    copilotColorOnSecondaryContainer:
      "--copilot-light-color-on-secondary-container",
    copilotColorTertiary: "--copilot-light-color-tertiary",
    copilotColorOnTertiary: "--copilot-light-color-on-tertiary",
    copilotColorTertiaryContainer: "--copilot-light-color-tertiary-container",
    copilotColorOnTertiaryContainer:
      "--copilot-light-color-on-tertiary-container",
    copilotColorError: "--copilot-light-color-error",
    copilotColorOnError: "--copilot-light-color-on-error",
    copilotColorErrorContainer: "--copilot-light-color-error-container",
    copilotColorErrorContainerRgb: "--copilot-light-color-error-container-rgb",
    copilotColorOnErrorContainer: "--copilot-light-color-on-error-container",
    copilotColorBackground: "--copilot-light-color-background",
    copilotColorOnBackground: "--copilot-light-color-on-background",
    copilotColorSurface: "--copilot-light-color-surface",
    copilotColorOnSurface: "--copilot-light-color-on-surface",
    copilotColorOnSurfaceRgb: "--copilot-light-color-on-surface-rgb",
    copilotColorSurfaceVariant: "--copilot-light-color-surface-variant",
    copilotColorOnSurfaceVariant: "--copilot-light-color-on-surface-variant",
    copilotColorOutline: "--copilot-light-color-outline",
    copilotColorOutlineVariant: "--copilot-light-color-outline-variant",
    copilotColorShadow: "--copilot-light-color-shadow",
    copilotColorScrim: "--copilot-light-color-scrim",
    copilotColorScrimRgb: "--copilot-light-color-scrim-rgb",
    copilotColorInverseSurface: "--copilot-light-color-inverse-surface",
    copilotColorInverseOnSurface: "--copilot-light-color-inverse-on-surface",
    copilotColorInversePrimary: "--copilot-light-color-inverse-primary",
    copilotColorPrimaryFixed: "--copilot-light-color-primary-fixed",
    copilotColorOnPrimaryFixed: "--copilot-light-color-on-primary-fixed",
    copilotColorPrimaryFixedDim: "--copilot-light-color-primary-fixed-dim",
    copilotColorOnPrimaryFixedVariant:
      "--copilot-light-color-on-primary-fixed-variant",
    copilotColorSecondaryFixed: "--copilot-light-color-secondary-fixed",
    copilotColorOnSecondaryFixed: "--copilot-light-color-on-secondary-fixed",
    copilotColorSecondaryFixedDim: "--copilot-light-color-secondary-fixed-dim",
    copilotColorOnSecondaryFixedVariant:
      "--copilot-light-color-on-secondary-fixed-variant",
    copilotColorTertiaryFixed: "--copilot-light-color-tertiary-fixed",
    copilotColorOnTertiaryFixed: "--copilot-light-color-on-tertiary-fixed",
    copilotColorTertiaryFixedDim: "--copilot-light-color-tertiary-fixed-dim",
    copilotColorOnTertiaryFixedVariant:
      "--copilot-light-color-on-tertiary-fixed-variant",
    copilotColorSurfaceDim: "--copilot-light-color-surface-dim",
    copilotColorSurfaceBright: "--copilot-light-color-surface-bright",
    copilotColorSurfaceContainerLowest:
      "--copilot-light-color-surface-container-lowest",
    copilotColorSurfaceContainerLow:
      "--copilot-light-color-surface-container-low",
    copilotColorSurfaceContainer: "--copilot-light-color-surface-container",
    copilotColorSurfaceContainerHigh:
      "--copilot-light-color-surface-container-high",
    copilotColorSurfaceContainerHighest:
      "--copilot-light-color-surface-container-highest",
    copilotColorChatMessageBackground:
      "--copilot-light-color-chat-message-background",
    copilotColorChatMessageText: "--copilot-light-color-chat-message-text",
    copilotColorChatBotMessageText:
      "--copilot-light-color-chat-bot-message-text",
    copilotColorChatBotMessageBackground:
      "--copilot-light-color-chat-bot-message-background",
  },
};
export const Fields = {
  copilotColorPrimary: "copilotColorPrimary",
  copilotColorPrimaryRgb: "copilotColorPrimaryRgb",
  copilotColorSurfaceTint: "copilotColorSurfaceTint",
  copilotColorOnPrimary: "copilotColorOnPrimary",
  copilotColorPrimaryContainer: "copilotColorPrimaryContainer",
  copilotColorOnPrimaryContainer: "copilotColorOnPrimaryContainer",
  copilotColorSecondary: "copilotColorSecondary",
  copilotColorSecondaryRgb: "copilotColorSecondaryRgb",
  copilotColorOnSecondary: "copilotColorOnSecondary",
  copilotColorSecondaryContainer: "copilotColorSecondaryContainer",
  copilotColorOnSecondaryContainer: "copilotColorOnSecondaryContainer",
  copilotColorTertiary: "copilotColorTertiary",
  copilotColorOnTertiary: "copilotColorOnTertiary",
  copilotColorTertiaryContainer: "copilotColorTertiaryContainer",
  copilotColorOnTertiaryContainer: "copilotColorOnTertiaryContainer",
  copilotColorError: "copilotColorError",
  copilotColorOnError: "copilotColorOnError",
  copilotColorErrorContainer: "copilotColorErrorContainer",
  copilotColorErrorContainerRgb: "copilotColorErrorContainerRgb",
  copilotColorOnErrorContainer: "copilotColorOnErrorContainer",
  copilotColorBackground: "copilotColorBackground",
  copilotColorOnBackground: "copilotColorOnBackground",
  copilotColorSurface: "copilotColorSurface",
  copilotColorOnSurface: "copilotColorOnSurface",
  copilotColorOnSurfaceRgb: "copilotColorOnSurfaceRgb",
  copilotColorSurfaceVariant: "copilotColorSurfaceVariant",
  copilotColorOnSurfaceVariant: "copilotColorOnSurfaceVariant",
  copilotColorOutline: "copilotColorOutline",
  copilotColorOutlineVariant: "copilotColorOutlineVariant",
  copilotColorShadow: "copilotColorShadow",
  copilotColorScrim: "copilotColorScrim",
  copilotColorScrimRgb: "copilotColorScrimRgb",
  copilotColorInverseSurface: "copilotColorInverseSurface",
  copilotColorInverseOnSurface: "copilotColorInverseOnSurface",
  copilotColorInversePrimary: "copilotColorInversePrimary",
  copilotColorPrimaryFixed: "copilotColorPrimaryFixed",
  copilotColorOnPrimaryFixed: "copilotColorOnPrimaryFixed",
  copilotColorPrimaryFixedDim: "copilotColorPrimaryFixedDim",
  copilotColorOnPrimaryFixedVariant: "copilotColorOnPrimaryFixedVariant",
  copilotColorSecondaryFixed: "copilotColorSecondaryFixed",
  copilotColorOnSecondaryFixed: "copilotColorOnSecondaryFixed",
  copilotColorSecondaryFixedDim: "copilotColorSecondaryFixedDim",
  copilotColorOnSecondaryFixedVariant: "copilotColorOnSecondaryFixedVariant",
  copilotColorTertiaryFixed: "copilotColorTertiaryFixed",
  copilotColorOnTertiaryFixed: "copilotColorOnTertiaryFixed",
  copilotColorTertiaryFixedDim: "copilotColorTertiaryFixedDim",
  copilotColorOnTertiaryFixedVariant: "copilotColorOnTertiaryFixedVariant",
  copilotColorSurfaceDim: "copilotColorSurfaceDim",
  copilotColorSurfaceBright: "copilotColorSurfaceBright",
  copilotColorSurfaceContainerLowest: "copilotColorSurfaceContainerLowest",
  copilotColorSurfaceContainerLow: "copilotColorSurfaceContainerLow",
  copilotColorSurfaceContainer: "copilotColorSurfaceContainer",
  copilotColorSurfaceContainerHigh: "copilotColorSurfaceContainerHigh",
  copilotColorSurfaceContainerHighest: "copilotColorSurfaceContainerHighest",
  copilotColorChatMessageBackground: "copilotColorChatMessageBackground",
  copilotColorChatMessageText: "copilotColorChatMessageText",
  copilotColorChatBotMessageBackground: "copilotColorChatBotMessageBackground",
  copilotColorChatBotMessageText: "copilotColorChatBotMessageText",
};

export const copilot_chat_fields: Record<keyof ChatPluginThemeColors, string> =
  {
    chatBackground: "--copilot-chat-background",
    botMessageBackgroundColor: "--copilot-color-chat-bot-message-background",
    buttonBackgroundColor: "--copilot-chat-background",
    botMessageTextColor: "--copilot-color-chat-bot-message-text",
    buttonTextColor: "--copilot-on-background",
    buttonBorderColor: "--copilot-border-color",
    userInputBackgroundColor: "--copilot-color-surface-container",
    userInputStrokeColor: "--copilot-border-color",
    userInputTextColor: "--copilot-color-on-surface",
    userInputPlaceholderTextColor: "--copilot-dialog-input-placeholder",
    userInputSendButtonBackgroundColor: "--copilot-chat-background",
    userInputSendButtonIconColor: "--copilot-color-on-surface",
    characterLimitColor: "--copilot-color-on-surface",
    userMessageBackgroundColor: "--copilot-color-chat-message-background",
    userMessageTextColor: "--copilot-color-chat-message-text",
    copilotSourceOnBackgroundColor: "--copilot-color-primary",
    copilotSourceBackgroundColor: "--copilot-color-surface-variant",
    copilotInactiveSourceColor: "--copilot-on-sidebar-background",
    copilotInactiveSourceBackgroundColor: "--copilot-color-surface-variant",
    buttonMoreBackgroundColor: "--copilot-button-more-background-color",
    buttonMoreTextColor: "--copilot-on-background",
    copilotFeedbackOpenDialogButton: "--copilot-color-surface-variant",
    copilotFeedbackDialogButtonColor: "--copilot-color-on-surface-variant",
    copilotFeedbackDialogBackgroundColor: "--copilot-dialog-background",
    copilotFeedbackBorderColor: "--copilot-border-color",
    copilotFeedbackDividerColor: "--copilot-border-color-variant",
    copilotColorPrimary: "--copilot-color-primary",
    copilotColorOnPrimary: "--copilot-color-on-primary",
    copilotFeedbackPlaceholderColor: "--copilot-dialog-input-placeholder",
    copilotIconColor: "--copilot-color-icon",
  };
