import { of } from "rxjs";
import { catchError, debounceTime, map, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import { getAgentUrl } from "lib/util";
import { setMessagePrediction } from "store/actions/nlp";

export const parseMessageEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_MESSAGE_PREDICTION),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${getAgentUrl(state$.value.language)}/model/parse`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { text: action.payload },
      }).pipe(
        map((response) => {
          return setMessagePrediction(response.response);
        }),
        catchError((error) => {
          return of(setMessagePrediction(null), errorMessage(error.message));
        })
      );
    })
  );
};
