import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchUsers = () => ({
  type: Actions.FETCH_USERS,
  token: getKeycloakToken(),
});

export const setUsers = (data) => ({
  type: Actions.SET_USERS,
  payload: data,
});

export const fetchUser = (data) => ({
  type: Actions.FETCH_USER,
  payload: data,
  token: getKeycloakToken(),
});

export const setUser = (data) => ({
  type: Actions.SET_USER,
  payload: data,
});

export const fetchUserSettings = (data) => ({
  type: Actions.FETCH_USER_SETTINGS,
  payload: data,
  token: getKeycloakToken(),
});

export const setUserSettings = (data) => ({
  type: Actions.SET_USER_SETTINGS,
  payload: data,
});

export const saveUserSettings = (data) => ({
  type: Actions.SAVE_USER_SETTINGS,
  payload: data,
  token: getKeycloakToken(),
});

export const saveUserSettingsFulfilled = (data) => ({
  type: Actions.SAVE_USER_SETTINGS_FULFILLED,
});

export const createUser = (data) => ({
  type: Actions.CREATE_USER,
  payload: data,
  token: getKeycloakToken(),
});

export const createUserFulfilled = () => ({
  type: Actions.CREATE_USER_FULFILLED,
});

export const deleteUser = (data) => ({
  type: Actions.DELETE_USER,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteUserFulfilled = (data) => ({
  type: Actions.DELETE_USER_FULFILLED,
  payload: data,
});

export const updateUser = (data) => ({
  type: Actions.UPDATE_USER,
  payload: data,
  token: getKeycloakToken(),
});

export const updateUserFulfilled = () => ({
  type: Actions.UPDATE_USER_FULFILLED,
});

export const resetUserPassword = (data) => ({
  type: Actions.RESET_USER_PASSWORD,
  payload: data,
  token: getKeycloakToken(),
});

export const resetUserPasswordFulfilled = () => ({
  type: Actions.RESET_USER_PASSWORD_FULFILLED,
});

export const resendUserEmailVerification = (data) => ({
  type: Actions.RESEND_USER_EMAIL_VERIFICATION,
  payload: data,
  token: getKeycloakToken(),
});

export const resendUserEmailVerificationFulfilled = () => ({
  type: Actions.RESEND_USER_EMAIL_VERIFICATION_FULFILLED,
});
