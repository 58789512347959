import Actions from "../constants";

export const setSnackbarMessages = (currentState, action) => {
  if (action.type === Actions.SNACKBAR_SUCCESS) {
    return [
      {
        type: "success",
        message: action.message,
        anchorOrigin: action.anchorOrigin,
        duration: action.duration,
        customTranslation: action.customTranslation,
      },
    ];
  }
  if (action.type === Actions.SNACKBAR_INFO) {
    return [
      {
        type: "info",
        message: action.message,
        anchorOrigin: action.anchorOrigin,
        duration: action.duration,
        customTranslation: action.customTranslation,
      },
    ];
  }
  if (action.type === Actions.SNACKBAR_WARNING) {
    return [
      {
        type: "warning",
        message: action.message,
        anchorOrigin: action.anchorOrigin,
        duration: action.duration,
        customTranslation: action.customTranslation,
      },
    ];
  }
  if (action.type === Actions.SNACKBAR_ERROR) {
    return [
      {
        type: "error",
        message: action.message,
        anchorOrigin: action.anchorOrigin,
        duration: action.duration,
        customTranslation: action.customTranslation,
      },
    ];
  }
  if (action.type === Actions.SNACKBAR_CLEAR) {
    return [];
  }

  return currentState;
};
