import React from "react";
import Typography from "@mui/material/Typography";
import getConfig from "lib/config";
import dynamic from "next/dynamic";

const { publicRuntimeConfig } = getConfig();

const { REACT_APP_TENANT } = publicRuntimeConfig;
const LogoThumb = dynamic(() => {
  if (REACT_APP_TENANT === "aar") return import("./LogoAareon");
  return import("./LogoBots4You");
});

interface LogoProps {
  thumbnail?: boolean;
}

export function Logo(props: LogoProps) {
  return (
    <>
      {/*@ts-ignore*/}
      <LogoThumb />
      {props.thumbnail && (
        <Typography
          sx={{ marginLeft: 2, fontFamily: "intermedium, sans-serif" }} // aar: "SkolarSans", GillSans, Calibri, Trebuchet, sans-serif;
          color="white"
        >
          {REACT_APP_TENANT === "aar" ? "Aareon" : "bots4you"}
        </Typography>
      )}
    </>
  );
}
