import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchDecisionTrees = () => ({
  type: Actions.FETCH_DECISION_TREES,
  token: getKeycloakToken(),
});

export const setDecisionTrees = (data) => ({
  type: Actions.SET_DECISION_TREES,
  payload: data,
});

export const fetchDecisionTreesFulfilled = () => ({
  type: Actions.FETCH_DECISION_TREES_FULFILLED,
});
