import Actions from "../constants";

export const setCannedResponses = (currentState, action) => {
  if (action.type === Actions.SET_CANNED_RESPONSES) {
    return action.payload;
  }
  if (action.type === Actions.DELETE_CANNED_RESPONSE_FULFILLED) {
    return currentState.filter((e) => e.id !== action.payload);
  }
  return currentState;
};

export const setCannedResponse = (currentState, action) => {
  if (action.type !== Actions.SET_CANNED_RESPONSE) {
    return currentState;
  }
  return action.payload;
};
