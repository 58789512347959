import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

// console.log(
//   ({
//     id = "",
//     //how many tickets to skip
//     offset = 0,
//     //how many tickets to fetch
//     limit = 50,
//     sync_external = false,
//     priority = "",
//     status = "",
//     sender_id = "",
//     contract_id = "",
//     description = "",
//     title = "",
//     contact_phone = "",
//     contact_mail = "",
//     article_id = "",
//     room_id = "",
//     user_id = "",
//     client_id = "",
//     external_id = "",
//   } = {})
// );
export const fetchRentalUnitTickets = ({
  id = "",
  //how many tickets to skip
  offset = 0,
  //how many tickets to fetch
  limit = 999999,
  sync_external = "",
  priority = "",
  status = "",
  sender_id = "",
  contract_id = "",
  description = "",
  title = "",
  contact_phone = "",
  contact_mail = "",
  article_id = "",
  room_id = "",
  user_id = "",
  client_id = "",
  external_id = "",
} = {}) => ({
  type: Actions.FETCH_RENTAL_UNIT_TICKETS,
  payload: {
    id,
    offset,
    limit,
    sync_external,
    priority,
    status,
    sender_id,
    contract_id,
    description,
    title,
    contact_phone,
    contact_mail,
    article_id,
    room_id,
    user_id,
    client_id,
    external_id,
  },
  token: getKeycloakToken(),
});

export const setOpenTickets = (data) => ({
  type: Actions.SET_OPEN_TICKETS,
  payload: data,
});

export const setSolvedTickets = (data) => ({
  type: Actions.SET_SOLVED_TICKETS,
  payload: data,
});

export const setInProgressTickets = (data) => ({
  type: Actions.SET_IN_PROGRESS_TICKETS,
  payload: data,
});

export const setPendingTickets = (data) => ({
  type: Actions.SET_PENDING_TICKETS,
  payload: data,
});

export const setForwardedTickets = (data) => ({
  type: Actions.SET_FORWARDED_TICKETS,
  payload: data,
});
export const setClosedTickets = (data) => ({
  type: Actions.SET_CLOSED_TICKETS,
  payload: data,
});

export const fetchRentalUnitTicketCountByStatus = () => ({
  type: Actions.FETCH_RENTAL_UNIT_TICKET_COUNT_BY_STATUS,
  token: getKeycloakToken(),
});

export const setRentalUnitTicketCountByStatus = (data) => ({
  type: Actions.SET_RENTAL_UNIT_TICKET_COUNT_BY_STATUS,
  payload: data,
});

export const selectTickets = (tickets) => ({
  type: Actions.SELECT_TICKETS,
  payload: tickets,
});

export const patchRentalUnitTickets = (data) => ({
  type: Actions.PATCH_RENTAL_UNIT_TICKETS,
  payload: data,
  token: getKeycloakToken(),
});
export const patchRentalUnitTicketsFulfilled = () => ({
  type: Actions.PATCH_RENTAL_UNIT_TICKETS_FULFILLED,
});
