import FlagProvider, { useUnleashContext } from "@unleash/proxy-client-react";
import React, { useEffect } from "react";
import { firstLetterCapitalize } from "../../lib/util";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();

const { REACT_APP_UNLEASH_URL, REACT_APP_UNLEASH_ENABLED } =
  publicRuntimeConfig;

const config = {
  url: REACT_APP_UNLEASH_URL,
  clientKey: "some_secret",
  refreshInterval: 120,
  appName: "default",
  environment: "dev",
};

function UnleashToggles(props) {
  const { tenant } = props;
  const updateContext = useUnleashContext();

  useEffect(() => {
    if (updateContext && tenant) {
      updateContext({
        userId: firstLetterCapitalize(tenant),
      });
    }
  }, [updateContext, tenant]);

  return <>{props.children}</>;
}

export default function UnleashProvider(props) {
  const { tenant } = props;

  if (REACT_APP_UNLEASH_ENABLED === "1") {
    return (
      <FlagProvider config={config}>
        <UnleashToggles tenant={tenant}>{props.children} </UnleashToggles>
      </FlagProvider>
    );
  } else {
    return <>{props.children}</>;
  }
}
