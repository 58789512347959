import Actions from "../constants";

export const fetchSynonyms = () => ({
  type: Actions.FETCH_SYNONYMS,
});

export const setSynonyms = (data) => ({
  type: Actions.SET_SYNONYMS,
  payload: data,
});
