import Actions from "../constants";

export const setOpenTickets = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SET_OPEN_TICKETS) {
    return currentState;
  }

  return action.payload;
};
export const setSolvedTickets = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SET_SOLVED_TICKETS) {
    return currentState;
  }

  return action.payload;
};
export const setInProgressTickets = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SET_IN_PROGRESS_TICKETS) {
    return currentState;
  }

  return action.payload;
};
export const setPendingTickets = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SET_PENDING_TICKETS) {
    return currentState;
  }

  return action.payload;
};
export const setForwardedTickets = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SET_FORWARDED_TICKETS) {
    return currentState;
  }

  return action.payload;
};
export const setClosedTickets = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SET_CLOSED_TICKETS) {
    return currentState;
  }

  return action.payload;
};
export const setRentalUnitTicketCountByStatus = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SET_RENTAL_UNIT_TICKET_COUNT_BY_STATUS) {
    return currentState;
  }

  return action.payload;
};
export const selectTickets = (
  currentState: any,
  action: { type: string; payload: any }
) => {
  if (action.type !== Actions.SELECT_TICKETS) {
    return currentState;
  }

  return action.payload;
};
