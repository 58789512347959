import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Switch from "@mui/material/Switch";

import withStyles from "@mui/styles/withStyles";

const StyledSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "white",
    },
    "&$checked + $track": {
      backgroundColor: "#148801",
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: "row",
    width: "100%",
  },
  label: {
    display: "inline-flex",
    alignItems: "center",
    marginRight: "auto",
    fontSize: theme.typography.pxToRem(14),
  },
}));

const SimpleSwitchInput = ({ nodeValue, label, onChange }) => {
  const classes = useStyles();

  const onSwitch = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormControl
      className={classes.formControl}
      component="fieldset"
      variant="outlined"
    >
      <FormLabel className={classes.label} component="span">
        {label}
      </FormLabel>
      <StyledSwitch value={nodeValue} checked={nodeValue} onChange={onSwitch} />
    </FormControl>
  );
};

export default SimpleSwitchInput;
