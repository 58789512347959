import { Observable, of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  debounceTime,
  concatMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import { patchTicketFulfilled, setTickets } from "../actions/tickets";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_AGENT_HOST } = publicRuntimeConfig;

// const res = await this.props.keycloakFetchApiHook.fetchKeycloakApi(
//       `/intent_mapping/tickets?language=${lang}`,
//       "tracker"
//     );

export const fetchTicketsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TICKETS),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_AGENT_HOST}/intent_mapping/tickets?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    map((response) => {
      return setTickets(response.response);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );
};

export const patchTicketEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.PATCH_TICKET),
    debounceTime(500),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_AGENT_HOST}/intent_mapping/ticket/${action.ticketId}?language=${state$.value.language}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          return of(patchTicketFulfilled(), setTickets(null));
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );
};
