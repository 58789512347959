import React from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import getConfig from "lib/config";
import { WarningWrapper } from "features/admin/components/EditTriggerNodes/TriggerNodesEditAndSelectionDialog";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import cls from "./styles/edit_trigger_globally.module.scss";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_TENANT } = publicRuntimeConfig;

type TriggerNodeInputsProps = {
  selectedStates: any;
  triggerNodeId: any;
  handleChange: any;
  triggerNodesInputSentences: any;
  deletedSentences: any;
  setDeletedSentences: any;
  setArchivedDeletedSentences: any;
  archivedDeletedSentences: any;
  setNewTriggerNodesInputSentences: (data: any) => void;
  triggerNodesInputs: { [key: string]: string[] };
};

export default function TriggerNodeInputs({
  setDeletedSentences,
  selectedStates,
  triggerNodeId,
  handleChange,
  setArchivedDeletedSentences,
  archivedDeletedSentences,
  triggerNodesInputs,
  triggerNodesInputSentences,
  setNewTriggerNodesInputSentences,
}: TriggerNodeInputsProps): JSX.Element {
  // const[triggerNodesInputSentences, setTriggerNodesInputSentences] = useState<any>(() => )

  const onRemoveSentence = (
    event: any,
    index: number,
    triggerNodesInputsId: string,
    triggerNodesInputSentence: string
  ) => {
    event.preventDefault();
    event.stopPropagation();

    // deletedSentencesObject = {
    //   ...deletedSentences,
    //   [triggerNodeId]: {
    //     ...deletedSentences[triggerNodeId],
    //     [triggerNodesInputsId]: [
    //       ...deletedSentences[triggerNodeId][triggerNodesInputsId],
    //       triggerNodesInputSentence,
    //     ],
    //   },
    // };

    setDeletedSentences(
      (prevDeletedSentences: { [x: string]: { [y: string]: string[] } }) => {
        const currentTriggerNode = prevDeletedSentences[triggerNodeId] || {};
        const currentTriggerNodesInputs =
          currentTriggerNode[triggerNodesInputsId] || [];

        return {
          ...prevDeletedSentences,
          [triggerNodeId]: {
            ...currentTriggerNode,
            [triggerNodesInputsId]: [
              ...currentTriggerNodesInputs,
              triggerNodesInputSentence,
            ],
          },
        };
      }
    );
    setArchivedDeletedSentences(
      (prevArchivedDeletedSentences: {
        [x: string]: { [y: string]: string[] };
      }) => {
        const currentTriggerNode =
          prevArchivedDeletedSentences[triggerNodeId] || {};
        const currentTriggerNodesInputs =
          currentTriggerNode[triggerNodesInputsId] || [];
        return {
          ...prevArchivedDeletedSentences,
          [triggerNodeId]: {
            ...currentTriggerNode,
            [triggerNodesInputsId]: [
              ...currentTriggerNodesInputs,
              triggerNodesInputSentence,
            ],
          },
        };
      }
    );

    const newSplicedSentencesArray = [
      ...triggerNodesInputSentences[triggerNodeId][triggerNodesInputsId],
    ];

    newSplicedSentencesArray.splice(index, 1);
    const newTriggerNodesInputSentencesArray = {
      [triggerNodesInputsId]: newSplicedSentencesArray,
    };
    setNewTriggerNodesInputSentences(newTriggerNodesInputSentencesArray);
  };

  return (
    <Stack
      key={triggerNodeId}
      // direction={"row"}
      sx={{
        position: "relative",
        padding: "10px 8px 12px 10px",
        borderRadius: "9px",
        gap: "4px",
        alignItems: "flex-start",
        backgroundColor: "#f9f4fbff",
        ...(REACT_APP_TENANT === "aar" && {
          backgroundColor: "rgba(194 ,224 ,254 ,0.26)",
        }),
        ...(Object.keys(selectedStates[triggerNodeId]).every(
          (inputId) => !selectedStates[triggerNodeId][inputId]
        ) && {
          backgroundColor: "#f8f8f8ff",
        }),
        "& >fieldset": {
          borderRadius: "9px",
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: "#8b61a5",
          fontSize: "0.75em",
          "& >legend": {
            paddingInline: "5px",
            fontWeight: "500",
            "& >p": {
              display: "none",
              m: 0,
              cursor: "pointer",
            },
            "& >span": { cursor: "pointer" },
            "&:hover >p": { display: "block" },
            "&:hover >span": { display: "none" },
          },
          ...(REACT_APP_TENANT === "aar" && {
            //#3498ff
            borderColor: "#83b4fb",
          }),
          ...(Object.keys(selectedStates[triggerNodeId]).every(
            (inputId) => !selectedStates[triggerNodeId][inputId]
          ) && {
            borderColor: "#b5b5b5",
            color: "rgb(0 0 0 / 46%)",
          }),
        },
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            sx={{ padding: 0, height: "18px", width: "18px" }}
            checked={Object.keys(selectedStates[triggerNodeId]).every(
              (inputId) => selectedStates[triggerNodeId][inputId]
            )}
            onChange={(event) => handleChange(event)}
            name={triggerNodeId}
          />
        }
        label={undefined}
        sx={{
          m: 0,
        }}
      />
      <Stack sx={{ pl: "24px", gap: "12px" }}>
        {triggerNodesInputs[triggerNodeId].map(
          (triggerNodesInputsId: any, index: any) => (
            <>
              {triggerNodesInputSentences[triggerNodeId][
                triggerNodesInputsId
              ] &&
              triggerNodesInputSentences[triggerNodeId][triggerNodesInputsId]
                .length === 0 ? undefined : (
                <>
                  {" "}
                  {index > 0 ? (
                    <WarningWrapper
                      nodeType={"Trigger Node Input"}
                      mapKey={triggerNodesInputsId}
                    >
                      <TriggerNodeInput
                        archivedDeletedSentences={archivedDeletedSentences}
                        triggerNodesInputsId={triggerNodesInputsId}
                        selectedStates={selectedStates}
                        triggerNodeId={triggerNodeId}
                        handleChange={handleChange}
                        triggerNodesInputSentences={triggerNodesInputSentences}
                        onRemove={(
                          event: any,
                          index: number,
                          triggerNodesInputsId: string,
                          triggerNodesInputSentence: string
                        ) =>
                          onRemoveSentence(
                            event,
                            index,
                            triggerNodesInputsId,
                            triggerNodesInputSentence
                          )
                        }
                      />
                    </WarningWrapper>
                  ) : (
                    <TriggerNodeInput
                      archivedDeletedSentences={archivedDeletedSentences}
                      triggerNodesInputsId={triggerNodesInputsId}
                      selectedStates={selectedStates}
                      triggerNodeId={triggerNodeId}
                      handleChange={handleChange}
                      triggerNodesInputSentences={triggerNodesInputSentences}
                      onRemove={(
                        event: any,
                        index: number,
                        triggerNodesInputsId: string,
                        triggerNodesInputSentence: string
                      ) =>
                        onRemoveSentence(
                          event,
                          index,
                          triggerNodesInputsId,
                          triggerNodesInputSentence
                        )
                      }
                    />
                  )}
                </>
              )}
            </>
          )
        )}
      </Stack>
      <fieldset className={cls["trigger_node_fieldset"]}>
        <legend>
          <p>{triggerNodeId}</p>
          <span>Trigger Node</span>
        </legend>
      </fieldset>
    </Stack>
  );
}

const TriggerNodeInput = ({
  triggerNodesInputsId,
  selectedStates,
  triggerNodeId,
  handleChange,
  triggerNodesInputSentences,
  onRemove,
  archivedDeletedSentences,
}: {
  triggerNodesInputsId: string;
  selectedStates: any;
  triggerNodeId: string;
  handleChange: any;
  triggerNodesInputSentences: { [key: string]: { [key: string]: string[] } };
  onRemove: any;
  archivedDeletedSentences: any;
}) => {
  function renderDeletedList() {
    if (archivedDeletedSentences[triggerNodeId]) {
      return Object.keys(archivedDeletedSentences[triggerNodeId]).map(
        (triggerInputId) => {
          if (triggerInputId === triggerNodesInputsId) {
            return archivedDeletedSentences[triggerNodeId][triggerInputId].map(
              (
                triggerNodeInputId:
                  | boolean
                  | React.ReactChild
                  | React.ReactFragment
                  | React.ReactPortal
                  | null
                  | undefined,
                index: any
              ) => {
                return (
                  <ListItem
                    disablePadding
                    key={triggerNodeInputId + index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "4px",
                      fontWeight: "500",
                      height: "40px",
                    }}
                  >
                    <ListItemText
                      sx={{
                        fontWeight: "500",
                        m: "0",
                        "& .MuiTypography-root": {
                          fontSize: "0.875rem",
                          color: "rgba(214, 61, 61, 0.43)",
                          textDecoration: "line-through",
                        },
                      }}
                      primary={triggerNodeInputId}
                    />
                  </ListItem>
                );
              }
            );
          }
        }
      );
    }
  }

  return (
    <FormControlLabel
      sx={{
        m: 0,
      }}
      control={
        <Stack
          key={triggerNodesInputsId}
          direction={"row"}
          sx={{
            position: "relative",
            backgroundColor: "#f7fffc",
            // boxShadow: "0 1px 6px rgba(32,33,36,.28)",
            alignItems: "flex-start",
            fontWeight: "500",
            borderRadius: "9px",
            padding: "12px",
            gap: "12px",
            ...(!selectedStates[triggerNodeId][triggerNodesInputsId] && {
              backgroundColor: "#fdfdfd",
              color: "rgb(0 0 0 / 46%)",
              boxShadow: "none",
            }),
            "& >fieldset": {
              borderRadius: "9px",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "var(--green)",
              fontSize: "0.75em",
              "& >legend": {
                paddingInline: "5px",
                "& >p": {
                  display: "none",
                  m: 0,
                  cursor: "pointer",
                },
                "& >span": { cursor: "pointer" },
              },

              ":hover& >fieldset legend p": { display: "block" },
              ":hover& >fieldset legend span": { display: "none" },
              ...(!selectedStates[triggerNodeId][triggerNodesInputsId] && {
                borderColor: "rgb(0 0 0 / 46%)",
              }),
            },
          }}
        >
          <Checkbox
            sx={{ padding: 0, height: "18px", width: "18px" }}
            checked={selectedStates[triggerNodeId][triggerNodesInputsId]}
            onChange={(event) => {
              event.stopPropagation();
              handleChange(event, triggerNodeId);
            }}
            name={triggerNodesInputsId}
          />{" "}
          <List
            sx={{
              padding: "0",
              maxHeight: "205px",
              overflowY: "auto",
              "& >li + li": {
                borderTop: "1px solid rgba(0 ,0, 0, 0.08)",
              },
            }}
          >
            {triggerNodesInputSentences[triggerNodeId][triggerNodesInputsId] &&
              triggerNodesInputSentences[triggerNodeId][
                triggerNodesInputsId
              ].map((triggerNodesInputSentence: string, index: any) => (
                <ListItem
                  disablePadding
                  key={triggerNodesInputSentence}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    fontWeight: "500",
                  }}
                >
                  <ListItemText
                    sx={{
                      fontWeight: "500",
                      m: "0",
                      "& .MuiTypography-root": {
                        fontSize: "0.875rem",
                      },
                    }}
                    primary={triggerNodesInputSentence}
                  />
                  <IconButton
                    onClick={(event) =>
                      onRemove(
                        event,
                        index,
                        triggerNodesInputsId,
                        triggerNodesInputSentence
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            {Object.keys(archivedDeletedSentences).length > 0 &&
              renderDeletedList()}
          </List>
          <fieldset className={cls["trigger_node_input_fieldset"]}>
            <legend>
              <span>Trigger Node Input(s)</span>
              <p>{triggerNodesInputsId}</p>
            </legend>
          </fieldset>
        </Stack>
      }
      label={undefined}
    />
  );
};
