import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchLabels = () => ({
  type: Actions.FETCH_LABELS,
  token: getKeycloakToken(),
});

export const setLabels = (data) => ({
  type: Actions.SET_LABELS,
  payload: data,
});

export const fetchLabel = (data) => ({
  type: Actions.FETCH_LABEL,
  payload: data,
  token: getKeycloakToken(),
});

export const setLabel = (data) => ({
  type: Actions.SET_LABEL,
  payload: data,
});

export const createLabel = (data) => ({
  type: Actions.CREATE_LABEL,
  payload: data,
  token: getKeycloakToken(),
});

export const createLabelFulfilled = () => ({
  type: Actions.CREATE_LABEL_FULFILLED,
});

export const deleteLabel = (data) => ({
  type: Actions.DELETE_LABEL,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteLabelFulfilled = (data) => ({
  type: Actions.DELETE_LABEL_FULFILLED,
  payload: data,
});

export const fetchLabelsForConversation = (data) => ({
  type: Actions.FETCH_LABELS_FOR_CONVERSATION,
  payload: data,
  token: getKeycloakToken(),
});

export const setLabelsForConversation = (data) => ({
  type: Actions.SET_LABELS_FOR_CONVERSATION,
  payload: data,
});

export const addLabelToConversation = (data) => ({
  type: Actions.ADD_LABEL_TO_CONVERSATION,
  payload: data,
  token: getKeycloakToken(),
});

export const addLabelToConversationFulfilled = () => ({
  type: Actions.ADD_LABEL_TO_CONVERSATION_FULFILLED,
});

export const deleteLabelFromConversation = (data) => ({
  type: Actions.DELETE_LABEL_FROM_CONVERSATION,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteLabelFromConversationFulfilled = (data) => ({
  type: Actions.DELETE_LABEL_FROM_CONVERSATION_FULFILLED,
  payload: data,
});
