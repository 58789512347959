import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Box, { BoxProps } from "@mui/material/Box";

interface PlaceholderImageProps extends BoxProps {}

const PlaceholderImage = (props: PlaceholderImageProps) => {
  return (
    <Box
      sx={{
        width: props.width,
        height: props.height,
        backgroundColor: "#cccccc",
        display: "flex",
      }}
      {...props}
    >
      <ImageSearchIcon sx={{ color: "#4f4f4f", margin: "auto" }} />
    </Box>
  );
};

export default PlaceholderImage;
