import { Author, Collection, Document } from "types/Interfaces/smartDoc";
import { SxProps } from "@mui/material/styles";
import debounce from "lodash.debounce";
import {
  DEFAULT_COLLECTIONS_IDS,
  DEFAULT_COLLECTIONS_IDS_NOT_TRASH,
  FAVORITES_COLLECTION_ID,
  READ_LATER_COLLECTION_ID,
  TRASH_COLLECTION_ID,
} from "features/smart-doc/utils/collections";
import { SetStateAction } from "react";
import { MenuProps } from "@mui/material/Menu";
import {
  copilot_chat_fields,
  copilot_fields_variables_mapping,
} from "features/copilot/themeFields";
import copilot_themes from "features/copilot/copilot_themes.json";
import { CopilotThemes, ThemeColors } from "types/Interfaces/copilot";
import {
  argbToRgba,
  hexToRgba,
} from "features/smart-doc/components/Documents/DocumentViewer/helpers/colorHelpers";
import { argbFromRgb, Hct } from "@material/material-color-utilities";

export const getAssociatedDocuments = (
  collectionId: string,
  collections: any[],
  collection: Collection,
  documentsInfoList: any[]
) => {
  let result: Document[];
  const collectionsCopy = collections;
  if (collection?.name !== "_trash") {
    const trashCollection = collectionsCopy.find(
      (collection) => collection.name === "_trash"
    );
    const trashDocumentIds = new Set(
      trashCollection ? trashCollection.documents : []
    );

    result = documentsInfoList.filter(
      (document) => !trashDocumentIds.has(document.id)
    );
  } else {
    result = documentsInfoList;
  }
  // if (searchQuery && searching) {
  //   result = documentsInfoList.filter((document) => {
  //     return document.name.includes(searchQuery);
  //   });
  //   setSubmitSearch(false);
  // }
  if (collectionId && collectionsCopy) {
    //filter: Collection
    const searchCollectionTree = (currentId: string): Collection[] => {
      if (collectionsCopy) {
        if (collection) {
          let array = [collection];
          const search = (collection: Collection) => {
            const children = collectionsCopy.filter(
              (coll) => coll.parent_id === collection.id
            );

            if (children.length > 0) {
              array = [...array, ...children];
              children.map((coll) => search(coll));
            }
          };
          search(collection);
          return array;
        }
        return [];
      }

      return [];
    };
    const collectionTree = searchCollectionTree(collectionId);

    let treeCollectionsDocumentIds: string[] = [];
    collectionTree.forEach((collection) => {
      treeCollectionsDocumentIds = [
        ...treeCollectionsDocumentIds,
        ...collection.documents,
      ];
    });
    result = result.filter((item) =>
      treeCollectionsDocumentIds.includes(item.id)
    );
  }

  return result;
};

export const getChatPluginPostMessageThemeConfig = (copilotColorMode: any) => {
  let copilotThemeConfig;
  const chatPluginStyleFields = { ...copilot_chat_fields };
  Object.keys(chatPluginStyleFields).forEach((key) => {
    const cssColorValue = getCssVariableValueColor(
      copilotColorMode,
      chatPluginStyleFields[key]
    );

    if (cssColorValue) {
      chatPluginStyleFields[key] = rgbOrHexToRgba(cssColorValue, 1);
    }
  });

  copilotThemeConfig = {
    config: {
      ...chatPluginStyleFields,
    },
  };
  return copilotThemeConfig;
};

export const getCssVariableValueColor = (
  copilotColorMode: string,
  value: string,
  fromRoot?: boolean
) => {
  if (fromRoot) {
    const root = document.documentElement;
    return window.getComputedStyle(root).getPropertyValue(value).trim();
  }

  const element = document.querySelector(
    `.copilot[data-theme=${copilotColorMode}]`
  );

  if (element) {
    const style = getComputedStyle(element);

    return style.getPropertyValue(value).trim();
  }
};
export const copilotInputStyles = (
  copilotColorMode: string,
  size?: string
): SxProps => ({
  fontSize: "16px",

  "& .MuiOutlinedInput-root": {
    border: `1px solid ${getCssVariableValueColor(
      copilotColorMode,
      "--copilot-border-color"
    )}`,
    borderRadius: "5px",
    transition: "border-color ease-in-out 0.3s",
    backgroundColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-background"
    ),
    ...(size === "small" && {
      padding: "0px 8px",
      fontSize: "14px",
      "& .MuiOutlinedInput-input": {
        padding: "8px 0px",
      },
    }),
    "& fieldset.MuiOutlinedInput-notchedOutline": { border: "none" },
    "& >input": {
      color: getCssVariableValueColor(
        copilotColorMode,
        "--copilot-dialog-input-color"
      ),
      "&::placeholder": {
        color: getCssVariableValueColor(
          copilotColorMode,
          "--copilot-dialog-input-placeholder"
        ),
        opacity: 1,
      },
    },
    "&.Mui-focused": {
      border: `1px solid ${getCssVariableValueColor(
        copilotColorMode,
        "--copilot-color-primary"
      )}`,
    },
  },
  "&:hover .MuiOutlinedInput-root": {
    borderColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-color-primary"
    ),
  },
});
export const copilotPrimaryButtonStyle = (
  copilotColorMode: string
): SxProps => ({
  color: getCssVariableValueColor(
    copilotColorMode,
    "--copilot-color-on-primary"
  ),
  backgroundColor: getCssVariableValueColor(
    copilotColorMode,
    "--copilot-color-primary"
  ),

  transition: getCssVariableValueColor(
    copilotColorMode,
    "--copilot-button-transition"
  ),
  "&:hover": {
    color: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-on-button-hover-color"
    ),
    backgroundColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-button-hover-color"
    ),
  },
});
export const copilotSecondaryButtonStyle = (
  copilotColorMode: string
): SxProps => ({
  color: getCssVariableValueColor(
    copilotColorMode,
    "--copilot-on-dialog-background"
  ),
  padding: "6px 16px",
  transition: `${getCssVariableValueColor(
    copilotColorMode,
    "--copilot-button-transition"
  )} box-shadow 0.25s ease-in-out`,

  "&:hover": {
    color: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-color-on-primary"
    ),
    backgroundColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-color-primary"
    ),
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  },
});
export const copilotModalBackgroundStyle = (
  copilotColorMode: string
): SxProps => ({
  "& .MuiBackdrop-root": {
    backgroundColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-dialog-scrim"
    ),
  },
  "& .MuiPaper-root": {
    width: "100%",
    backgroundColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-dialog-background"
    ),
    color: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-on-dialog-background"
    ),
  },
});
export const copilotSelectStyle = (copilotColorMode: string): SxProps => ({
  backgroundColor: getCssVariableValueColor(
    copilotColorMode,
    "--copilot-dialog-background"
  ),
  borderColor: getCssVariableValueColor(
    copilotColorMode,
    "--copilot-border-color"
  ),
  color: getCssVariableValueColor(
    copilotColorMode,
    "--copilot-on-dialog-background"
  ),
  "& .MuiSvgIcon-root": {
    color: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-on-dialog-background"
    ),
  },
  "&:hover": {
    borderColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-color-primary"
    ),
  },
});
export const copilotIconButtonStyle = (
  copilotColorMode: string,
  open: boolean
): SxProps => ({
  borderRadius: "5px",
  overflow: "hidden",
  ...(open && {
    backgroundColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-color-surface-variant"
    ),
  }),
  "& .MuiSvgIcon-root": {
    fill: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-color-on-surface-variant"
    ),
  },
  "&:hover": {
    backgroundColor: getCssVariableValueColor(
      copilotColorMode,
      "--copilot-color-surface-variant"
    ),
    "& .MuiSvgIcon-root": {
      fill: getCssVariableValueColor(
        copilotColorMode,
        "--copilot-color-on-surface-variant"
      ),
    },
  },
});
export const copilotOpenSelectStyle = (copilotColorMode: string): SxProps => ({
  border: `1px solid ${getCssVariableValueColor(
    copilotColorMode,
    "--copilot-sidebar-element-hover"
  )}`,
  boxShadow: "none",
});
export const copilotMenuStyles = (
  copilotColorMode: string
): Partial<MenuProps> | undefined => ({
  sx: {
    "& .MuiPaper-root": {
      backgroundColor: getCssVariableValueColor(
        copilotColorMode,
        "--copilot-dialog-background"
      ),
      color: getCssVariableValueColor(
        copilotColorMode,
        "--copilot-on-dialog-background"
      ),
    },
    "& .MuiList-root": {
      padding: "8px",
      borderRadius: "5px",
    },
    "& .MuiMenuItem-root": {
      padding: "4px 8px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      "& >p": {
        letterSpacing: "0.5px",
      },
      "&.Mui-selected": {
        backgroundColor:
          getCssVariableValueColor(
            copilotColorMode,
            "--copilot-color-surface-variant"
          ) + "!important",
        color: getCssVariableValueColor(
          copilotColorMode,
          "--copilot-color-primary"
        ),
        "&  svg": {
          display: "block",
        },
        "&:hover": {
          backgroundColor: getCssVariableValueColor(
            copilotColorMode,
            "--copilot-color-surface-variant"
          ),
        },
      },
      "&:hover": {
        backgroundColor: getCssVariableValueColor(
          copilotColorMode,
          "--copilot-color-surface-variant"
        ),
      },
    },
    "& .MuiMenuItem-root + .MuiMenuItem-root": {
      mt: "6px",
    },
  },
});
export const copilotPopoverStyle = (copilotColorMode: string): SxProps => ({
  color: getCssVariableValueColor(copilotColorMode, "--copilot-on-background"),
  backgroundColor: getCssVariableValueColor(
    copilotColorMode,
    "--copilot-dialog-surface"
  ),
  "& .MuiListItem-root": {
    "& .MuiListItemButton-root": {
      "& .MuiListItemIcon-root .MuiSvgIcon-root": {
        color: getCssVariableValueColor(
          copilotColorMode,
          "--copilot-on-background"
        ),
      },
    },
    "& .MuiListItemButton-root.Mui-selected": {
      backgroundColor: getCssVariableValueColor(
        copilotColorMode,
        "--copilot-dialog-surface"
      ),
      "& .MuiListItemIcon-root": {
        color: getCssVariableValueColor(
          copilotColorMode,
          "--copilot-on-background"
        ),
      },
    },
  },
  "& .MuiTreeItem-root": {
    "& .MuiListItemButton-root": {
      "& .MuiListItemIcon-root .MuiSvgIcon-root": {
        color: getCssVariableValueColor(
          copilotColorMode,
          "--copilot-on-background"
        ),
      },
    },
    "& .MuiListItemButton-root.Mui-selected": {
      backgroundColor: getCssVariableValueColor(
        copilotColorMode,
        "--copilot-dialog-surface"
      ),
      "& .MuiListItemIcon-root": {
        color: getCssVariableValueColor(
          copilotColorMode,
          "--copilot-on-background"
        ),
      },
    },
  },
});

export const debouncedPostMessage = debounce(
  (targetContendWindow: any, payload: any) => {
    targetContendWindow.postMessage(
      {
        ...payload,
      },
      "*"
    );
  },
  500
);

export function sortCollections(collections: Collection[]) {
  // Filter and sort system collections
  const systemCollections = collections
    .filter((collection) => collection.created_by === "SYSTEM")
    .sort((a, b) => {
      const lastDigitA = a.id[a.id.length - 1];
      const lastDigitB = b.id[b.id.length - 1];
      return lastDigitA.localeCompare(lastDigitB);
    });

  // Filter and sort non-system collections
  const nonSystemCollections = collections
    .filter((collection) => collection.created_by !== "SYSTEM")
    .sort((a, b) => a.name.localeCompare(b.name));

  // Combine both lists
  return [...systemCollections, ...nonSystemCollections];
}

export function updateCollections(
  documentSelection: any[],
  lookupCollectionById: {
    [x: string]: Collection | { documents: string | any[] };
  },
  setLookupCollectionById: {
    (value: SetStateAction<{ [key: string]: Collection } | null>): void;
    (arg0: (prevLookup: any) => any): void;
  },
  newCollectionId: string,
  setSelectedDocuments: {
    (value: SetStateAction<string[]>): void;
    (arg0: never[]): void;
  },
  selectCollectionId: {
    (value: SetStateAction<string | null>): void;
    (arg0: null): void;
  },
  setRequestInfo: {
    (value: any): void;
    (arg0: {
      add: { collectionId: any; documentIds: any } | null;
      remove: unknown[];
    }): void;
  },
  isDestinationTrash?: boolean,
  nextParentDocs?: string[],
  prevParentDocs?: string[]
) {
  const removeRequestInfo: any = {};
  const isDestinationAllDocuments = newCollectionId === "your_documents";
  documentSelection.forEach((documentId) => {
    let sourceCollectionId = Object.keys(lookupCollectionById).find(
      (collectionId) =>
        lookupCollectionById[collectionId].documents.includes(documentId)
    );
    if (!sourceCollectionId) {
      sourceCollectionId = "your_documents";
    }
    const isSourceSystemCollection =
      DEFAULT_COLLECTIONS_IDS.includes(sourceCollectionId);
    let secondSourceCollectionId = null;
    if (isSourceSystemCollection && isDestinationTrash) {
      secondSourceCollectionId = Object.keys(lookupCollectionById).find(
        (collectionId) =>
          !DEFAULT_COLLECTIONS_IDS.includes(collectionId) &&
          lookupCollectionById[collectionId].documents.includes(documentId)
      );
    }
    const isSourceAllDocuments = sourceCollectionId === "your_documents";

    const isDestinationSystemCollection =
      DEFAULT_COLLECTIONS_IDS_NOT_TRASH.includes(newCollectionId);
    if (!isSourceAllDocuments && !isDestinationSystemCollection) {
      if (!removeRequestInfo[sourceCollectionId]) {
        removeRequestInfo[sourceCollectionId] = {
          collectionId: sourceCollectionId,
          documentIds: [],
        };
      }
      removeRequestInfo[sourceCollectionId].documentIds.push(documentId);
      if (
        secondSourceCollectionId &&
        !removeRequestInfo[secondSourceCollectionId]
      ) {
        removeRequestInfo[secondSourceCollectionId] = {
          collectionId: secondSourceCollectionId,
          documentIds: [],
        };
        removeRequestInfo[secondSourceCollectionId].documentIds.push(
          documentId
        );
      }
    }
  });

  //Update lookupCollectionById
  setLookupCollectionById((prevLookup) => {
    const newLookupCollectionById = { ...prevLookup };

    Object.entries(removeRequestInfo).forEach(
      //@ts-ignore
      ([sourceCollectionId, { documentIds }]) => {
        newLookupCollectionById[sourceCollectionId].documents =
          newLookupCollectionById[sourceCollectionId].documents.filter(
            (docId) => !documentIds.includes(docId)
          );
      }
    );

    documentSelection.forEach((documentId) => {
      if (
        newLookupCollectionById[newCollectionId] &&
        !isDestinationAllDocuments &&
        !newLookupCollectionById[newCollectionId].documents.includes(documentId)
      ) {
        newLookupCollectionById[newCollectionId].documents.push(documentId);
      }
    });

    return newLookupCollectionById;
  });

  // Set request info
  const addRequestInfo = isDestinationAllDocuments
    ? null
    : {
        collectionId: newCollectionId,
        documentIds: documentSelection,
      };
  setSelectedDocuments([]);
  selectCollectionId(null);
  setTimeout(() => {
    setRequestInfo({
      add: addRequestInfo,
      remove: Object.values(removeRequestInfo),
    });
  }, 500);
}

export const sendAiConfigChangePostMessage = (copilotAiConfig: any) => {
  const copilotIframe = document.getElementById("copilot_chat_iframe");

  if (copilotIframe) {
    const postMessageConfigObject = {
      smartdoc_temperature: copilotAiConfig.smartdoc_temperature,
      smartdoc_use_sources: copilotAiConfig.smartdoc_use_sources,
      smartdoc_is_multidoc: copilotAiConfig.smartdoc_is_multidoc,
      smartdoc_llm_model: copilotAiConfig.smartdoc_llm_model,
      smartdoc_user_selected_documents:
        copilotAiConfig.smartdoc_user_selected_documents || false,
    };

    // @ts-ignore
    debouncedPostMessage(copilotIframe.contentWindow, {
      type: "changeCopilotAiConfig",
      config: postMessageConfigObject,
    });
  }
};

export function prepareCollectionsPayload(
  documentSelection: any[], // Array of selected document IDs
  lookupCollectionById: { [key: string]: Collection } | null, // Lookup map for collections by ID
  newCollectionId: string, // ID of the collection documents are moved to, including "virtual_root", "_favorites", "_read_later", or "_trash"
  setRequestInfo: {
    (value: any): void;
    (arg0: {
      add: { id: string; documents: { document_id: string[] }[] }[];
      remove: { id: string; documents: { document_id: string[] }[] }[];
    }): void;
  }
) {
  if (!lookupCollectionById) return;
  const isTrashCollection = newCollectionId === TRASH_COLLECTION_ID;
  // Initialize the payload structure
  const payload: {
    add: { id: string; documents: { document_id: string }[] }[];
    remove: { id: string; documents: { document_id: string }[] }[];
  } = {
    add: [],
    remove: [],
  };

  const isSpecialDestination = [
    FAVORITES_COLLECTION_ID,
    READ_LATER_COLLECTION_ID,
  ].includes(newCollectionId);

  if (newCollectionId !== "virtual-root") {
    const newDocuments = [
      ...new Set([
        ...lookupCollectionById[newCollectionId].documents,
        ...documentSelection,
      ]),
    ];

    payload.add.push({
      id: newCollectionId,
      documents: newDocuments.map((documentId: any) => {
        return { document_id: documentId };
      }),
    });
  }
  let sourceCollectionIds: any[] = [];
  documentSelection.forEach((documentId) => {
    const matchedCollectionIds = Object.keys(lookupCollectionById).filter(
      (collectionId) =>
        lookupCollectionById[collectionId].documents.includes(documentId)
    );

    sourceCollectionIds = [...sourceCollectionIds, ...matchedCollectionIds];
  });

  sourceCollectionIds.forEach((sourceCollectionId) => {
    if (
      isTrashCollection ||
      (!isSpecialDestination && sourceCollectionId !== newCollectionId)
    ) {
      let collectionRemovePayload: {
        id: string;
        documents: { document_id: string }[];
      } = {
        id: sourceCollectionId,
        documents: [],
      };

      let collectionRemovePayloadDocuments = [
        ...new Set(lookupCollectionById[sourceCollectionId].documents),
      ];
      if (collectionRemovePayloadDocuments.length > 0) {
        documentSelection.forEach((documentId: any) => {
          collectionRemovePayloadDocuments =
            collectionRemovePayloadDocuments.filter(
              (prevDocId: any) => prevDocId !== documentId
            );
        });

        collectionRemovePayload.documents =
          collectionRemovePayloadDocuments.map((prevDocId: any) => {
            return { document_id: prevDocId };
          });
      }

      payload.remove.push(collectionRemovePayload);
    }
  });
  setRequestInfo(payload);
}

const getReactElementText = (parent: any) => {
  if (typeof parent === "string") {
    return parent;
  }

  if (
    parent === undefined ||
    typeof parent !== "object" ||
    !parent.props ||
    !parent.props.children ||
    (typeof parent.props.children !== "string" &&
      typeof parent.props.children !== "object")
  ) {
    return "";
  }

  if (typeof parent.props.children === "string") {
    return parent.props.children;
  }

  return parent.props.children
    .map((child: any) => getReactElementText(child))
    .join("");
};
export const stringSearch = (
  key: string,
  searchQuery: string,
  node: any,
  path: number[],
  treeIndex: number
) => {
  if (
    searchQuery &&
    key === "authors" &&
    node.authors &&
    node.authors.length > 0
  ) {
    return node.authors.some((author: string) =>
      author.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  if (typeof node[key] === "function") {
    // Search within text after calling its function to generate the text
    return String(node[key]({ node, path, treeIndex })).includes(searchQuery);
  }
  if (typeof node[key] === "object") {
    // Search within text inside react elements
    return getReactElementText(node[key]).includes(searchQuery);
  }

  // Search within string
  return node[key] && String(node[key]).includes(searchQuery);
};

export const getDocumentAuthors = (document: Document) => {
  let metaData = null;
  if (document.meta != null) {
    metaData = JSON.parse(document.meta);
  }
  const authors = new Set<string>();
  if (
    metaData &&
    Array.isArray(metaData.authors) &&
    metaData.authors.length > 0
  ) {
    metaData.authors.forEach((author: Author) => authors.add(author.full_name));
  }

  return authors;
};
export const rgbOrHexToRgba = (colorString: string, alpha: number) => {
  // Function to convert hex to RGB
  const hexToRgb = (hex: string) => {
    let r = 0,
      g = 0,
      b = 0;

    // Remove the hash at the beginning if it's there
    hex = hex.replace(/^#/, "");

    // If it's a 3-character hex code, expand it to 6 characters
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Convert to RGB values
    if (hex.length === 6) {
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
    }

    return `rgb(${r}, ${g}, ${b})`;
  };

  // Check if the input is a hex color
  if (/^#([0-9A-F]{3}){1,2}$/i.test(colorString)) {
    colorString = hexToRgb(colorString);
  }

  // Remove extra spaces and normalize RGB syntax
  const cleanColorString = colorString.replace(/\s+/g, " ").trim();
  const correctedColor = cleanColorString.replace(
    /rgb\(\s*(\d+)\s+(\d+)\s+(\d+)\s*[\/,]\s*([\d.]+)\s*\)/,
    "rgba($1, $2, $3, $4)"
  );

  // If the corrected color is already in the correct RGBA format, return it
  if (
    /rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*\d*(?:\.\d+)?\s*\)/i.test(
      correctedColor
    )
  ) {
    return correctedColor;
  }

  // Normalize RGB if it's not already RGBA and add the alpha value
  const standardizedRgb = correctedColor.replace(
    /rgb\(\s*(\d+)\s*[\s,]\s*(\d+)\s*[\s,]\s*(\d+)\s*\)/,
    `rgb($1, $2, $3)`
  );

  return standardizedRgb.replace("rgb(", "rgba(").replace(")", `, ${alpha})`);
};

export function rgbOrRgbaToHct(color: any) {
  const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d\.]+)?\)/i);
  if (!rgba) {
    return null;
  }

  const r = parseInt(rgba[1], 10);
  const g = parseInt(rgba[2], 10);
  const b = parseInt(rgba[3], 10);
  const argb = argbFromRgb(r, g, b);

  // Convert ARGB to HCT
  const hct = Hct.fromInt(argb);
  return hct;
}

export function rgbOrRgbaToHex(color: any) {
  const standardizedRgb = color.replace(
    /rgb\(\s*(\d+)\s*[\s,]\s*(\d+)\s*[\s,]\s*(\d+)\s*\)/,
    "rgb($1, $2, $3)"
  );
  // This regex matches the numbers in the RGB/RGBA string including decimals for alpha
  const result =
    /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*(0|1|0?\.\d+))?\)$/.exec(
      standardizedRgb
    );

  if (!result) {
    return null; // Returns null if the input is not a valid RGB or RGBA color
  }

  const r = parseInt(result[1], 10);
  const g = parseInt(result[2], 10);
  const b = parseInt(result[3], 10);
  const a = result[4] === undefined ? 1 : parseFloat(result[4]);

  // Convert the alpha (if present) from a range of 0-1 to 0-255
  const alpha = Math.round(a * 255);

  // Convert each component to a hexadecimal string and pad with zeroes if necessary
  const toHex = (x: any) => x.toString(16).padStart(2, "0");

  const hexColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

  // Append the alpha hexadecimal representation if the original was RGBA
  return result[4] === undefined ? hexColor : `${hexColor}`;
}

export const applyOpacity = (rgba: string, opacity: number) => {
  // Extract the RGBA components from the input string

  const matches = rgba.match(
    /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d*(?:\.\d+)?))?\s*\)$/i
  );

  if (!matches) {
    throw new Error("Invalid RGBA format");
  }

  // Adjust the alpha value
  return `rgba(${matches[1]}, ${matches[2]}, ${matches[3]}, ${opacity})`;
};

// const theme = themeFromSourceColor(argbFromHex(primaryColorHex), [
//   {
//     name: "custom-1",
//     value: argbFromHex("#ff0000"),
//     blend: true,
//   },
// ]);

// const updatedDarkScheme = convertArgbToRgbaInObject(
//   theme.schemes[copilotColorMode].props
// );

export function convertArgbToRgbaInObject(themeObject: any) {
  const newThemeObject = {}; // This will store the new key-value pairs with RGBA values

  for (const key in themeObject) {
    if (themeObject.hasOwnProperty(key)) {
      const argb = themeObject[key];
      // @ts-ignore
      newThemeObject[key] = argbToRgba(argb);
    }
  }

  return newThemeObject;
}

export function convertHexToRgbaInObject(themeObject: any) {
  const newThemeObject = {}; // This will store the new key-value pairs with RGBA values
  for (const key in themeObject) {
    if (themeObject.hasOwnProperty(key)) {
      const hex = themeObject[key];
      // @ts-ignore
      newThemeObject[key] = hexToRgba(hex);
    }
  }

  return newThemeObject;
}

export function updateFormFieldsWithColors(
  updatedColors: any,
  setValue: any,
  colorMode: any
) {
  Object.keys(updatedColors).forEach((field) => {
    const cssValue = updatedColors[field];
    const cssVariable = getCorrespondingCssVariableFromFieldName(
      colorMode,
      `copilotColor${capitalizeFirstLetter(field)}`
    );
    setValue(`copilotColor${capitalizeFirstLetter(field)}`, cssValue, {
      shouldValidate: true,
    });
    updateCssVariable(cssVariable, cssValue);
  });
}

export function capitalizeFirstLetter(string: string | undefined) {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Example usage

export const updateCssVariable = (
  variableName: string,
  value: string | null
) => {
  document.documentElement.style.setProperty(variableName, value);
};
export const sendUpdatedCssVariablesToChatPlugin = (copilotColorMode: any) => {
  const copilotIframe = document.getElementById("copilot_chat_iframe");
  const element = document.querySelector(
    `.copilot[data-theme=${copilotColorMode}]`
  );
  let copilotThemeConfig;
  if (element) {
    copilotThemeConfig = getChatPluginPostMessageThemeConfig(copilotColorMode);
  } else {
    console.log("Element with the specific selector not found");
  }
  if (copilotIframe) {
    // @ts-ignore
    copilotIframe.contentWindow.postMessage(
      {
        type: "setCopilotChatTheme",
        config: copilotThemeConfig,
      },
      "*"
    );
  }
};
type ThemeMode = keyof CopilotThemes;
export const getCorrespondingCssVariableFromFieldName = (
  colorMode: ThemeMode,
  fieldName: keyof ThemeColors
) => {
  return copilot_fields_variables_mapping[colorMode][fieldName];
};

export const resetToDefaultTheme = (colorMode: ThemeMode) => {
  const themeSettings = copilot_themes.copilot[colorMode];

  for (const key in themeSettings) {
    if (themeSettings.hasOwnProperty(key)) {
      // @ts-ignore
      const cssValue = themeSettings[key];
      const cssVariable = getCorrespondingCssVariableFromFieldName(
        colorMode,
        key
      );

      if (cssVariable) {
        updateCssVariable(cssVariable, cssValue);
      }
    }
  }
};
export const updateToVariablesToNewTheme = (
  themeConfig: any,
  colorMode: ThemeMode
) => {
  const themeSettings = themeConfig[colorMode];

  // Loop through each property in the theme settings for the selected mode
  for (const key in themeSettings) {
    if (themeSettings.hasOwnProperty(key)) {
      // @ts-ignore
      const cssValue = hexToRgba(themeSettings[key]);
      const cssVariable = getCorrespondingCssVariableFromFieldName(
        colorMode,
        key
      );

      if (cssVariable) {
        updateCssVariable(cssVariable, cssValue);
      }
    }
  }
};
export const resetToThemeConfigTheme = (
  colorMode: ThemeMode,
  themeConfig: any
) => {
  const themeSettings = themeConfig.copilot[colorMode];

  // Loop through each property in the theme settings for the selected mode
  for (const key in themeSettings) {
    if (themeSettings.hasOwnProperty(key)) {
      // @ts-ignore
      const cssValue = themeSettings[key];
      const cssVariable = getCorrespondingCssVariableFromFieldName(
        colorMode,
        key
      );

      if (cssVariable) {
        updateCssVariable(cssVariable, cssValue);
      }
    }
  }
};

const delay = (ms: number | undefined) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const ONLY_GPT4_MODEL_EXCEPTIONS = [
  "josephstiftung27347",
  "lidlimmobilien92689",
  "ixenso83746",
  "badenova59764",
  "wgshrostock52594",
  "wowiport4739",
  "aareondemo94695",
];
export const ONLY_CUSTOM_MODEL_EXCEPTIONS: string[] = [""];

export const SELECT_ALL_DOCUMENTS_BY_DEFAULT: string[] = [
  "abcfinance",
  "abcfinance63384",
];
