import Actions from "../constants";

export const setDrawerState = (data) => ({
  type: Actions.SET_DRAWER_STATE,
  payload: data,
});
export const setCopilotDrawerState = (data) => ({
  type: Actions.SET_COPILOT_DRAWER_STATE,
  payload: data,
});

export const setLanguage = (data) => ({
  type: Actions.SET_LANGUAGE,
  payload: data,
});

export const setTenant = (data) => ({
  type: Actions.SET_TENANT,
  payload: data,
});

export const setConversationSidebarState = (data) => ({
  type: Actions.SET_CONVERSATION_SIDEBAR_STATE,
  payload: data,
});
