import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchTickets = () => ({
  type: Actions.FETCH_TICKETS,
  token: getKeycloakToken(),
});

export const setTickets = (data) => ({
  type: Actions.SET_TICKETS,
  payload: data,
});

export const patchTicket = (ticketId, data) => ({
  type: Actions.PATCH_TICKET,
  ticketId: ticketId,
  payload: data,
  token: getKeycloakToken(),
});

export const patchTicketFulfilled = () => ({
  type: Actions.PATCH_TICKET_FULFILLED,
});
