import Actions from "../constants";

export const setContacts = (currentState, action) => {
  if (action.type === Actions.SET_CONTACTS) {
    return action.payload;
  } else if (action.type === Actions.DELETE_CONTACT_FULFILLED) {
    return currentState.filter((e) => e.id !== action.payload);
  } else if (action.type === Actions.MERGE_CONTACT_FULFILLED) {
    // pseudo merge of contacts so that state is updated
    let reference =
      currentState.find((e) => e.id === action.payload.contactId) || {};
    Object.keys(reference).forEach(
      (k) => !reference[k] && reference[k] !== undefined && delete reference[k]
    );
    const updatedContact = {
      ...(currentState.find((e) => e.id === action.payload.otherContactId) ||
        {}),
      ...reference,
    };
    return currentState
      .filter((e) => e.id !== action.payload.otherContactId)
      .map((e) => (e.id === action.payload.contactId ? updatedContact : e));
  }
  return currentState;
};

export const setContact = (currentState, action) => {
  if (action.type === Actions.SET_CONTACT) {
    return action.payload;
  } else if (action.type === Actions.CREATE_CONTACT) {
    if (currentState?.id === action.payload?.id) {
      return { ...currentState, ...action.payload };
    }
  }

  return currentState;
};
