import { of } from "rxjs";
import { catchError, concatMap, debounceTime, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import { updateEventFulfilled } from "../actions/events";
import dayjs from "dayjs";
import getConfig from "lib/config";
import { setConversation } from "store/actions/conversations";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const updateEventEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.UPDATE_EVENT),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/events/${action.eventId}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          return of(
            updateEventFulfilled(),
            setConversation({
              ...state$.value.conversation,
              events: [
                ...state$.value.conversation.events,
                {
                  data: action.payload.event.data,
                  event: action.payload.event.name,
                  timestamp: dayjs().unix(),
                },
              ],
            })
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
