export const isEdge = function isEdge(element) {
  return "id" in element && "source" in element && "target" in element;
};
export const isNode = function isNode(element) {
  return "id" in element && !("source" in element) && !("target" in element);
};

// export const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
//   const token =
//     JSON.parse(localStorage.getItem("keycloakToken"))
//
//   if (jwtDecode(token).exp < Date.now() / 1000) {
//     next(action);
//     localStorage.clear();
//   }
//   next(action);
// };
