import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/ssr";

const useUserGroups = () => {
  const { keycloak } = useKeycloak();
  const [groups, setGroups] = useState<string[]>();

  useEffect(() => {
    // @ts-ignore
    const parsedToken = keycloak?.idTokenParsed;
    if (parsedToken) {
      setGroups(parsedToken.groups);
    }
  }, [keycloak]);

  return groups;
};

export default useUserGroups;
