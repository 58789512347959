import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import getConfig from "lib/config";
import {
  changeConversationCaseStatusFulfilled,
  setCaseStatusValues,
} from "store/actions/cases";
import { setConversation, setConversations } from "store/actions/conversations";
import dayjs from "dayjs";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchCaseStatusValuesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CASE_STATUS_VALUES),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/case_status`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setCaseStatusValues(response.response.result);
        }),
        catchError((error) => {
          return of(setCaseStatusValues(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const changeConversationCaseStatusEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CHANGE_CONVERSATION_CASE_STATUS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.conversationId}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload.request },
      }).pipe(
        concatMap((response) => {
          if (state$.value.selectedCaseStatus !== "all") {
            return of(
              changeConversationCaseStatusFulfilled(),
              // addEvent(action.conversationId, action.payload.event.name, {
              //   data: action.payload.event.data,
              // }),
              setConversation({
                ...state$.value.conversation,
                ...action.payload.request,
                events: [
                  ...state$.value.conversation.events,
                  {
                    data: action.payload.event.data,
                    event: action.payload.event.name,
                    timestamp: dayjs().unix(),
                  },
                ],
              }),
              setConversations(
                state$.value.conversations.filter(
                  (e) => e.sender_id !== action.conversationId
                )
              )
            );
          }
          return of(
            changeConversationCaseStatusFulfilled(),
            // addEvent(action.conversationId, action.payload.event.name, {
            //   data: action.payload.event.data,
            // }),
            setConversation({
              ...state$.value.conversation,
              ...action.payload.request,
              events: [
                ...state$.value.conversation.events,
                {
                  data: action.payload.event.data,
                  event: action.payload.event.name,
                  timestamp: dayjs().unix(),
                },
              ],
            }),
            setConversations(
              state$.value.conversations.map((e) => {
                if (e.sender_id === action.conversationId)
                  return { ...e, ...action.payload.request };
                return e;
              })
            )
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
