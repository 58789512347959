import { Observable, of } from "rxjs";
import { switchMap, catchError, debounceTime, concatMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import getConfig from "lib/config";
const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;
import { setJumpGraphs } from "../actions/jumpGraphs";

export const fetchJumpGraphsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_JUMP_GRAPHS),
    debounceTime(500),
    switchMap((action) => {
      let channels = state$.value.dialogueGraphChannel;
      if (channels === "emails") {
        channels = "emails,web";
      }

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/graphs/info?languages=${state$.value.language}&channels=${channels}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      });
    }),
    concatMap((response) => {
      const result = response.response["result"];
      return of(setJumpGraphs(result));
    }),
    catchError((error) => of(errorMessage(error.message)))
  );
