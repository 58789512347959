import React, { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import Box from "@mui/material/Box";
import { EditorState, Modifier } from "draft-js";
import Button from "@mui/material/Button";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import dynamic from "next/dynamic";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Typography from "@mui/material/Typography";
import getConfig from "lib/config";
import { textInputStyles } from "features/tags/components/TagsTreeView";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_TENANT } = publicRuntimeConfig;

const Editor = dynamic(
  // @ts-ignore
  () => import("react-draft-wysiwyg").then((module) => module.Editor),
  {
    ssr: false,
  }
);

type AddNewSentencesProps = {
  // onChange: (data: any) => void;
  values: any[];
  setValues: any;
};
export default function AddNewSentences({
  // onChange,
  values,
  setValues,
}: AddNewSentencesProps): JSX.Element {
  const t = useTranslations();

  // const [values, setValues] = useState<any[]>([]);
  const [newValue, setNewValue] = useState<string>("");
  const [addNewValue, setAddNewValue] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<{
    edit: boolean;
    value: string;
    index: string | null | number;
  }>({
    edit: false,
    value: "",
    index: null,
  });

  const [editorState, setEditorState] = useState<any>(null);

  const [pasteList, setPasteList] = useState<boolean>(false);

  const handlePastedText = (
    text: string,
    html: any,
    editorState: EditorState
  ) => {
    const lines = text
      .split(/<br>|<\/p><p>|<\/li><li>|\r\n|\r|\n/g)
      .map((item) => item.replace(/<[^>]*>/g, ""));
    setPasteList(false);
    setValues((prevValues: any) => [...prevValues, ...lines]);
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ""
    );
    setEditorState(
      EditorState.push(editorState, contentState, "insert-characters")
    );
    return true;
  };

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, []);

  // const submitAddedSentences = () => {
  //   onChange(values);
  //   setValues([]);
  // };

  const onAdd = () => {
    setAddNewValue(true);
  };

  const onRemove = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  const onBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    edit = false,
    index = null
  ) => {
    if (addNewValue) {
      setAddNewValue(false);
    }
    if (edit && (index || index === 0)) {
      const newValues = [...values];
      setEditValue({ edit: false, value: "", index: null });
      if (newValues[index] === newValue) return;
      newValues[index] = newValue;
      setValues(newValues);
    } else {
      setValues((prevValues: any) => [...prevValues, newValue]);
    }
    setNewValue("");
  };
  const onEditValue = (value: React.SetStateAction<string>, index: number) => {
    if (addNewValue) {
      setAddNewValue(false);
    }
    // @ts-ignore
    setEditValue({ edit: true, value, index });
    setNewValue(value);
  };

  if (!editorState) {
    return <div>Loading editor...</div>;
  }

  return (
    <Stack
      sx={{
        padding: "0px 14px 16px 14px",
      }}
    >
      <Stack
        sx={{
          border: "2px solid rgba(0,0,0,0.23)",
          borderRadius: "9px",
          paddingBottom: "16px",
          marginTop: "12px",
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            padding: "16px 16px 8px 16px",
          }}
        >
          <Typography
            variant={"h5"}
            sx={{
              fontSize: "1.25rem",
              "& >span": { color: "rgba(0,0,0,0.46)" },
            }}
          >
            {/*{t("new_user_inputs")}*/}
            Neue Nutzereingaben formulieren
            <span>&nbsp;({values.length})</span>
          </Typography>
          {values.map((element, index) => {
            return (
              <Stack
                direction={"row"}
                key={element + index}
                sx={{
                  width: "500px",
                  justifyContent: "space-between",
                  gap: "24px",
                  padding: "6px 0",
                  borderTop: index > 0 ? "1px solid rgb(0 0 0 / 8%)" : "none",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    flex: "1 1 60%",
                  }}
                >
                  {editValue.edit && editValue.index === index ? (
                    <TextField
                      autoFocus
                      placeholder={t("Write a text input")}
                      value={newValue}
                      onChange={(e) => setNewValue(e.currentTarget.value)}
                      // @ts-ignore
                      onBlur={(e) => onBlur(e, true, index)}
                      variant="outlined"
                      size={"small"}
                      sx={{ ...textInputStyles, flex: "1 1 60%" }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        padding: "8.5px 0px",
                        cursor: "pointer",
                        width: "100%",
                        border: "1px solid transparent",
                        borderRadius: "5px",
                        transition:
                          "border 150ms ease-out, padding 150ms ease-out",
                        ":hover": {
                          paddingInline: "14px",
                          borderColor: "#8b61a5",
                          ...(REACT_APP_TENANT === "aar" && {
                            borderColor: "#3498ff",
                          }),
                        },
                      }}
                      onClick={() => onEditValue(element, index)}
                    >
                      {element}
                    </Typography>
                  )}
                </Box>

                <IconButton onClick={() => onRemove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          })}
          {addNewValue && (
            <TextField
              autoFocus
              placeholder={t("Write a text input")}
              value={newValue}
              onChange={(e) => setNewValue(e.currentTarget.value)}
              onBlur={onBlur}
              variant="outlined"
              size={"small"}
              sx={{
                ...textInputStyles,
                width: "100%",
                padding: "6px 0px",
                borderTop: "1px solid rgb(0 0 0 / 8%)",
              }}
            />
          )}
        </Box>
        <Stack
          direction={"row"}
          sx={{
            flexGrow: "1",
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            // mt: "24px",
            padding: "0 24px",
            gap: "16px",
          }}
        >
          {" "}
          <Button
            disabled={pasteList}
            onClick={onAdd}
            variant={"contained"}
            startIcon={<AddIcon />}
            sx={{ flex: "0 1 auto" }}
          >
            {/*{t("add_rephrasing")}*/}
            Neue Umformulierung
          </Button>
          {!pasteList ? (
            <Button
              disabled={addNewValue}
              variant={"contained"}
              sx={{
                backgroundColor: "var(--green)",
                "&:hover": { backgroundColor: "var(--green-hover)" },
              }}
              onClick={() => setPasteList(true)}
              startIcon={<FormatListBulletedIcon />}
            >
              {t("add_list")}
            </Button>
          ) : (
            <Box
              sx={{
                flex: "1 1 auto",
                maxWidth: "500px",
                "&.MuiBox-root .rdw-editor-wrapper": {
                  border: "1px solid var(--border-color-base)",
                  borderRadius: "5px",
                  padding: "2px 16px",
                  transition: "border-color ease-in-out 0.3s",

                  "&:hover": {
                    borderColor: "#8b61a5",
                    ...(REACT_APP_TENANT === "aar" && {
                      borderColor: "#3498ff",
                    }),
                  },
                },
              }}
            >
              {/*@ts-ignore*/}
              <Editor
                //@ts-ignore
                editorState={editorState}
                onEditorStateChange={setEditorState}
                handlePastedText={handlePastedText}
                toolbarHidden
                placeholder={"Füge eine Liste von Umformulierungen hier ein"}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      {/*<Stack sx={{ width: "fit-content", padding: "16px 16px 8px 16px" }}>*/}
      {/*  <Button*/}
      {/*    disabled={values.length === 0}*/}
      {/*    variant={"contained"}*/}
      {/*    onClick={() => submitAddedSentences()}*/}
      {/*  >*/}
      {/*    Nutzereingaben zur Auswahl hinzufügen*/}
      {/*  </Button>*/}
      {/*</Stack>*/}
    </Stack>
  );
}
