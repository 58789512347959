import * as React from "react";
import { alpha, styled, SxProps } from "@mui/material/styles";
import TreeView, { treeViewClasses, TreeViewProps } from "@mui/lab/TreeView";
import TreeItem, {
  TreeItemProps as MuiTreeItemProps,
  treeItemClasses,
} from "@mui/lab/TreeItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import Box from "@mui/material/Box";
import AddNewElement from "features/tags/components/AddNewElement";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import styles from "./tag.module.scss";
import classnames from "classnames";
import { useTheme } from "@mui/material/styles";
import getConfig from "lib/config";
import { editTagElement } from "store/actions/tags";
import { useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_TENANT } = publicRuntimeConfig;

interface StyledTreeItemProps extends Omit<MuiTreeItemProps, "classes"> {
  onNodeToggle?: (
    event: React.MouseEvent<HTMLDivElement>,
    itemIds: Array<string>
  ) => void;
}

const inputStyles: SxProps = {
  "& .MuiOutlinedInput-root": {
    border: "none",

    "& fieldset.MuiOutlinedInput-notchedOutline": { border: "none" },
    "&.Mui-focused": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: "500",
      padding: "0px",
    },
  },
};
export const textInputStyles: SxProps = {
  "& .MuiOutlinedInput-root": {
    border: "1px solid var(--border-color-base)",
    borderRadius: "5px",
    transition: "border-color ease-in-out 0.3s",

    "& fieldset.MuiOutlinedInput-notchedOutline": {
      border: "none",
      "& legend": {
        background: "#fff",
        visibility: "visible",
      },
    },
    "&.Mui-focused": {
      border: "1px solid #8b61a5",
      boxShadow: "0 0 0 3px rgb(230 214 241)",
      ...(REACT_APP_TENANT === "aar" && {
        border: "1px solid #3498ff",
        boxShadow: "0 0 0 3px rgba(194 ,224 ,254 ,0.6)",
      }),
    },
  },
  "&:hover .MuiOutlinedInput-root": {
    borderColor: "#8b61a5",
    ...(REACT_APP_TENANT === "aar" && {
      borderColor: "#3498ff",
    }),
  },
};
const StyledTreeView = styled((props: TreeViewProps) => (
  <TreeView {...props} />
))(({ theme }) => {
  return {
    [`&.${treeViewClasses.root} `]: {
      [`& >.${treeItemClasses.root}`]: {
        maxWidth: "90%",

        [`& >.${treeItemClasses.content}`]: {
          border: "1px solid rgba(0,0,0,0.2)",
          [`& .${treeItemClasses.label} .MuiTypography-root`]: {
            color: "rgba(0,0,0,0.87)",
          },
          "&::before": { borderBottom: `none`, borderLeft: `none` },
        },
        [`& + .${treeItemClasses.root}`]: {
          marginTop: "8px",
        },
        [`& > div.${treeItemClasses.selected} ,&  > div.${treeItemClasses.expanded}`]:
          {
            border: "1px solid transparent",
          },
      },
      [`& .${treeItemClasses.root} .${treeItemClasses.content} .${treeItemClasses.iconContainer}`]:
        {
          display: "none",
        },
    },
  };
});

const StyledTreeItem = styled((props: StyledTreeItemProps) => (
  <TreeItem {...props} />
))(({ theme }) => {
  return {
    [`&.${treeItemClasses.root} .MuiCollapse-root`]: {
      "& .MuiCollapse-wrapper": {
        "& .MuiCollapse-wrapperInner": {
          "& .MuiTreeItem-root": {
            marginTop: 8,
          },
        },
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 36,
      position: "relative",
    },

    [`& .${treeItemClasses.content}`]: {
      marginLeft: 12,
      padding: 0,
      position: "relative",
      borderRadius: "9px",
      width: "auto",
      "&::before": {
        content: "''",
        width: 12,
        height: 8,
        borderBottom: `1px solid #d2d2d3`,
        borderLeft: `1px solid #d2d2d3`,
        borderBottomLeftRadius: "12px",
        position: "absolute",
        right: "calc(100% + 1px)",
      },
      [`&.${treeItemClasses.selected},&.${treeItemClasses.expanded}`]: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        border: "none",
        [`& .${treeItemClasses.label} .MuiTypography-root`]: {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
      [`&.${treeItemClasses.selected}:hover,&.${treeItemClasses.selected}.${treeItemClasses.focused}`]:
        {
          backgroundColor: "#dddddd",
        },
      [`& .${treeItemClasses.label}`]: {
        "& .MuiTypography-root": {
          fontWeight: "500",
          color: "#979797",
        },
        [`& >.MuiBox-root`]: {
          padding: "8px 12px",
        },
        "& .MuiBox-root.icon_box >.MuiSvgIcon-root": {
          transition: "all 0.2s ease-in-out",
        },
      },
      [`&.${treeItemClasses.expanded}`]: {
        [`& .${treeItemClasses.label} .MuiBox-root.icon_box >.MuiSvgIcon-root`]:
          {
            transform: "rotate(90deg)",
          },
      },
    },
  };
});

interface RenderTree {
  parent_id: string;
  type: string;
  id: string;
  name: string;
  children?: readonly RenderTree[];

  [key: string]: any;
}

type TreeNode = {
  id: string;
  name?: string;
  children?: TreeNode[];
  type?: string;
};
export default function TagsTreeView({
  categories,
  topics,
  reasons,
  problems,
  fetchAll,
  tagging,
  tagMessage,
  tags,
  statusTags,
}: {
  categories: any;
  topics: any;
  reasons: any;
  problems: any;
  fetchAll: any;
  tagging?: any;
  tagMessage?: any;
  tags?: any;
  statusTags: any;
}) {
  const [edit, setEdit] = useState<string | null>(null);
  const treeData = generateTreeViewData(categories, topics, reasons, problems);
  const theme = useTheme();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [expandedItems, setExpandedItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [foundMatches, setFoundMatches] = useState<any>([]);

  const handleSearchInputChange = () => {
    const matchingNodes = [];

    const filterNode = (
      node: TreeNode,
      query: string,
      parents: Array<TreeNode> = []
    ): Array<{ node: TreeNode; parents: Array<TreeNode> }> => {
      const nodes: Array<{ node: TreeNode; parents: Array<TreeNode> }> = [];

      if (node?.name?.toLowerCase().includes(query.toLowerCase())) {
        nodes.push({
          node,
          parents,
        });
      }

      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          const childNodes = filterNode(
            node.children[i],
            query,
            parents.concat(node)
          );
          if (childNodes.length > 0) {
            nodes.push(...childNodes);
          }
        }
      }
      return nodes;
    };

    for (let i = 0; i < treeData.length; i++) {
      const nodeMatches = filterNode(treeData[i], searchQuery);
      if (nodeMatches.length > 0) {
        matchingNodes.push(...nodeMatches);
      }
    }

    const uniqueNodes = matchingNodes.filter(
      (node, index, self) =>
        index ===
        self.findIndex(
          (n) => n.node.id === node.node.id && n.node.name === node.node.name
        )
    );
    const parentNodes = uniqueNodes.map((node) => {
      if (node.parents.length > 0) {
        return node.parents.filter((parent) => parent?.type === "category");
      } else {
        return node.node;
      }
    });

    setFilteredItems(
      parentNodes.flat().filter((node, index, self) => {
        return (
          node &&
          node.id &&
          index ===
            self.findIndex((n) => n && n.id === node.id && n.name === node.name)
        );
      })
    );
    setFoundMatches(matchingNodes.map((node) => node.node.id));

    const expandedNodes = parentNodes.concat(
      uniqueNodes.map((node) => node.parents).flat()
    );

    // @ts-ignore
    const expandedIds = expandedNodes.map((node) => node.id);

    setExpandedItems(Array.from(new Set(expandedIds)));
  };

  useEffect(() => {
    if (searchQuery.length === 0) {
      setFilteredItems([]);
      setExpandedItems([]);
      setFoundMatches([]);
    }
  }, [searchQuery]);

  function addEventListeners() {
    const treeItems = document.querySelectorAll(".tree_item");

    treeItems.forEach((treeItem) => {
      const icon = treeItem.querySelector(".edit_icon");
      let timer: NodeJS.Timeout;

      const alreadyHasEventListener = treeItem.getAttribute(
        "data-has-event-listener"
      );
      if (!alreadyHasEventListener) {
        treeItem.addEventListener("mouseenter", () => {
          timer = setTimeout(() => {
            icon?.classList.add(styles["bounce_animation"]);
          }, 500);
        });

        treeItem.addEventListener("mouseleave", () => {
          clearTimeout(timer);
          icon?.classList.remove(styles["bounce_animation"]);
        });

        treeItem.setAttribute("data-has-event-listener", "true");
      }
    });
  }

  const handleNodeToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedItems(nodeIds);
    setTimeout(() => {
      addEventListeners();
    }, 500);
  };

  const handleEdit = (nodes: any, value: string) => {
    const payload = {
      type: nodes.type,
      [`${nodes.type}_id`]: nodes[`${nodes.type}_id`],
      [`${nodes.type}_value`]: value,
    };
    dispatch(editTagElement({ ...payload }));

    setEdit(null);
  };

  useEffect(() => {
    addEventListeners();
  }, []);

  useEffect(() => {
    addEventListeners();
  }, [edit, categories, topics, reasons, problems, expandedItems]);

  function generateTreeViewData(
    categories: any,
    topics: any,
    reasons: any,
    problems: any
  ) {
    const treeViewData: { id: string; name: any; children: any }[] = [];

    categories.forEach((category: { id: any; value: any }) => {
      const categoryTreeViewData: any = {
        type: "category",
        category_id: category.id,
        id: `category-${category.id}`,
        name: category.value,
        children: [],
      };

      if (topics && topics.length > 0) {
        const topicsInCategory = topics.filter(
          (topic: { category_id: any }) => topic.category_id === category.id
        );
        topicsInCategory.forEach((topic: { id: any; value: any }) => {
          const topicTreeViewData: any = {
            parent_id: category.id,
            type: "topic",
            topic_id: topic.id,
            id: `topic-${topic.id}`,
            name: topic.value,
            children: [],
          };
          if (reasons && reasons.length > 0) {
            const reasonsInTopic = reasons.filter(
              (reason: { topic_id: any }) => reason.topic_id === topic.id
            );
            reasonsInTopic.forEach((reason: { id: any; value: any }) => {
              const reasonTreeViewData: any = {
                parent_id: topic.id,
                type: "reason",
                reason_id: reason.id,
                id: `reason-${reason.id}`,
                name: reason.value,
                children: [],
              };
              if (problems && problems.length > 0) {
                const problemsInReason = problems.filter(
                  (problem: { reason_id: any }) =>
                    problem.reason_id === reason.id
                );
                problemsInReason.forEach((problem: { id: any; value: any }) => {
                  const problemTreeViewData: any = {
                    parent_id: reason.id,
                    type: "problem",
                    problem_id: problem.id,
                    id: `problem-${problem.id}`,
                    name: problem.value,
                  };

                  reasonTreeViewData.children.push(problemTreeViewData);
                });
              }
              let fixedArray: any = [];
              let arrayWithAppendedFixedProblems = [];

              const arrayWithoutFixedProblems =
                reasonTreeViewData.children.filter((problem: any) => {
                  if (statusTags && statusTags.length > 0) {
                    const fixedTag = statusTags.find(
                      (tag: any) =>
                        tag.problem_id === problem.problem_id &&
                        tag.review_status_id == 3
                    );

                    if (fixedTag) {
                      problem["fixed"] = true;
                      fixedArray.push(problem);
                      return;
                    }
                  }

                  return problem;
                });

              arrayWithoutFixedProblems.sort(function (a: any, b: any) {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();

                return a < b ? -1 : a > b ? 1 : 0;
              });
              fixedArray.sort(function (a: any, b: any) {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();

                return a < b ? -1 : a > b ? 1 : 0;
              });
              arrayWithAppendedFixedProblems.push(...arrayWithoutFixedProblems);
              arrayWithAppendedFixedProblems.push(...fixedArray);

              reasonTreeViewData.children =
                arrayWithAppendedFixedProblems.flat();

              topicTreeViewData.children.push(reasonTreeViewData);
            });
          }
          topicTreeViewData.children.sort(function (a: any, b: any) {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          });
          categoryTreeViewData.children.push(topicTreeViewData);
        });
      }
      categoryTreeViewData.children.sort(function (a: any, b: any) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        return a < b ? -1 : a > b ? 1 : 0;
      });
      treeViewData.push(categoryTreeViewData);
    });

    treeViewData.sort(function (a, b) {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();

      return a < b ? -1 : a > b ? 1 : 0;
    });

    return treeViewData;
  }

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderTree = (nodes: RenderTree, index: number) => {
    let tagAlreadyAdded = false;
    if (nodes) {
      if (nodes.type === "problem" && tags && tags.length > 0) {
        tagAlreadyAdded = tags.find(
          (tag: { id: any }) => tag.id === nodes.problem_id
        );
      }
      return (
        <StyledTreeItem
          key={nodes.id}
          nodeId={nodes.id}
          id={nodes.id}
          onClick={
            tagging && nodes.type === "problem" && !tagAlreadyAdded
              ? () => tagMessage(nodes.problem_id)
              : undefined
          }
          sx={{
            "& >div": {
              ...(foundMatches.includes(nodes.id) && {
                border: "none !important",
              }),
            },
            "& >.MuiTreeItem-content": {
              ...(foundMatches.includes(nodes.id) && {
                "& >.MuiTreeItem-label": {
                  borderRadius: "9px",
                  backgroundColor: "#fff4dd !important",
                  border: "none",
                  "& .tree_item .MuiTypography-root": {
                    color: "#c99750 !important",
                  },
                },
              }),
            },

            ...(nodes.type === "problem" && {
              "&:hover": {},
              pointerEvents: tagAlreadyAdded ? "none" : "all",
              "& .MuiTreeItem-label": {
                borderRadius: "9px",
                backgroundColor: tagAlreadyAdded
                  ? "#ecfff8a6"
                  : "rgba(0, 0, 0, 0.00)",

                // filter:
                //   tagAlreadyAdded && nodes.fixed
                //     ? "hue-rotate(45deg)"
                //     : nodes.fixed && tagging
                //     ? "opacity(0.4)"
                //     : "none",
                border: tagAlreadyAdded ? "2px solid #0099795e" : "none",
                "& .MuiTypography-root": {
                  color: "rgba(0,0,0,0.87)",
                },
              },
            }),
          }}
          label={
            edit && edit === nodes.id ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <EditOutlinedIcon sx={{ transform: "none !important" }} />
                <TextField
                  autoFocus
                  sx={{
                    width: "100%",
                    ...inputStyles,
                    "& >.MuiInput-root": {
                      marginTop: 0,
                    },
                  }}
                  placeholder={`${capitalizeFirstLetter(nodes.type)} editieren`}
                  variant="outlined"
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                  onBlur={(e) => {
                    const treeItem = document.getElementById(`Box_${nodes.id}`);
                    treeItem?.removeAttribute("data-has-event-listener");
                    setEdit(null);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter" && e.target.value.length > 0) {
                      handleEdit(nodes, e.target.value);
                    }
                  }}
                />
              </Box>
            ) : (
              <Box
                id={`Box_${nodes.id}`}
                className="tree_item"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {nodes.type === "category" && <LocalOfferOutlinedIcon />}
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "inherit",
                      flexGrow: 1,
                      fontSize: "16px",
                      "& >span": { color: "rgba(0,0,0,0.3)" },
                      "&.MuiTypography-root": {
                        color:
                          tagAlreadyAdded && tagging
                            ? "var(--green) !important"
                            : "inherit",
                      },
                    }}
                  >
                    {nodes.name}&nbsp;
                    {nodes.children && nodes.children.length > 0 && (
                      <span>({nodes.children?.length})</span>
                    )}
                  </Typography>{" "}
                  <EditRoundedIcon
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                        fill: "#360052ba",
                        ...(REACT_APP_TENANT === "aar" && {
                          fill: theme.palette.primary.main,
                        }),
                      },
                    }}
                    className={classnames("edit_icon", styles.edit_item)}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEdit(nodes.id);
                    }}
                  />
                </Box>
                <Box
                  className={"icon_box"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {nodes.type === "problem" && tagAlreadyAdded && tagging ? (
                    <Typography
                      id={`tagged_${nodes.id}`}
                      sx={{
                        [`&.MuiTypography-root#tagged_${nodes.id}`]: {
                          color: tagAlreadyAdded
                            ? "var(--green)"
                            : "rgba(0, 0, 0, 0.87)",
                        },
                      }}
                    >
                      added
                    </Typography>
                  ) : nodes.type === "problem" &&
                    tagging &&
                    !tagAlreadyAdded ? (
                    <Typography>+ add tag</Typography>
                  ) : nodes.type !== "problem" ? (
                    <KeyboardArrowRightIcon />
                  ) : undefined}{" "}
                </Box>
              </Box>
            )
          }
        >
          {nodes.children && nodes.children.length > 0 && (
            <Box
              sx={{
                paddingTop: "8px",
                borderLeft: "1px solid #d2d2d3",
              }}
            >
              {Array.isArray(nodes.children)
                ? nodes.children.map((node, index: number) =>
                    renderTree(node, index)
                  )
                : null}
            </Box>
          )}
          {nodes.type !== "problem" && (
            <Box
              sx={{
                borderLeft: "1px solid #d2d2d3",
                pt: "8px",
                "& >.add_new_element.MuiBox-root": {
                  position: "relative",
                  marginLeft: "12px",
                  mt: 0,
                  "&::before": {
                    content: "''",
                    width: 12,
                    height: 8,
                    borderBottom: `1px solid #d2d2d3`,
                    borderLeft: `1px solid #d2d2d3`,
                    borderBottomLeftRadius: "12px",
                    position: "absolute",
                    right: "calc(100% + 1px)",
                    boxShadow: "-7px 12px 1px 12px #fff",
                  },
                  "& .MuiFormControl-root": {
                    width: "auto",
                  },
                  "& >div": {
                    justifyContent: "unset",
                  },
                },
              }}
            >
              <AddNewElement
                element={
                  nodes.type === "category"
                    ? "topic"
                    : nodes.type === "topic"
                    ? "reason"
                    : "problem"
                }
                fetchAll={fetchAll}
                parentId={nodes[`${nodes.type}_id`]}
                id={index}
              />
            </Box>
          )}
        </StyledTreeItem>
      );
    }
  };
  const dataToRender = filteredItems.length > 0 ? filteredItems : treeData;

  return (
    <>
      <Stack
        className={"search_field"}
        direction={"row"}
        sx={{
          fontSize: "16px",
          position: "absolute",
          top: "8px",
          left: "8px",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <TextField
          label="Suche"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          size={"small"}
          sx={{ ...textInputStyles }}
        />
        <Button
          sx={{
            fontSize: "16px",
            "&:disabled": { backgroundColor: "#f0f3f9" },
          }}
          disabled={searchQuery.length === 0}
          onClick={() => handleSearchInputChange()}
          variant={"contained"}
          endIcon={<SearchIcon />}
        >
          Suchen
        </Button>

        <IconButton
          sx={{
            position: "absolute",
            left: "195px",
            width: "30px",
            height: "30px",
            display: "none",
            fontSize: "16px",
            ...(searchQuery.length > 0 && { display: "flex" }),
          }}
          disabled={searchQuery.length === 0}
          onClick={() => setSearchQuery("")}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
      {foundMatches.length > 0 && (
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          {" "}
          <Typography sx={{ m: "8px 12px" }}>
            {" "}
            Gefundene Treffer werden{" "}
            <Box
              sx={{
                padding: "4px 12px",
                display: "inline-flex",
                backgroundColor: "#fff4dd ",
                color: "#c99750",
                borderRadius: "9px",
                fontWeight: 500,
              }}
            >
              gelb
            </Box>{" "}
            markiert
          </Typography>{" "}
          {/*<AddNewElement fetchAll={fetchAll} element={"category"} />*/}
        </Stack>
      )}
      {dataToRender && dataToRender.length > 0 ? (
        <Paper elevation={1} sx={{ p: 2, overflowY: "auto", m: 1 }}>
          <StyledTreeView
            aria-label="customized"
            // defaultExpanded={["root"]}
            multiSelect
            expanded={expandedItems}
            onNodeToggle={handleNodeToggle}
            sx={{ flexGrow: 1, overflowY: "auto" }}
          >
            {dataToRender?.map((data: any, index: number) =>
              renderTree(data, index)
            )}
          </StyledTreeView>
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
