import Actions from "../constants";

export const setTagCategories = (currentState, action) => {
  if (action.type !== Actions.SET_TAG_CATEGORIES) {
    return currentState;
  }
  return action.payload;
};
export const setTagTopics = (currentState, action) => {
  if (action.type !== Actions.SET_TAG_TOPICS) {
    return currentState;
  }
  return action.payload;
};
export const setTagReasons = (currentState, action) => {
  if (action.type !== Actions.SET_TAG_REASONS) {
    return currentState;
  }
  return action.payload;
};
export const setTagProblems = (currentState, action) => {
  if (action.type !== Actions.SET_TAG_PROBLEMS) {
    return currentState;
  }
  return action.payload;
};
export const setTagsBySenderId = (currentState, action) => {
  if (action.type !== Actions.SET_TAGS_BY_SENDERID) {
    return currentState;
  }
  return action.payload;
};

export const setTags = (currentState, action) => {
  if (action.type !== Actions.SET_TAGS) {
    return currentState;
  }

  return action.payload;
};
