import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
} from "rxjs";
import {
  switchMap,
  map,
  catchError,
  debounceTime,
  concatMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  fetchRentalUnitTickets,
  patchRentalUnitTicketsFulfilled,
  setForwardedTickets,
  setClosedTickets,
  setInProgressTickets,
  setOpenTickets,
  setPendingTickets,
  setSolvedTickets,
  setRentalUnitTicketCountByStatus,
} from "../actions/rentalUnitTickets";
import getConfig from "../../lib/config";
import { showErrorSnackbar, showSuccessSnackbar } from "../actions/snackbar";
import { Action } from "redux";
import { useStoreState } from "store/hooks";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

const setStatusTickets = (status: any, tickets: any) => {
  switch (status) {
    case "open":
      return setOpenTickets(tickets);
    case "in_progress":
      return setInProgressTickets(tickets);
    case "pending":
      return setPendingTickets(tickets);
    case "forwarded":
      return setForwardedTickets(tickets);
    case "solved":
      return setSolvedTickets(tickets);
    case "closed":
      return setClosedTickets(tickets);
    default:
      return; // handle default case or throw an error
  }
};

const getTicketsStatusState = (status: any) => {
  switch (status) {
    case "open":
      return "openTickets";
    case "in_progress":
      return "inProgressTickets";
    case "pending":
      return "pendingTickets";
    case "forwarded":
      return "forwardedTickets";
    case "solved":
      return "solvedTickets";
    case "closed":
      return "closedTickets";
    default:
      return; // handle default case or throw an error
  }
};

export const fetchRentalUnitTicketsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<
        unknown,
        | {
            type: string;
            payload: any;
          }
        | { type: string; error: any }
        | undefined
      >
    ) => any;
  },
  state$: {
    value: { [x: string]: { concat: (arg0: any) => null }; tenant: any };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_RENTAL_UNIT_TICKETS),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/rental_unit/tickets?id=${action.payload.id}&offset=${action.payload.offset}&limit=${action.payload.limit}&sync_external=${action.payload.sync_external}&priority=${action.payload.priority}&status=${action.payload.status}&sender_id=${action.payload.sender_id}&contract_id=${action.payload.contract_id}&description=${action.payload.description}&title=${action.payload.title}&contact_phone=${action.payload.contact_phone}&contact_mail=${action.payload.contact_mail}&article_id=${action.payload.article_id}&room_id=${action.payload.room_id}&user_id=${action.payload.user_id}&client_id=${action.payload.client_id}&external_id=${action.payload.external_id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          if (!action.payload.status) {
            const setTicketsAction = setStatusTickets(
              response.response.tickets[0].status,
              response.response.tickets
            );
            return of(setTicketsAction);
          }

          if (action.payload.offset === 0) {
            const setTicketsAction = setStatusTickets(
              action.payload.status,
              response.response.tickets
            );
            return of(setTicketsAction);
          } else {
            const ticketStatusState = getTicketsStatusState(
              action.payload.status
            );

            const setTicketsAction = setStatusTickets(
              action.payload.status, // @ts-ignore
              state$.value[ticketStatusState].concat(response.response.result)
            );
            return of(setTicketsAction);
          }
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            setStatusTickets(action.payload.status, null)
          );
        })
      );
    })
  );
};

export const patchRentalUnitTicketsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<
        unknown,
        | {
            type: string;
            error: any;
          }
        | { type: string }
        | { type: string; payload: any; anchorOrigin: any }
      >
    ) => any;
  },
  state$: { value: { tenant: any } }
) => {
  return action$.pipe(
    ofType(ActionConstants.PATCH_RENTAL_UNIT_TICKETS),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/rental_unit/tickets`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: action.payload,
      }).pipe(
        concatMap((response) => {
          return of(
            fetchRentalUnitTickets({ id: response.response.ids[0] }),
            showSuccessSnackbar("Snackbars.patchedTicketSuccess", {
              vertical: "top",
              horizontal: "right",
            }),
            patchRentalUnitTicketsFulfilled()
          );
        }),
        catchError((error) => {
          return of(
            showErrorSnackbar("Snackbars.patchedTicketError"),
            errorMessage(error.message)
          );
        })
      );
    })
  );
};

export const fetchRentalUnitTicketCountByStatusEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<
        unknown,
        | {
            type: string;
            error: any;
          }
        | { type: string; payload: any }
      >
    ) => any;
  },
  state$: { value: { tenant: any } }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_RENTAL_UNIT_TICKET_COUNT_BY_STATUS),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/rental_unit/tickets/count_by_status`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setRentalUnitTicketCountByStatus(response.response));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
