import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  addLabelToConversationFulfilled,
  createLabelFulfilled,
  deleteLabelFromConversationFulfilled,
  deleteLabelFulfilled,
  setLabel,
  setLabels,
  setLabelsForConversation,
} from "../actions/labels";
import getConfig from "lib/config";
import { addEvent } from "store/actions/conversations";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchLabelsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_LABELS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/labels`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setLabels(response.response);
        }),
        catchError((error) => {
          return of(setLabels(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchLabelEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_LABEL),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/label/${action.payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setLabel(response.response.result);
        }),
        catchError((error) => {
          return of(setLabels(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const createLabelsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_LABEL),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/label`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload },
      }).pipe(
        concatMap((response) => {
          return of(createLabelFulfilled(), setLabels(null));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const deleteLabelEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_LABEL),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/label/${action.payload}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return deleteLabelFulfilled(action.payload);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchLabelsForConversationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_LABELS_FOR_CONVERSATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.payload}/labels`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setLabelsForConversation(response.response.result);
        }),
        catchError((error) => {
          return of(
            setLabelsForConversation(null),
            errorMessage(error.message)
          );
        })
      );
    })
  );
};

export const deleteLabelFromConversationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_LABEL_FROM_CONVERSATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.payload.senderId}/labels/${action.payload.labelId}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            deleteLabelFromConversationFulfilled(action.payload),
            addEvent(action.payload.senderId, "label_removed", {
              data: action.payload.event,
            })
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const addLabelToConversationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.ADD_LABEL_TO_CONVERSATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.payload.senderId}/labels/${action.payload.labelId}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            addLabelToConversationFulfilled(),
            addEvent(action.payload.senderId, "label_added", {
              data: action.payload.event,
            })
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
