import Actions from "../constants";

export const setCampaignTypes = (currentState, action) => {
  if (action.type !== Actions.SET_CAMPAIGN_TYPES) {
    return currentState;
  }
  return action.payload;
};

export const setOneOffCampaigns = (currentState, action) => {
  if (action.type === Actions.SET_ONEOFF_CAMPAIGNS) {
    return action.payload;
  }
  if (action.type === Actions.DELETE_ONEOFF_CAMPAIGN_FULFILLED) {
    return currentState.filter((e) => e.id !== action.payload);
  }
  return currentState;
};

export const setOneOffCampaign = (currentState, action) => {
  if (action.type !== Actions.SET_ONEOFF_CAMPAIGN) {
    return currentState;
  }
  return action.payload;
};

export const setOngoingCampaigns = (currentState, action) => {
  if (action.type === Actions.SET_ONGOING_CAMPAIGNS) {
    return action.payload;
  }
  if (action.type === Actions.DELETE_ONGOING_CAMPAIGN_FULFILLED) {
    return currentState.filter((e) => e.id !== action.payload);
  }
  return currentState;
};

export const setOngoingCampaign = (currentState, action) => {
  if (action.type !== Actions.SET_ONGOING_CAMPAIGN) {
    return currentState;
  }
  return action.payload;
};
