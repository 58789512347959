import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
} from "rxjs";
import {
  switchMap,
  map,
  catchError,
  debounceTime,
  concatMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";

import getConfig from "../../lib/config";
import { setRentalUnitTenants } from "store/actions/rentalUnitTenants";
import { Action } from "redux";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchRentalUnitTenantsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<
        unknown,
        | {
            type: string;
            payload: any;
          }
        | { type: string; error: any }
      >
    ) => any;
  },
  state$: { value: { tenant: any } }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_RENTAL_UNIT_TENANTS),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        // /rental_unit/tenants?id=${action.payload.id}&offset=${action.payload.offset}&limit=${action.payload.limit}&sync_external=${action.payload.sync_external}&active=${action.payload.active}&end_date=${action.payload.end_date}&start_date=${action.payload.start_date}&birth_date=${action.payload.birth_date}&phone=${action.payload.phone}&email=${action.payload.email}&street=${action.payload.street}&postcode=${action.payload.postcode}&city=${action.payload.city}&last_name=${action.payload.last_name}&first_name=${action.payload.first_name}&contract_type=${action.payload.contract_type}&contract_name=${action.payload.contract_name}&contract_id=${action.payload.contract_id}&user_id=${action.payload.user_id}&rental_unit_id=${action.payload.rental_unit_id}&client_id=${action.payload.client_id}
        url: `${REACT_APP_ACTION_HOST}/rental_unit/tenants`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setRentalUnitTenants(response.response.tenants));
        }),
        catchError((error) => {
          return of(setRentalUnitTenants(null), errorMessage(error.message));
        })
      );
    })
  );
};
