import Actions from "../../constants";
import { getKeycloakToken } from "lib/util";

export const fetchCollections = () => ({
  type: Actions.FETCH_COLLECTIONS,
  token: getKeycloakToken(),
});

export const setCollections = (data: any) => ({
  type: Actions.SET_COLLECTIONS,
  payload: data,
});
export const setCollectionsUpsertStatus = (data: any) => ({
  type: Actions.SET_COLLECTIONS_UPSERT_STATUS,
  payload: data,
});

export const fetchCollection = (collectionId: string) => ({
  type: Actions.FETCH_COLLECTION,
  collection_id: collectionId,
  token: getKeycloakToken(),
});

export const setCollection = (data: any) => ({
  type: Actions.SET_COLLECTION,
  payload: data,
});

export const upsertCollection = (
  data: any,
  editCollectionId?: string | null | undefined,
  copilot?: boolean | null | undefined,
  option?: string | null | undefined
) => ({
  type: Actions.UPSERT_COLLECTION,
  payload: data,
  editCollectionId: editCollectionId ? editCollectionId : null,
  copilot: copilot ? copilot : null,
  option: option ? option : null,
  token: getKeycloakToken(),
});
export const upsertCollectionFulfilled = (
  option: string,
  collectionId: string
) => ({
  type: Actions.UPSERT_COLLECTION_FULFILLED,
  option: option,
  collectionId: collectionId,
});

export const upsertCollectionFailed = (
  option: string,
  collectionId: string,
  error: any
) => ({
  type: Actions.UPSERT_COLLECTION_FAILED,
  option: option,
  collectionId: collectionId,
  error,
});

export const deleteCollection = (collectionId: string) => ({
  type: Actions.DELETE_COLLECTION,
  collection_id: collectionId,
  token: getKeycloakToken(),
});
export const deleteCollectionFulfilled = () => ({
  type: Actions.DELETE_COLLECTION_FULFILLED,
});

export const setIsTrashCollection = (collectionId: string) => {
  const isTrashCollection =
    collectionId === "00000000-0000-0000-0000-000000000002";

  return {
    type: Actions.SET_IS_TRASH_COLLECTION,
    payload: isTrashCollection,
  };
};
