import Actions from "../constants";

export const addUtterance = (data) => ({
  type: Actions.ADD_UTTERANCE,
  payload: data,
});

export const addUtteranceFullfilled = (data) => ({
  type: Actions.ADD_UTTERANCE_FULFILLED,
});
