import Actions from "../constants";
import { getKeycloakToken } from "../../lib/util";

export const fetchFaqs = () => ({
  type: Actions.FETCH_FAQS,
  token: getKeycloakToken(),
});

export const setFaqs = (data) => ({
  type: Actions.SET_FAQS,
  payload: data,
});

export const fetchTrainStatus = () => ({
  type: Actions.FETCH_TRAIN_STATUS,
  token: getKeycloakToken(),
});
export const setTrainStatus = (data) => ({
  type: Actions.SET_TRAIN_STATUS,
  payload: data,
});
