import Actions from "../../constants";

export const setCopilotDashboardConfig = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_COPILOT_DASHBOARD_CONFIG) {
    return currentState;
  }

  return action.payload;
};
export const setIsCopilotReady = (
  currentState: any,
  action: {
    type: any;
    payload: any;
    config: any;
    newCopilotConfig: boolean;
  }
) => {
  if (action.type === Actions.FETCH_THEME_CONFIG_FULFILLED) {
    if (action.config.copilot) {
      return true;
    } else {
      return false;
    }
  } else if (action.type !== Actions.SET_IS_COPILOT_READY) {
    return currentState;
  }

  return currentState;
};
