import parse from "html-react-parser";
import { useEffect, useRef } from "react";

export const highlightSearchMatch = (
  input: string | undefined,
  match: string
) => {
  if (!input) return <></>;
  return parse(
    input.replace(new RegExp(match, "gi"), (m: string) => `<mark>${m}</mark>`)
  );
};

export const logger = {
  log: (message: any) => {
    if (process.env.NODE_ENV === "development") {
      console.log(`%c ${message}`, "color: #0549AA; font-size: 12px;");
    }
  },
  error: (message: any, error: any) => {
    if (process.env.NODE_ENV == "development") {
      console.log(
        "%c%s%c%s",
        " font-size: 12px;",
        message,
        "color: var(--red); font-size: 12px;",
        error
      );
    }
  },
};

export const useTraceUpdate = (
  name: string,
  values: { [key: string]: any }
) => {
  // How to use in functional component
  // tracking props
  // useTraceUpdate("Props", {
  // props go here...
  // });

  // How to use
  // tracking state
  // useTraceUpdate("State", {
  // states go here...
  // });

  const prevValuesRef = useRef(values);

  useEffect(() => {
    const prevValues = prevValuesRef.current;

    const changedEntries = Object.entries(values).filter(([key, value]) => {
      return prevValues[key] !== value;
    });

    if (changedEntries.length > 0) {
      console.log(`%c ${name} changed:`, "color: #0549AA; font-size: 12px;");

      changedEntries.forEach(([key, value]) => {
        console.log(
          `%c  ${key}:`,
          "color: #0549AA; font-size: 11px;",
          "from",
          prevValues[key],
          "to",
          value
        );
      });
    }

    prevValuesRef.current = values;
  }, [name, values]);
};
