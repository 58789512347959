import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const updateEvent = (eventId, data) => ({
  type: Actions.UPDATE_EVENT,
  eventId: eventId,
  payload: data,
  token: getKeycloakToken(),
});

export const updateEventFulfilled = () => ({
  type: Actions.UPDATE_EVENT_FULFILLED,
});
