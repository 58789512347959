import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchMessagePrediction = (data) => ({
  type: Actions.FETCH_MESSAGE_PREDICTION,
  payload: data,
  token: getKeycloakToken(),
});

export const setMessagePrediction = (data) => ({
  type: Actions.SET_MESSAGE_PREDICTION,
  payload: data,
});
