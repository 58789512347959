import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchOneOffCampaigns = () => ({
  type: Actions.FETCH_ONEOFF_CAMPAIGNS,
  token: getKeycloakToken(),
});

export const fetchCampaignTypes = () => ({
  type: Actions.FETCH_CAMPAIGN_TYPES,
  token: getKeycloakToken(),
});

export const setCampaignTypes = (data) => ({
  type: Actions.SET_CAMPAIGN_TYPES,
  payload: data,
});
export const setOneOffCampaigns = (data) => ({
  type: Actions.SET_ONEOFF_CAMPAIGNS,
  payload: data,
});

export const fetchOneOffCampaign = (data) => ({
  type: Actions.FETCH_ONEOFF_CAMPAIGN,
  payload: data,
  token: getKeycloakToken(),
});

export const setOneOffCampaign = (data) => ({
  type: Actions.SET_ONEOFF_CAMPAIGN,
  payload: data,
});

export const createOneOffCampaign = (data) => ({
  type: Actions.CREATE_ONEOFF_CAMPAIGN,
  payload: data,
  token: getKeycloakToken(),
});

export const createOneOffCampaignFulfilled = () => ({
  type: Actions.CREATE_ONEOFF_CAMPAIGN_FULFILLED,
});

export const deleteOneOffCampaign = (data) => ({
  type: Actions.DELETE_ONEOFF_CAMPAIGN,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteOneOffCampaignFulfilled = (data) => ({
  type: Actions.DELETE_ONEOFF_CAMPAIGN_FULFILLED,
  payload: data,
});

export const fetchOngoingCampaigns = () => ({
  type: Actions.FETCH_ONGOING_CAMPAIGNS,
  token: getKeycloakToken(),
});

export const setOngoingCampaigns = (data) => ({
  type: Actions.SET_ONGOING_CAMPAIGNS,
  payload: data,
});

export const fetchOngoingCampaign = (data) => ({
  type: Actions.FETCH_ONGOING_CAMPAIGN,
  payload: data,
  token: getKeycloakToken(),
});

export const setOngoingCampaign = (data) => ({
  type: Actions.SET_ONGOING_CAMPAIGN,
  payload: data,
});

export const createOngoingCampaign = (data) => ({
  type: Actions.CREATE_ONGOING_CAMPAIGN,
  payload: data,
  token: getKeycloakToken(),
});

export const createOngoingCampaignFulfilled = () => ({
  type: Actions.CREATE_ONGOING_CAMPAIGN_FULFILLED,
});

export const deleteOngoingCampaign = (data) => ({
  type: Actions.DELETE_ONGOING_CAMPAIGN,
  payload: data,
  token: getKeycloakToken(),
});

export const deleteOngoingCampaignFulfilled = (data) => ({
  type: Actions.DELETE_ONGOING_CAMPAIGN_FULFILLED,
  payload: data,
});
