import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  addTagToMessageFulfilled,
  createTagCategoryFulfilled,
  createTagElementFulfilled,
  createTagProblemFulfilled,
  createTagReasonFulfilled,
  createTagTopicFulfilled,
  setTagCategories,
  setTagProblems,
  setTagReasons,
  setTagTopics,
  setTagsBySenderId,
  deleteTagFromMessage,
  deleteTagFromMessageFulfilled,
  fetchTagsBySenderId,
  editTagElementFulfilled,
  fetchTagCategories,
  fetchTagTopics,
  fetchTagReasons,
  fetchTagProblems,
  setTags,
  fetchTags,
  changeReviewStatusFulfilled,
  changePriorityFulfilled,
} from "../actions/tags";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

//das ist für externes Dashboard
// export const fetchTagsEpic = (action$, state$) => {
//   return action$.pipe(
//     ofType(ActionConstants.FETCH_TAGS),
//     debounceTime(0),
//     switchMap((action) => {
//       return ajax({
//         url: `${REACT_APP_ACTION_HOST}/tags/tags`,
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: action.token,
//           Tenant_Realm: state$.value.tenant,
//           Type_Origin: "dashboard",
//         },
//       }).pipe(
//         map((response) => {
//           return setTags(response.response);
//         }),
//         catchError((error) => {
//           return of(setTags(null), errorMessage(error.message));
//         })
//       );
//     })
//   );
// };

export const fetchCategoriesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TAG_CATEGORIES),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/categories`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setTagCategories(response.response);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
export const fetchTopicsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TAG_TOPICS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/topics`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setTagTopics(response.response);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
export const fetchReasonsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TAG_REASONS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/reasons`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setTagReasons(response.response);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
export const fetchTagsBySenderIdEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TAGS_BY_SENDERID),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/tags/by_sender_id?sender_id=${action.payload.sender_id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setTagsBySenderId(response.response);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
export const fetchProblemsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TAG_PROBLEMS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/problems`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setTagProblems(response.response);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
export const createTagCategoryEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_TAG_CATEGORY),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/category?category_value=${action.payload.category_value}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(createTagCategoryFulfilled());
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const createTagElementEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_TAG_ELEMENT),
    debounceTime(0),
    switchMap((action) => {
      let url;
      let parent_type =
        action.payload.type === "topic"
          ? "category"
          : action.payload.type === "reason"
          ? "topic"
          : action.payload.type === "problem"
          ? "reason"
          : null;
      if (action.payload.parent_id) {
        url = `${REACT_APP_ACTION_HOST}/tags/${action.payload.type}?${
          action.payload.type
        }_value=${
          action.payload[`${action.payload.type}_value`]
        }&${parent_type}_id=${action.payload.parent_id}`;
      } else {
        url = `${REACT_APP_ACTION_HOST}/tags/category?category_value=${action.payload.category_value}`;
      }

      return ajax({
        url: url,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(createTagElementFulfilled());
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const editTagElementEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.EDIT_TAG_ELEMENT),
    debounceTime(0),
    switchMap((action) => {
      let url;

      url = `${REACT_APP_ACTION_HOST}/tags/${action.payload.type}?${
        action.payload.type
      }_value=${action.payload[`${action.payload.type}_value`]}&${
        action.payload.type
      }_id=${action.payload[`${action.payload.type}_id`]}`;

      return ajax({
        url: url,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            editTagElementFulfilled(),
            fetchTagCategories(),
            fetchTagTopics(),
            fetchTagReasons(),
            fetchTagProblems()
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const addTagToMessageEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.ADD_TAG_TO_MESSAGE),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/tag?problem_id=${action.payload.problem_id}&message_text=${action.payload.message_text}&event_id=${action.payload.event_id}&sender_id=${action.payload.sender_id}&message_time=${action.payload.message_time}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            addTagToMessageFulfilled(),
            fetchTagsBySenderId({ sender_id: action.payload.sender_id }),
            fetchTags()
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const deleteTagFromMessageEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_TAG_FROM_MESSAGE),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/tag?tag_id=${action.payload.tag_id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            deleteTagFromMessageFulfilled(action.payload),
            fetchTagsBySenderId({ sender_id: action.payload.sender_id }),
            fetchTags()
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchTagsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TAGS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/tags`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: "standard",
        },
      }).pipe(
        map((response) => {
          return setTags(response.response);
        }),
        catchError((error) => {
          return of(setTags(null));
        })
      );
    })
  );
};
export const changeReviewStatusEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CHANGE_REVIEW_STATUS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/tags/review_status/by_problem_id?problem_id=${action.payload.problem_id}&review_status_id=${action.payload.review_status_id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: "standard",
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(changeReviewStatusFulfilled(), fetchTags());
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const changePriorityEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CHANGE_PRIORITY),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/tag/priority?tag_id=${action.payload.tag_id}&priority=${action.payload.priority}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: "standard",
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            changePriorityFulfilled(),
            fetchTagsBySenderId({ sender_id: action.payload.sender_id })
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
export const addCommentToTagEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.ADD_COMMENT_TO_TAG),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/tag/comment?tag_id=${action.payload.tag_id}&comment=${action.payload.comment}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: "standard",
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            fetchTagsBySenderId({ sender_id: action.payload.sender_id })
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const addAddtitionalAttributesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.ADD_ADDITIONAL_ATTRIBUTES_TO_TAG),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/tags/tag/additional_attributes?tag_id=${action.tag_id}&additional_attributes=${action.payload}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: "standard",
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(fetchTagsBySenderId({ sender_id: action.sender_id }));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

// export const createTagTopicEpic = (action$, state$) => {
//   return action$.pipe(
//     ofType(ActionConstants.CREATE_TAG_TOPIC),
//     debounceTime(0),
//     switchMap((action) => {
//       return ajax({
//         url: `${REACT_APP_ACTION_HOST}/tags/topic?category_id=${action.payload.category_id}&topic_value=${action.payload.topic_value}`,
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: action.token,
//           Tenant_Realm: state$.value.tenant,
//           Type_Origin: "dashboard",
//         },
//       }).pipe(
//         concatMap((response) => {
//           return of(createTagTopicFulfilled());
//         }),
//         catchError((error) => {
//           return of(errorMessage(error.message));
//         })
//       );
//     })
//   );
// };
// export const createTagReasonEpic = (action$, state$) => {
//   return action$.pipe(
//     ofType(ActionConstants.CREATE_TAG_REASON),
//     debounceTime(0),
//     switchMap((action) => {
//       return ajax({
//         url: `${REACT_APP_ACTION_HOST}/tags/reason?topic_id=${action.payload.topic_id}&reason_value=${action.payload.reason_value}`,
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: action.token,
//           Tenant_Realm: state$.value.tenant,
//           Type_Origin: "dashboard",
//         },
//       }).pipe(
//         concatMap((response) => {
//           return of(createTagReasonFulfilled());
//         }),
//         catchError((error) => {
//           return of(errorMessage(error.message));
//         })
//       );
//     })
//   );
// };
//
// export const createTagProblemEpic = (action$, state$) => {
//   return action$.pipe(
//     ofType(ActionConstants.CREATE_TAG_PROBLEM),
//     debounceTime(0),
//     switchMap((action) => {
//       return ajax({
//         url: `${REACT_APP_ACTION_HOST}/tags/problem?reason_id=${action.payload.reason_id}&problem_value=${action.payload.problem_value}`,
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: action.token,
//           Tenant_Realm: state$.value.tenant,
//           Type_Origin: "dashboard",
//         },
//       }).pipe(
//         concatMap((response) => {
//           return of(createTagProblemFulfilled());
//         }),
//         catchError((error) => {
//           return of(errorMessage(error.message));
//         })
//       );
//     })
//   );
// };

// export const fetchTagEpic = (action$, state$) => {
//   return action$.pipe(
//     ofType(ActionConstants.FETCH_LABEL),
//     debounceTime(0),
//     switchMap((action) => {
//       return ajax({
//         url: `${REACT_APP_ACTION_HOST}/`,
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: action.token,
//           Tenant_Realm: state$.value.tenant,
//           Type_Origin: "dashboard",
//         },
//       }).pipe(
//         map((response) => {
//           return setTag(response.response.result);
//         }),
//         catchError((error) => {
//           return of(setTag(null), errorMessage(error.message));
//         })
//       );
//     })
//   );
// };

// export const createTagEpic = (action$, state$) => {
//   return action$.pipe(
//     ofType(ActionConstants.CREATE_TAG),
//     debounceTime(0),
//     switchMap((action) => {
//       return ajax({
//         url: `${REACT_APP_ACTION_HOST}/tags/tag`,
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: action.token,
//           Tenant_Realm: state$.value.tenant,
//           Type_Origin: "dashboard",
//         },
//         body: { ...action.payload },
//       }).pipe(
//         concatMap((response) => {
//           return of(createTagFulfilled(), setTags(null));
//         }),
//         catchError((error) => {
//           return of(errorMessage(error.message));
//         })
//       );
//     })
//   );
// };

// export const deleteTagEpic = (action$, state$) => {
//   return action$.pipe(
//     ofType(ActionConstants.DELETE_TAG),
//     debounceTime(0),
//     switchMap((action) => {
//       return ajax({
//         url: `${REACT_APP_ACTION_HOST}/tags/tag`,
//         method: "DELETE",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: action.token,
//           Tenant_Realm: state$.value.tenant,
//           Type_Origin: "dashboard",
//         },
//       }).pipe(
//         map((response) => {
//           return deleteTagFulfilled(action.payload);
//         }),
//         catchError((error) => {
//           return of(errorMessage(error.message));
//         })
//       );
//     })
//   );
// };
