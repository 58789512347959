import { Observable, of } from "rxjs";
import { switchMap, map, catchError, debounceTime } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { setSynonyms } from "../actions/synonyms";
import { errorMessage } from "../actions/error";
import { getAgentUrl } from "../../lib/util";

export const fetchSynonymsEpic = (action$, state$) => {
  let url = "/entities/synonyms";
  return action$.pipe(
    ofType(ActionConstants.FETCH_SYNONYMS),
    debounceTime(500),
    switchMap((action) =>
      Observable.create((observer) => {
        fetch(`${getAgentUrl(state$.value.language)}${url}`, {
          headers: {
            Authorization: state$.value.keycloakToken,
            Tenant_Realm: state$.value.tenant.toLowerCase(),
            Type_Origin: "dashboard",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            observer.next(data);
            observer.complete();
          })
          .catch((err) => observer.error(err));
      })
    ),
    map((response) => {
      if (!Object.keys(response.synonyms).length)
        return errorMessage("Data not found!");
      return setSynonyms(response.synonyms);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );
};
