import { useDispatch } from "react-redux";
import { clearSnackbar } from "store/actions/snackbar";
import Snackbar from "@mui/material/Snackbar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useStoreState } from "store/hooks";
import Alert from "@mui/material/Alert";
import { useTranslations } from "next-intl";
import { SyntheticEvent } from "react";
import Slide, { SlideProps } from "@mui/material/Slide";
import { getCssVariableValueColor } from "features/copilot/helpers";

export default function RequestSnackbar() {
  const dispatch = useDispatch();
  const snackbarMessages = useStoreState((state) => state.snackbarMessages);
  const isCopilotReady = useStoreState((state) => state.isCopilotReady);
  const copilotColorMode = useStoreState((state) => state.copilotColorMode);
  const t = useTranslations();
  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(clearSnackbar());
  };

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <>
      {snackbarMessages?.map(
        (
          {
            message,
            type,
            anchorOrigin,
            duration,
            customTranslation,
          }: {
            message: any;
            type: "success" | "info" | "warning" | "error";
            anchorOrigin?: {
              vertical: "top" | "bottom";
              horizontal: "left" | "center" | "right";
            };
            duration?: number;
            customTranslation?: boolean;
          },
          index: number
        ) => {
          return (
            <Snackbar
              key={index}
              anchorOrigin={
                anchorOrigin
                  ? { ...anchorOrigin }
                  : {
                      vertical: "bottom",
                      horizontal: "right",
                    }
              }
              open={true}
              autoHideDuration={duration || 3000}
              onClose={handleClose}
              TransitionComponent={SlideTransition}
              action={
                <>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <Icon fontSize="small">close</Icon>
                  </IconButton>
                </>
              }
            >
              <Alert
                elevation={6}
                onClose={handleClose}
                severity={type}
                sx={{
                  width: "100%",
                  ...(isCopilotReady && {
                    backgroundColor: getCssVariableValueColor(
                      copilotColorMode,
                      "--copilot-dialog-background"
                    ),
                    color: getCssVariableValueColor(
                      copilotColorMode,
                      "--copilot-on-dialog-background"
                    ),
                  }),
                }}
              >
                {customTranslation ? message : t(message)}
              </Alert>
            </Snackbar>
          );
        }
      )}
    </>
  );
}
