import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useTranslations } from "next-intl";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
// @ts-ignore
import cloneDeep from "lodash.clonedeep";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import TriggerNodeInputs from "features/admin/components/EditTriggerNodes/TriggerNodeInputs";
import getConfig from "lib/config";
import AddNewSentences from "features/admin/components/EditTriggerNodes/AddNewSentences";
import { editSelectedTriggerNodes } from "store/actions/admin";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_TENANT } = publicRuntimeConfig;

type TriggerNodesEditAndSelectionDialogProps = {
  triggerNodesFetchedBySentence: any;
  handleClickOpen: () => void;
  handleClose: () => void;
  open: boolean;
  sentence: string;
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  padding: "8px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "4px 24px 8px 24px",
  backgroundColor: "rgba(0, 0, 0, .03)",
}));

export default function TriggerNodesEditAndSelectionDialog({
  triggerNodesFetchedBySentence,
  handleClose,
  open,
  sentence,
}: TriggerNodesEditAndSelectionDialogProps) {
  const dispatch = useDispatch();
  const [addedSentences, setAddedSentences] = useState<any[]>([]);
  const [selectedStates, setSelectedStates] = useState<{
    [key: string | number]: { [key: string | number]: boolean };
  }>({});
  const [deletedSentences, setDeletedSentences] = useState<{
    [key: string | number]: { [key: string | number]: string[] };
  }>({});
  const [renderList, setRenderList] = useState<boolean>(false);
  const [tenants, setTenants] = useState<string[]>([]);
  const [graphs, setGraphs] = useState<{
    [key: string]: string | any;
  }>({});
  const [triggerNodes, setTriggerNodes] = useState<{
    [key: string]: string | any;
  }>({});
  const [triggerNodesInputs, setTriggerNodesInputs] = useState<{
    [key: string]: string | any;
  }>({});
  const [triggerNodesInputSentences, setTriggerNodesInputSentences] = useState<{
    [key: string]: string | any;
  }>({});
  const [graphNamesCollection, setGraphNamesCollection] = useState<{
    [key: string]: string | any;
  }>({});

  const [archivedDeletedSentences, setArchivedDeletedSentences] = useState<{
    [key: string | number]: { [key: string | number]: string[] };
  }>({});
  const [
    deletedTriggerNodeInputSentencesBodies,
    setDeletedTriggerNodeInputSentencesBodies,
  ] = useState<
    {
      [key: string]: {
        [key: string]: any;
      };
    }[]
  >([]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    triggerNodeId = null
  ) => {
    if (triggerNodeId) {
      const stateBeingChange = selectedStates[triggerNodeId][event.target.name];
      setSelectedStates((previousState) => ({
        ...previousState,
        [triggerNodeId]: {
          ...previousState[triggerNodeId],
          [event.target.name]: !stateBeingChange,
        },
      }));
    } else {
      const statesBeingChange = selectedStates[event.target.name];
      const allTrue = Object.keys(statesBeingChange).every(
        (inputId) => statesBeingChange[inputId]
      );
      Object.keys(statesBeingChange).forEach((inputId) => {
        statesBeingChange[inputId] = !allTrue;
      });
      setSelectedStates((previousState) => ({
        ...previousState,
        [event.target.name]: { ...statesBeingChange },
      }));
    }
  };

  useEffect(() => {
    if (triggerNodesFetchedBySentence) {
      let tenantArray: any[] = [];
      let graphNames = {};
      let tmpTriggerTextInputKeysObject: any = {};
      Object.keys(triggerNodesFetchedBySentence).forEach((tenant) => {
        const currentTenant = tenant;
        const currentTenantGraphs =
          triggerNodesFetchedBySentence[currentTenant];
        tenantArray.push(currentTenant);
        let tmpGraphArray: any[] = [];

        Object.keys(currentTenantGraphs).forEach((graph) => {
          const currentGraph = graph;
          const currentGraphTriggerNodes =
            currentTenantGraphs[currentGraph].trigger_nodes;
          const currentGraphName = currentTenantGraphs[currentGraph].graph_name;
          tmpGraphArray.push(currentGraph);
          graphNames = { ...graphNames, [currentGraph]: currentGraphName };
          let tmpTriggerNodesArray: any[] = [];

          Object.keys(currentGraphTriggerNodes).forEach((triggerNode) => {
            const currentTriggerNode = triggerNode;
            const currentTriggerNodeTextInputs =
              currentGraphTriggerNodes[currentTriggerNode];
            tmpTriggerNodesArray.push(currentTriggerNode);

            let tmpTriggerTextInputs: any[] = [];
            tmpTriggerTextInputKeysObject = {
              ...tmpTriggerTextInputKeysObject,
              [currentTriggerNode]: {},
            };
            Object.keys(currentTriggerNodeTextInputs).forEach(
              (textInput, index) => {
                const currentTextInput = textInput;
                const currentTextInputSentences =
                  currentTriggerNodeTextInputs[currentTextInput].sentences;
                tmpTriggerTextInputs.push(currentTextInput);

                tmpTriggerTextInputKeysObject[currentTriggerNode][
                  currentTextInput
                ] = true;
                const currentTriggerNodesInputSentences = {
                  [currentTextInput]: currentTextInputSentences,
                };
                setTriggerNodesInputSentences(
                  (previousTriggerNodesInputSentences) => ({
                    ...previousTriggerNodesInputSentences,
                    //have to add this currentTriggerNode (it's the id of the trigger node) because text input ids seem not to be unique when they were copied
                    [currentTriggerNode]: {
                      ...previousTriggerNodesInputSentences[currentTriggerNode],
                      ...currentTriggerNodesInputSentences,
                    },
                  })
                );
              }
            );

            setSelectedStates({
              ...tmpTriggerTextInputKeysObject,
            });
            const currentTriggerNodeTextInputsObject = {
              [currentTriggerNode]: tmpTriggerTextInputs,
            };

            setTriggerNodesInputs((previousTriggerNodesInputs) => ({
              ...previousTriggerNodesInputs,
              ...currentTriggerNodeTextInputsObject,
            }));
          });
          const currentGraphTriggerNodesObject = {
            [currentGraph]: tmpTriggerNodesArray,
          };

          setTriggerNodes((previousTriggerNodes) => ({
            ...previousTriggerNodes,
            ...currentGraphTriggerNodesObject,
          }));
        });
        const currentTenantGraphObject = { [currentTenant]: tmpGraphArray };

        setTenants(tenantArray);
        setGraphs((previousGraphs) => ({
          ...previousGraphs,
          ...currentTenantGraphObject,
        }));

        setGraphNamesCollection(graphNames);
      });
    }
  }, [triggerNodesFetchedBySentence]);

  function findTopLevelObjWithKey(
    obj: { [x: string]: any },
    triggerNodeId: string,
    keyToFind: string
  ) {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        if (findKeyInNestedObj(obj[key], triggerNodeId, keyToFind)) {
          return { [key]: obj[key] };
        }
      }
    }
    return null;
  }

  function findKeyInNestedObj(
    obj: { [x: string]: any; hasOwnProperty: (arg0: any) => any },
    triggerNodeId: string,
    keyToFind: string
  ) {
    if (obj.hasOwnProperty(triggerNodeId)) {
      if (obj[triggerNodeId].hasOwnProperty(keyToFind)) {
        return true;
      }
    }
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        if (findKeyInNestedObj(obj[key], triggerNodeId, keyToFind)) {
          return true;
        }
      }
    }
    return false;
  }

  function addDataForBody(
    obj: { [x: string]: any } | null,
    keyToStop: string,
    triggerInputId: string,
    dataToAdd: any,
    type: "added" | "deleted"
  ) {
    for (let key in obj) {
      if (key === keyToStop) {
        const triggerNode = obj[key];
        Object.keys(triggerNode).forEach((triggerInputKey) => {
          if (triggerInputKey === triggerInputId) {
            const currentTriggerDeletedSentences =
              triggerNode[triggerInputId][`${type}_sentences`] || [];
            triggerNode[triggerInputId][`${type}_sentences`] = [
              ...currentTriggerDeletedSentences,
              ...dataToAdd,
            ];
            if (obj[key][triggerInputId].sentences)
              delete obj[key][triggerInputId].sentences;
          }
        });
      }
      if (typeof obj[key] === "object" && obj[key] !== null) {
        addDataForBody(obj[key], keyToStop, triggerInputId, dataToAdd, type);
      }
    }
  }

  function removeNestedNotSelectedTriggerNodes(
    obj: { [x: string]: any } | null,
    keyToStop: string
  ) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        removeNestedNotSelectedTriggerNodes(obj[key], keyToStop);
      }

      if (key === keyToStop) {
        const triggerNodes = obj[key];
        Object.keys(triggerNodes).forEach((triggerNodeId) => {
          Object.keys(triggerNodes[triggerNodeId]).forEach((textInputId) => {
            if (
              Object.keys(triggerNodes[triggerNodeId]).length <= 1 &&
              !triggerNodes[triggerNodeId][textInputId].hasOwnProperty(
                "added_sentences"
              ) &&
              !triggerNodes[triggerNodeId][textInputId].hasOwnProperty(
                "deleted_sentences"
              )
            ) {
              delete triggerNodes[triggerNodeId];
            } else if (
              Object.keys(triggerNodes[triggerNodeId]).length > 1 &&
              !triggerNodes[triggerNodeId][textInputId].hasOwnProperty(
                "added_sentences"
              ) &&
              !triggerNodes[triggerNodeId][textInputId].hasOwnProperty(
                "deleted_sentences"
              )
            ) {
              delete triggerNodes[triggerNodeId][textInputId];
            }
          });
        });
      }
    }
  }

  useEffect(() => {
    if (deletedSentences && Object.keys(deletedSentences).length > 0) {
      const triggerNodesFetchedBySentenceCopy = cloneDeep(
        triggerNodesFetchedBySentence
      );

      let tmpDeletedTriggerNodeInputSentencesBodies: any;
      Object.keys(deletedSentences).forEach((triggerNodeId) => {
        Object.keys(deletedSentences[triggerNodeId]).forEach(
          (triggerInputId) => {
            if (deletedSentences[triggerNodeId][triggerInputId].length === 0)
              return;

            Object.keys(triggerNodesFetchedBySentenceCopy).forEach(
              (tenantId) => {
                const objectDeepCopy = cloneDeep(
                  triggerNodesFetchedBySentenceCopy[tenantId]
                );
                let deletedTriggerNodeSentence = findTopLevelObjWithKey(
                  objectDeepCopy,
                  triggerNodeId,
                  triggerInputId
                );

                if (!deletedTriggerNodeSentence) return;

                addDataForBody(
                  deletedTriggerNodeSentence,
                  triggerNodeId,
                  triggerInputId,
                  deletedSentences[triggerNodeId][triggerInputId],
                  "deleted"
                );
                removeNestedNotSelectedTriggerNodes(
                  deletedTriggerNodeSentence,
                  "trigger_nodes"
                );
                tmpDeletedTriggerNodeInputSentencesBodies = {
                  [tenantId]: deletedTriggerNodeSentence,
                };
              }
            );

            setDeletedTriggerNodeInputSentencesBodies(
              (previousDeletedTriggerNodeInputSentencesBodies) => {
                return [
                  ...previousDeletedTriggerNodeInputSentencesBodies,
                  tmpDeletedTriggerNodeInputSentencesBodies,
                ];
              }
            );
            setDeletedSentences({});
          }
        );
      });
    }
  }, [deletedSentences]);

  const mergeObjectsAndSubmit = (deletions: any[]) => {
    const additions = onAddAddedSentences(addedSentences);

    const requestBodies = [...deletions, ...additions];
    let mergedData: any = {};
    requestBodies.forEach((item) => {
      const topKey = Object.keys(item)[0];
      const midKey = Object.keys(item[topKey])[0];

      if (!mergedData[topKey]) {
        mergedData[topKey] = {};
      }
      if (!mergedData[topKey][midKey]) {
        mergedData[topKey][midKey] = {
          graph_name: item[topKey][midKey].graph_name,
          trigger_nodes: {},
        };
      }

      const trigger_nodes = item[topKey][midKey]["trigger_nodes"];

      Object.keys(trigger_nodes).forEach((botKey) => {
        const botNode = trigger_nodes[botKey];

        if (!mergedData[topKey][midKey]["trigger_nodes"][botKey]) {
          mergedData[topKey][midKey]["trigger_nodes"][botKey] = {};
        }

        const mergedBotNode =
          mergedData[topKey][midKey]["trigger_nodes"][botKey];

        Object.keys(botNode).forEach((sentenceKey) => {
          const currentTriggerNode = botNode[sentenceKey];

          if (!mergedBotNode[sentenceKey]) {
            mergedBotNode[sentenceKey] = { ...currentTriggerNode };
          } else {
            Object.keys(currentTriggerNode).forEach((key) => {
              if (Array.isArray(currentTriggerNode[key])) {
                if (!mergedBotNode[sentenceKey][key]) {
                  mergedBotNode[sentenceKey][key] = [
                    ...currentTriggerNode[key],
                  ];
                } else {
                  mergedBotNode[sentenceKey][key] = [
                    ...new Set([
                      ...mergedBotNode[sentenceKey][key],
                      ...currentTriggerNode[key],
                    ]),
                  ];
                }
              }
            });
          }
        });
      });
    });

    dispatch(editSelectedTriggerNodes(mergedData));
  };

  const onAddAddedSentences = (sentences: React.SetStateAction<string[]>) => {
    const triggerNodesFetchedBySentenceCopy = cloneDeep(
      triggerNodesFetchedBySentence
    );

    let selectedEditedTriggerNodeBodies: any[] = [];
    Object.keys(selectedStates).forEach((triggerNodeId) => {
      Object.keys(selectedStates[triggerNodeId]).forEach((triggerInput) => {
        if (!selectedStates[triggerNodeId][triggerInput]) return;
        Object.keys(triggerNodesFetchedBySentenceCopy).forEach((tenantId) => {
          const objectDeepCopy = cloneDeep(
            triggerNodesFetchedBySentenceCopy[tenantId]
          );
          const selectedEditedTriggerNode = findTopLevelObjWithKey(
            objectDeepCopy,
            triggerNodeId,
            triggerInput
          );
          if (!selectedEditedTriggerNode) return;
          addDataForBody(
            selectedEditedTriggerNode,
            triggerNodeId,
            triggerInput,
            sentences,
            "added"
          );
          removeNestedNotSelectedTriggerNodes(
            selectedEditedTriggerNode,
            "trigger_nodes"
          );
          if (selectedEditedTriggerNode)
            selectedEditedTriggerNodeBodies.push({
              [tenantId]: selectedEditedTriggerNode,
            });
          return;
        });
      });
    });

    return selectedEditedTriggerNodeBodies;
  };

  useEffect(() => {
    if (
      tenants &&
      graphs &&
      graphNamesCollection &&
      triggerNodes &&
      triggerNodesInputs &&
      triggerNodesInputSentences &&
      !renderList
    ) {
      setRenderList(true);
    }
  }, [
    tenants,
    graphs,
    graphNamesCollection,
    triggerNodes,
    triggerNodesInputs,
    triggerNodesInputSentences,
  ]);

  useEffect(() => {
    return () => {
      setRenderList(false);
    };
  }, []);

  useEffect(() => {
    // setTriggerNodesInputs
    Object.keys(triggerNodesInputSentences).forEach((key) => {
      const triggerNodesInputSentencesIds = triggerNodesInputSentences[key];
      Object.keys(triggerNodesInputSentencesIds).forEach(
        (triggerNodesInputSentencesId) => {
          if (
            triggerNodesInputSentencesIds[triggerNodesInputSentencesId]
              .length === 0
          ) {
            const newTriggerNodesInputs = { ...triggerNodesInputs };

            const indexOfTriggerNodesInputSentencesId = newTriggerNodesInputs[
              key
            ].indexOf(triggerNodesInputSentencesId);
            if (indexOfTriggerNodesInputSentencesId === -1) return;
            newTriggerNodesInputs[key].splice(
              indexOfTriggerNodesInputSentencesId,
              1
            );

            const currentTriggerNodeTextInputsObject = {
              [key]: [...newTriggerNodesInputs[key]],
            };

            setTriggerNodesInputs((previousTriggerNodesInputs) => ({
              ...previousTriggerNodesInputs,
              ...currentTriggerNodeTextInputsObject,
            }));
          }
        }
      );
    });
  }, [triggerNodesInputSentences]);

  useEffect(() => {
    Object.keys(triggerNodesInputs).forEach((key) => {
      const triggerNodesIds = triggerNodesInputs[key];
      if (triggerNodesIds.length === 0) {
        const newTriggerNodes = cloneDeep(triggerNodes);
        Object.keys(newTriggerNodes).forEach((graphId) => {
          const indexOfTriggerNodesId = newTriggerNodes[graphId].indexOf(key);
          if (indexOfTriggerNodesId === -1) return;
          newTriggerNodes[graphId].splice(indexOfTriggerNodesId, 1);
          const currentNewTriggerNodes = {
            [graphId]: [...newTriggerNodes[graphId]],
          };

          setTriggerNodes((previousTriggerNodesInputs) => ({
            ...previousTriggerNodesInputs,
            ...currentNewTriggerNodes,
          }));
        });
      }
    });
  }, [triggerNodesInputs]);

  const onRemoveTriggerNodeInputSentences = (
    newTriggerNodesInputSentencesArray: any,
    triggerNodeId: string | number
  ) => {
    setTriggerNodesInputSentences(
      (previousTriggerNodesInputSentences: { [x: string]: any }) => ({
        ...previousTriggerNodesInputSentences,
        [triggerNodeId]: {
          ...previousTriggerNodesInputSentences[triggerNodeId],
          ...newTriggerNodesInputSentencesArray,
        },
      })
    );
  };

  function renderAccordions() {
    return (
      <Stack
        sx={{
          width: "100%",
        }}
      >
        {tenants.map((tenantId: string, index) => (
          <Accordion
            defaultExpanded={true}
            key={tenantId + index}
            sx={{
              width: "100%",
            }}
          >
            <AccordionSummary>
              <Typography
                variant={"h5"}
                sx={{
                  fontSize: "1.25rem",
                  color: "rgba(0,0,0,0.46)",
                }}
              >
                {tenantId}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction={"row"} sx={{ gap: "12px" }}>
                {graphs[tenantId].map((graphId: any, index: any) => (
                  <>
                    {triggerNodes[graphId] &&
                    triggerNodes[graphId].length === 0 ? undefined : (
                      <Stack
                        key={graphId + index}
                        sx={{
                          border: "1px solid rgba(223,225,229,1)",
                          backgroundColor: "#fff",
                          borderRadius: "9px",
                          padding: "8px 16px 16px 16px",
                          boxShadow: "0px 1px 4px rgba(100, 116, 139, 0.24)",
                          flex: "0 1 auto",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          sx={{
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <SmartToyIcon />
                          <Typography
                            variant={"h6"}
                            sx={{
                              padding: "8px 0px",
                              fontWeight: "400",
                              fontSize: "1rem",
                            }}
                            key={graphId}
                          >
                            {graphNamesCollection[graphId]}
                          </Typography>
                        </Stack>

                        <Divider />
                        <FormControl
                          sx={{}}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormGroup
                            row
                            sx={{ gap: "12px", marginTop: "12px" }}
                          >
                            {triggerNodes[graphId] &&
                              triggerNodes[graphId].map(
                                (triggerNodeId: any, index: any) => {
                                  if (
                                    triggerNodesInputs[triggerNodeId].length ===
                                    0
                                  )
                                    return null;
                                  return (
                                    <>
                                      {index > 0 ? (
                                        <WarningWrapper
                                          nodeType={"Trigger Node"}
                                          mapKey={triggerNodeId + index}
                                        >
                                          <TriggerNodeInputs
                                            archivedDeletedSentences={
                                              archivedDeletedSentences
                                            }
                                            deletedSentences={deletedSentences}
                                            setArchivedDeletedSentences={
                                              setArchivedDeletedSentences
                                            }
                                            setDeletedSentences={
                                              setDeletedSentences
                                            }
                                            selectedStates={selectedStates}
                                            triggerNodeId={triggerNodeId}
                                            handleChange={handleChange}
                                            triggerNodesInputs={
                                              triggerNodesInputs
                                            }
                                            triggerNodesInputSentences={
                                              triggerNodesInputSentences
                                            }
                                            setNewTriggerNodesInputSentences={(
                                              newTriggerNodesInputSentencesArray
                                            ) =>
                                              onRemoveTriggerNodeInputSentences(
                                                newTriggerNodesInputSentencesArray,
                                                triggerNodeId
                                              )
                                            }
                                          />
                                        </WarningWrapper>
                                      ) : (
                                        <TriggerNodeInputs
                                          archivedDeletedSentences={
                                            archivedDeletedSentences
                                          }
                                          deletedSentences={deletedSentences}
                                          setArchivedDeletedSentences={
                                            setArchivedDeletedSentences
                                          }
                                          setDeletedSentences={
                                            setDeletedSentences
                                          }
                                          selectedStates={selectedStates}
                                          triggerNodeId={triggerNodeId}
                                          handleChange={handleChange}
                                          triggerNodesInputs={
                                            triggerNodesInputs
                                          }
                                          triggerNodesInputSentences={
                                            triggerNodesInputSentences
                                          }
                                          setNewTriggerNodesInputSentences={(
                                            newTriggerNodesInputSentencesArray
                                          ) =>
                                            onRemoveTriggerNodeInputSentences(
                                              newTriggerNodesInputSentencesArray,
                                              triggerNodeId
                                            )
                                          }
                                        />
                                      )}
                                    </>
                                  );
                                }
                              )}
                          </FormGroup>
                        </FormControl>
                      </Stack>
                    )}
                  </>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    );
  }

  if (!renderList) {
    return <CircularProgress />;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography
            sx={{
              ml: 2,
              flex: 1,
              "& >span": { fontSize: "18px", fontWeight: 400 },
            }}
            variant="h6"
            component="div"
          >
            <span>Trigger Nodes für Satz:</span> {sentence}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Stack
        sx={{
          backgroundColor: "rgba(0, 0, 0, .03)",
          flexGrow: "1",
        }}
      >
        <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
          <AddNewSentences
            // onChange={onChangeTextInputSentences}
            values={addedSentences}
            setValues={setAddedSentences}
          />
          <Box
            sx={{
              alignSelf: "flex-end",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              padding: "0px 14px 24px 14px",
            }}
          >
            {" "}
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                ...(deletedTriggerNodeInputSentencesBodies.length === 0 && {
                  color: "rgba(0,0,0,0.46)",
                }),
              }}
            >
              {deletedTriggerNodeInputSentencesBodies.length === 0 &&
              addedSentences.length === 0
                ? "noch keine Editierungen durchgeführt"
                : "Editierungen Ausführen"}
              <span>-</span>
            </Typography>
            <Button
              disabled={
                deletedTriggerNodeInputSentencesBodies.length === 0 &&
                addedSentences.length === 0
              }
              variant={"contained"}
              onClick={() =>
                mergeObjectsAndSubmit(deletedTriggerNodeInputSentencesBodies)
              }
            >
              Ausführen
            </Button>
          </Box>
        </Stack>
        {renderList && renderAccordions()}
      </Stack>
    </Dialog>
  );
}

export function WarningWrapper({
  mapKey,
  children,
  nodeType,
}: any): JSX.Element {
  const t = useTranslations();
  return (
    <Stack
      key={mapKey}
      sx={{
        padding: "6px 8px 12px 6px",
        borderRadius: "9px",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: "var(--yellow)",
        gap: "6px",
        alignItems: "flex-start",
        backgroundColor: "#fff5e6",
        ...(REACT_APP_TENANT === "aar" && {
          borderColor: "#3498ff",
          backgroundColor: "rgba(194 ,224 ,254 ,0.26)",
        }),
      }}
    >
      <Tooltip
        title={t("Admin.duplicate_trigger_warning", { nodeType: nodeType })}
      >
        <WarningAmberRoundedIcon
          sx={{
            fill: "var(--yellow)",
            width: "26px",
            height: "26px",
            cursor: "pointer",
          }}
        />
      </Tooltip>
      {children}
    </Stack>
  );
}
