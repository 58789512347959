import * as React from "react";
import { useState } from "react";
import * as images from "../../../../public/assets";
import { useTranslations } from "next-intl";
import ResponseEditor from "features/dialogue-editor/components/DialogueNodes/ResponseEditor";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import cls from "features/dialogue-editor/components/DialogueNodes/dialogue-graph-node.module.scss";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const HoverControls = (props: any) => {
  const {
    onAdd,
    onDelete,
    onCopy,
    onOpenRichText,
    onCollapse,
    isDraggingConnection = false,
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        opacity: 0,
        top: "15px !important",
        padding: "5px 10px 5px 5px",
        pointerEvents: "none",
        position: "absolute",
        right: "calc(100% - 10px) !important",
        zIndex: 10,
      }}
      onFocus={(e) => {
        e.stopPropagation();
      }}
      className={`${cls.controlGroup} hover-controls`}
    >
      {onCopy && (
        <button onClick={onCopy}>
          <img
            alt={"Card icon"}
            className={cls.icon}
            src={images.dialogueIcoCloneCopy.src}
          />
        </button>
      )}
      {onDelete && (
        <button onClick={onDelete}>
          <img
            alt={"Card icon"}
            className={cls.icon}
            src={images.dialogueIcoTrash.src}
          />
        </button>
      )}
      {onOpenRichText && (
        <button onClick={onOpenRichText}>
          <img
            alt={"Card icon"}
            className={cls.icon}
            src={images.dialogueIcoRichTextEditor.src}
          />
        </button>
      )}
      {onAdd && (
        <button onClick={onAdd}>
          <img
            alt={"Card icon"}
            className={cls.icon}
            src={images.dialogueIcoAdd.src}
          />
        </button>
      )}
      {onCollapse && (
        <button onClick={onCollapse}>
          <img
            alt={"Card icon"}
            className={cls.icon}
            src={images.neela_icon_collapse.src}
          />
        </button>
      )}
    </Box>
  );
};

const UtteranceEditor = (props: any) => {
  const { utteranceTexts, responses, setResponses } = props;
  const t = useTranslations();

  const [isDraggingContent, setIsDraggingContent] = useState(false);
  const [focusedResponse, setFocusedResponse] = useState<number>();
  const [clearStateIndex, setClearStateIndex] = useState(-1);

  const onDragStart = (result: any) => {
    setIsDraggingContent(true);
  };

  const onClearState = () => {
    setClearStateIndex(-1);
  };

  const onResponseDragEnd = (result: any) => {
    setIsDraggingContent(false);
    if (!result.destination) {
      return;
    }
    onReorderResponse(result.source.index, result.destination.index);
  };

  const canDeleteListElement = (element: any) => {
    return element.length > 1 || (element[0].title ?? "") !== "";
  };

  const onReorderResponse = (startIndex: number, endIndex: number) => {
    setResponses((previousResponses: any) => {
      const [removed] = previousResponses.splice(startIndex, 1);
      previousResponses.splice(endIndex, 0, removed);
      return [...previousResponses];
    });
  };
  const onCopyResponse = (index: number) => {
    setResponses((previousResponses: any) => {
      let copied = responses[index];
      previousResponses.splice(index + 1, 0, copied);
      return [...previousResponses];
    });
  };
  const onAddResponse = (index: number) => {
    setResponses((previousResponses: any) => {
      return [
        ...previousResponses.slice(0, index + 1),
        "",
        ...previousResponses.slice(index + 1),
      ];
    });
  };
  const onDeleteResponse = (index: number) => {
    setResponses((previousResponses: any) => {
      return [
        ...previousResponses.slice(0, index),
        ...previousResponses.slice(index + 1),
      ];
    });
  };
  const onEditResponse = (index: number, value: any) => {
    setResponses((previousResponses: any) => {
      previousResponses[index] = value;
      return [...previousResponses];
    });
  };

  const handleClose = () => {}; // onClose();
  const handleAccept = () => {}; // onAccept();

  return (
    <>
      <DragDropContext
        onDragStart={onDragStart}
        onBeforeDragStart={onDragStart}
        onDragEnd={onResponseDragEnd}
      >
        <Droppable droppableId={`droppable-responses`}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {responses &&
                responses.map((element: any, index: number) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={`${element}-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          ref={provided.innerRef}
                          key={index}
                          sx={{
                            position: "relative",
                            ml: 10,
                            px: 4,
                            py: 2,
                            mb: 2,
                            "&:hover .hoverControls div": {
                              opacity: 1,
                              pointerEvents: "auto",
                              transform: "translateX(-10px)",
                            },
                            minWidth: "400px",
                          }}
                          onFocus={(e) => {
                            setFocusedResponse(index);
                          }}
                          {...provided.draggableProps}
                        >
                          <ResponseEditor
                            alwaysShowToolbar={true}
                            value={element}
                            index={index}
                            onChange={(value: any) =>
                              onEditResponse(index, value)
                            }
                            onClearState={() => {
                              onClearState();
                            }}
                            timedAutoSave={false}
                            showList={true}
                            controlledMode={false}
                          />
                          <Box
                            component="img"
                            sx={{
                              zIndex: 11,
                              opacity: 0,
                              position: "absolute",
                              transition: "opacity 0.2s, transform 0.2s",
                              right: 4,
                              bottom: 2,
                              padding: 1,
                              "&:hover": {
                                background: "rgba(167, 182, 194, 0.3)",
                              },
                              "&:active": {
                                background: "rgba(115, 134, 148, 0.3)",
                              },
                              ...(!isDraggingContent && {
                                opacity: 1,
                              }),
                            }}
                            {...provided.dragHandleProps}
                            src={images.dialogueIcoDragHandle.src}
                          />

                          <Box className="hoverControls">
                            <HoverControls
                              onCopy={() => onCopyResponse(index)}
                              onAdd={() => {
                                setClearStateIndex(index + 1);
                                onAddResponse(index);
                              }}
                              onDelete={
                                canDeleteListElement(responses)
                                  ? () => {
                                      setClearStateIndex(index);
                                      onDeleteResponse(index);
                                    }
                                  : false
                              }
                            />
                          </Box>
                        </Paper>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default UtteranceEditor;
