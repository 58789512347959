import Actions from "../../constants";

export const setDocument = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_DOCUMENT) {
    return currentState;
  }

  return action.payload;
};
export const setDocumentsInfo = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_DOCUMENTS_INFO) {
    return currentState;
  }

  return action.payload;
};
export const setIndexingDocuments = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_INDEXING_DOCUMENTS) {
    return currentState;
  }

  return action.payload;
};

export const setDocumentsUsage = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_DOCUMENTS_USAGE) {
    return currentState;
  }

  return action.payload;
};
export const setDownloadDocument = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_DOWNLOAD_DOCUMENT) {
    return currentState;
  }

  return action.payload;
};
export const setDocumentViewport = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_DOCUMENT_VIEWPORT) {
    return currentState;
  }

  return action.payload;
};

export const setUserConversations = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_USER_CONVERSATIONS) {
    return currentState;
  }

  return action.payload;
};
export const setDocumentConversationId = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_DOCUMENT_CONVERSATION_ID) {
    return currentState;
  }

  return action.payload;
};
export const setSelectedCopilotChat = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_SELECTED_COPILOT_CHAT) {
    return currentState;
  }

  return action.payload;
};
export const setNewCreatedChatId = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_NEW_CREATED_CHAT_ID) {
    return currentState;
  }

  return action.payload;
};
export const setLoadingNewDocument = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_LOADING_NEW_DOCUMENT) {
    return currentState;
  }

  return action.payload;
};
export const setCopilotAiConfig = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_COPILOT_AI_CONFIG) {
    return currentState;
  }

  return action.payload;
};
export const setCopilotColorMode = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_COPILOT_COLOR_MODE) {
    return currentState;
  }

  return action.payload;
};

export const setPdfData = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_PDF_DATA) {
    return currentState;
  }

  return action.payload;
};

export const setMessageLimitReached = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_MESSAGE_LIMIT_REACHED) {
    return currentState;
  }

  return action.payload;
};
export const setAnnotations = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_ANNOTATIONS) {
    return currentState;
  }

  return action.payload;
};
export const setAnnotationsHighlightColor = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_ANNOTATIONS_HIGHLIGHTCOLOR) {
    return currentState;
  }

  return action.payload;
};
export const setStorageLimitReached = (
  currentState: any,
  action: {
    type: any;
    payload: any;
  }
) => {
  if (action.type !== Actions.SET_STORAGE_LIMIT_REACHED) {
    return currentState;
  }

  return action.payload;
};

export const setDocumentViewFetchStatus = (
  currentState: {
    fetchDocumentDownload: any;
    fetchDocument: any;
    userConversations: any;
    jumpLocation: any;
  } | null,
  action: {
    type: string;
    error?: any;
    decodedSegment?: any;
    pageNumber?: any;
    metadata?: any;
  }
) => {
  if (action.type === Actions.DOWNLOAD_DOCUMENT_FULFILLED) {
    const currentStateCopy = { ...currentState };
    currentStateCopy["fetchDocumentDownload"] = {
      type: "success",
      message: "success",
    };
    return currentStateCopy;
  } else if (action.type === Actions.DOWNLOAD_DOCUMENT_FAILED) {
    const currentStateCopy = { ...currentState };
    currentStateCopy["fetchDocumentDownload"] = {
      type: "error",
      message: action.error,
    };
    return currentStateCopy;
  } else if (action.type === Actions.FETCH_DOCUMENT_FULFILLED) {
    const currentStateCopy = { ...currentState };
    if (action.decodedSegment && action.pageNumber) {
      currentStateCopy["jumpLocation"] = {
        pageNumber: action.pageNumber,
        decodedSegment: action.decodedSegment,
        metadata: action.metadata,
      };
    }

    currentStateCopy["fetchDocument"] = {
      type: "success",
      message: "success",
    };
    return currentStateCopy;
  } else if (action.type === Actions.FETCH_DOCUMENT_FAILED) {
    const currentStateCopy = { ...currentState };
    currentStateCopy["fetchDocument"] = {
      type: "error",
      message: action.error,
    };
    return currentStateCopy;
  } else if (action.type === Actions.FETCH_USER_CONVERSATIONS_FULFILLED) {
    const currentStateCopy = { ...currentState };
    currentStateCopy["userConversations"] = {
      type: "success",
      message: "success",
    };
    return currentStateCopy;
  } else if (action.type === Actions.FETCH_USER_CONVERSATIONS_FAILED) {
    const currentStateCopy = { ...currentState };
    currentStateCopy["userConversations"] = {
      type: "error",
      message: action.error,
    };
    return currentStateCopy;
  } else if (action.type === Actions.RESET_DOCUMENT_VIEW_FETCH_STATUS) {
    return {
      fetchDocumentDownload: null,
      fetchDocument: null,
      userConversations: null,
      jumpLocation: null,
    };
  }

  return currentState;
};
