import React from "react";
import { getBotAvatarLogo, getBotName, getHumanAvatarLogo } from "lib/util";
export const UserContext = React.createContext({
  state: {
    user: {
      id: "",
      name: getBotName(),
      avatar: getHumanAvatarLogo(),
      roles: ["super_admin"],
      groups: ["standard"],
    },
  },
  updateUser: () => null,
  resetUser: () => null,
});
