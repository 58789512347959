import getOriginalConfig from "next/config";
const { publicRuntimeConfig } = getOriginalConfig();
import cloneDeep from "lodash.clonedeep";

const getConfig = () => {
  if (typeof window !== "undefined") {
    let dynamicConfig = cloneDeep(publicRuntimeConfig);
    Object.keys(publicRuntimeConfig).forEach((e) => {
      if (window.__RUNTIME_ENV__[e]) {
        dynamicConfig[e] = window.__RUNTIME_ENV__[e];
      }
    });
    return { publicRuntimeConfig: dynamicConfig };
  }
  return { publicRuntimeConfig: publicRuntimeConfig };
};

export default getConfig;
