import Actions from "../constants";
import { ReduxState } from "types/index";

export const setMSLoginLink = (
  currentState: ReduxState["MSLoginLink"],
  action: any
) => {
  if (action.type !== Actions.SET_MS_LOGIN_LINK) {
    return currentState;
  }

  return action.payload;
};

export const setMSLoginState = (
  currentState: ReduxState["MSLoginState"],
  action: any
) => {
  if (action.type !== Actions.SET_MS_LOGIN_STATE) {
    return currentState;
  }

  return action.payload;
};
