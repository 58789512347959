import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import { setDecisionTrees } from "../actions/decisionTree";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchDecisionTreesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_DECISION_TREES),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/aar/dt/decision_trees`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setDecisionTrees(response.response);
        }),
        catchError((error) => {
          return of(setDecisionTrees(null), errorMessage(error.message));
        })
      );
    })
  );
};
