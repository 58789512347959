import React from "react";
import { useTranslations } from "next-intl";
import TextField from "@mui/material/TextField";
import { fetchTriggerNodesBySentence } from "store/actions/admin";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";

type FetchTriggerNodesInputProps = {
  sentence: string;
  setSentence: (sentence: string) => void;
};

const FetchTriggerNodesInput = ({
  sentence,
  setSentence,
}: FetchTriggerNodesInputProps) => {
  const t = useTranslations();

  const dispatch = useDispatch();

  const fetchTriggerNodes = () => {
    dispatch(fetchTriggerNodesBySentence(sentence));
  };

  return (
    <>
      <Box
        sx={{
          minWidth: 400,
          padding: "8px 0px",
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <TextField
          onChange={(event: any) => {
            setSentence(event.target?.value);
          }}
          color="primary"
          size="small"
          sx={{ width: "100%" }}
          label={t("Admin.trigger_nodes_sentence")}
        />
        <Button
          variant={"contained"}
          onClick={() => fetchTriggerNodes()}
          startIcon={<SearchIcon />}
        >
          Suchen
        </Button>
      </Box>
    </>
  );
};

export default FetchTriggerNodesInput;
