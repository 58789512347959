import { of } from "rxjs";
import { switchMap, map, catchError, debounceTime } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { API_ENDPOINT_HOST } from "./constants";
import { ajax } from "rxjs/ajax";
import { addUtteranceFullfilled } from "../actions/utterances";

const getUtteranceBody = (payload) => {
  let utterKey = payload.id;
  let utteranceData = {
    templates: {},
  };

  utteranceData.templates[utterKey] = {};
  utteranceData.templates[utterKey]["custom"] = {
    data: { allowUserInput: "false" },
  };

  utteranceData.templates[utterKey]["text"] = {};
  utteranceData.templates[utterKey]["text"]["questions"] = [];
  utteranceData.templates[utterKey]["text"]["answers"] = [
    {
      data: payload.data.label,
      flag: 2,
    },
  ];

  utteranceData.templates[utterKey]["status"] = "";
  // utteranceData.templates[utterKey]["topic"] = selectedTopic;
  utteranceData.templates[utterKey]["topic_type"] = "";
  utteranceData.templates[utterKey]["has_activate"] = 0;
  utteranceData.templates[utterKey]["is_new"] = true;

  return utteranceData;
};

export const addUtterance = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.ADD_UTTERANCE),
    debounceTime(500),
    switchMap((action) => {
      let body = getUtteranceBody(action.payload);
      return ajax.patch(
        `${API_ENDPOINT_HOST}/templates?language=${state$.value.language}`,
        JSON.stringify(body),
        {
          "Content-Type": "application/json",
          Authorization: state$.value.keycloakToken,
          Tenant_Realm: state$.value.language,
          Type_Origin: "dashboard",
        }
      );
    }),
    map((response) => {
      return addUtteranceFullfilled(response);
    }),
    catchError((error) => of(errorMessage(error.message)))
  );
};
