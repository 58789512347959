import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import {
  createCannedResponseFulfilled,
  deleteCannedResponseFulfilled,
  setCannedResponse,
  setCannedResponses,
} from "../actions/cannedResponses";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchCannedResponsesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CANNED_RESPONSES),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/canned_responses?language=${state$.value.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setCannedResponses(response.response);
        }),
        catchError((error) => {
          return of(setCannedResponses(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchCannedResponseEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CANNED_RESPONSE),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/canned_response/${action.payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setCannedResponse(response.response.result);
        }),
        catchError((error) => {
          return of(setCannedResponses(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const createCannedResponsesEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_CANNED_RESPONSE),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/canned_response`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload, language: state$.value.language },
      }).pipe(
        concatMap((response) => {
          return of(createCannedResponseFulfilled(), setCannedResponses(null));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const deleteCannedResponseEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_CANNED_RESPONSE),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/dashboard/canned_response/${action.payload}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return deleteCannedResponseFulfilled(action.payload);
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
