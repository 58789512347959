import Actions from "../constants";
import { getKeycloakToken } from "../../lib/util";
import { ReduxState } from "types/index";

export const fetchMSLoginLink = () => ({
  type: Actions.FETCH_MS_LOGIN_LINK,
});

export const setMSLoginLink = (data: ReduxState["MSLoginLink"]) => ({
  type: Actions.SET_MS_LOGIN_LINK,
  payload: data,
});

export const fetchMSLoginState = () => ({
  type: Actions.FETCH_MS_LOGIN_STATE,
});

export const setMSLoginState = (data: ReduxState["MSLoginState"]) => ({
  type: Actions.SET_MS_LOGIN_STATE,
  payload: data,
});

export const unlinkMSAccount = () => ({
  type: Actions.UNLINK_MS_ACCOUNT,
});

export const unlinkMSAccountFulfilled = () => ({
  type: Actions.UNLINK_MS_ACCOUNT_FULFILLED,
});
