import Actions from "../constants";

export const setLabels = (currentState, action) => {
  if (action.type === Actions.SET_LABELS) {
    return action.payload;
  }
  if (action.type === Actions.DELETE_LABEL_FULFILLED) {
    return currentState.filter((e) => e.id !== action.payload);
  }
  return currentState;
};

export const setLabel = (currentState, action) => {
  if (action.type !== Actions.SET_LABEL) {
    return currentState;
  }
  return action.payload;
};

export const setLabelsForConversation = (currentState, action) => {
  if (action.type !== Actions.SET_LABELS_FOR_CONVERSATION) {
    return currentState;
  }
  return action.payload;
};
