import React, { useEffect, useState, memo } from "react";
import { useTranslations } from "next-intl";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

export const CONTENT_TYPE = { MESSAGE: "MESSAGE", MEDIA: "MEDIA" };

const ResponseContentTypeChoice = (props) => {
  const t = useTranslations();

  return (
    <ButtonGroup size="small" sx={{ margin: "auto" }}>
      <Button
        sx={{ fontSize: " 0.775em", py: 0, px: 1 }}
        variant={
          props.selected === CONTENT_TYPE.MESSAGE ? "contained" : "outlined"
        }
        onClick={() => props.handleSelectContentType(CONTENT_TYPE.MESSAGE)}
      >
        {t("text")}
      </Button>
      <Button
        sx={{ fontSize: " 0.775em", py: 0, px: 1 }}
        variant={
          props.selected === CONTENT_TYPE.MEDIA ? "contained" : "outlined"
        }
        onClick={() => props.handleSelectContentType(CONTENT_TYPE.MEDIA)}
      >
        {t("Media")}
      </Button>
    </ButtonGroup>
  );
};

export default memo(ResponseContentTypeChoice);
