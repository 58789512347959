import Actions from "../constants";

export const setCaseStatusValues = (currentState, action) => {
  if (action.type !== Actions.SET_CASE_STATUS_VALUES) {
    return currentState;
  }
  return action.payload;
};

export const setSelectedCaseStatus = (currentState, action) => {
  if (action.type !== Actions.FETCH_CONVERSATIONS) {
    return currentState;
  }
  return action.payload.caseStatus;
};
