import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchCaseStatusValues = () => ({
  type: Actions.FETCH_CASE_STATUS_VALUES,
  token: getKeycloakToken(),
});

export const setCaseStatusValues = (data) => ({
  type: Actions.SET_CASE_STATUS_VALUES,
  payload: data,
});

export const changeConversationCaseStatus = (conversationId, data, event) => ({
  type: Actions.CHANGE_CONVERSATION_CASE_STATUS,
  conversationId: conversationId,
  payload: data,
  event: event,
  token: getKeycloakToken(),
});

export const changeConversationCaseStatusFulfilled = () => ({
  type: Actions.CHANGE_CONVERSATION_CASE_STATUS_FULFILLED,
});
