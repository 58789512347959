import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslations } from "next-intl";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { useStoreState } from "store/hooks";
import dynamic from "next/dynamic";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import * as Actions from "store/actions/admin";
import { setAdminResponse } from "store/actions/admin";
import { useRouter } from "next/router";
import UtteranceAdminEditor from "features/admin/components/UtteranceAdminEditor";
import QnaExtraction from "features/admin/components/QnaExtraction";
import SentenceReformulation from "features/admin/components/SentenceReformulation";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import EditTriggerNodes from "features/admin/components/EditTriggerNodes/EditTriggerNodes";
import { fetchDialogueGraphs } from "store/actions/dialogues";

const FileUpload = (props: any) => {
  const t = useTranslations("Admin");
  return (
    <Button variant="contained" component="label">
      {t("file_upload")}
      <input onChange={props.onChange} type="file" hidden />
    </Button>
  );
};

const AdminDialog = (props: any) => {
  const [actionPayload, setActionPayload] = useState<Object | any>();
  const [infoMessage, setInfoMessage] = useState<string>();
  const dialogueGraphs = useStoreState((state) => state.dialogueGraphs);
  const language = useStoreState((state) => state.language);
  const dispatch = useDispatch();
  const dialogueGraphsRef = useRef(dialogueGraphs);

  useEffect(() => {
    dialogueGraphsRef.current = dialogueGraphs;
  }, [dialogueGraphs]);

  useEffect(() => {
    if (language && !dialogueGraphs) {
      dispatch(fetchDialogueGraphs());
    }
  }, [dialogueGraphs, language, dispatch]);
  const [fileName, setFileName] = useState<any>(null);
  const actions = useMemo(() => {
    return [
      {
        label: "qna_upload",
        action: Actions.qnaUpload,
        additionalInput: (
          <FileUpload
            onChange={(e: any) => {
              setActionPayload({ file: e.target.files[0] });
            }}
          />
        ),
      },
      {
        label: "qna_llm_upload",
        action: Actions.qnaLlmUpload,
        additionalInput: (
          <FileUpload
            onChange={(e: any) => {
              setActionPayload({ file: e.target.files[0] });
            }}
          />
        ),
      },
      {
        label: "graphs_download",
        action: Actions.graphsDownload,
      },
      {
        label: "graphs_upload",
        action: Actions.graphsUpload,
        additionalInput: (
          <FileUpload onChange={(e: any) => onGraphUploadChange(e)} />
        ),
      },
      { label: "qna_download", action: Actions.qnaDownload },
      { label: "qna_delete_all", action: Actions.qnaDeleteAll },
      {
        label: "utterance_upsert",
        action: Actions.utteranceUpsert,
        additionalInput: (
          <UtteranceAdminEditor
            onChange={(e: any) => {
              setActionPayload({
                utterance_key: e.utterance_key,
                body: {
                  text: e.text,
                  custom: { data: { allowUserInput: e.allowUserInput } },
                },
              });
            }}
          />
        ),
      },
      //TODO : not fully implemented yet?
      // { label: "utterance_read", action: Actions.utteranceRead },
      {
        label: "initiate_classic_version",
        action: Actions.initiateClassicVersion,
      },
      {
        label: "disable_classic_version",
        action: Actions.disableClassicVersion,
      },
      { label: "conversations_archive", action: Actions.conversationArchive },
      {
        label: "qna_extraction",
        action: Actions.qnaExtraction,
        additionalInput: (
          <QnaExtraction
            onChange={(e: any) => {
              setActionPayload({
                count: e.count,
                url: e.url,
                upload: e.upload,
              });
            }}
          />
        ),
      },
      {
        label: "reformulation_generation",
        action: Actions.reformulationGeneration,
        additionalInput: (
          <SentenceReformulation
            onChange={(e: any) => {
              setActionPayload({
                count: e.count,
                sentence: e.sentence,
              });
            }}
          />
        ),
      },
      {
        label: "edit_trigger_nodes",
        action: Actions.editSelectedTriggerNodes,
        additionalInput: (
          <EditTriggerNodes
            onChange={(e: any) => {
              // setActionPayload({
              //   count: e.count,
              //   sentence: e.sentence,
              // });
            }}
          />
        ),
      },
      // { label: "download_graphs", action: Actions.conversationArchive },
      // { label: "upload_graphs", action: Actions.conversationArchive },
    ];
  }, []);

  const { open, onClose } = props;
  const t = useTranslations("Admin");
  const router = useRouter();
  const { query } = router;
  const { tenant = "" }: { tenant?: string } = query;
  const [selectedAction, setSelectedAction] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onGraphUploadChange = useCallback(
    (e: any) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e?.target?.result;
        const graphs = JSON.parse(text as string);
        if (!dialogueGraphsRef.current) {
          setInfoMessage(
            t("graph_upload_info", {
              newIds: graphs.map((graph: any) => graph.id).join(", "),
              existingIds: "none",
            })
          );
          return;
        }
        //   check if ids are in dialogueGraphs and set warning message
        const ids = graphs.map((graph: any) => graph.id);
        const existingIds = dialogueGraphsRef.current
          .map((graph: any) => graph.id)
          .filter((id: any) => ids.includes(id));
        const missingIds = ids.filter((id: any) => !existingIds.includes(id));
        setInfoMessage(
          t("graph_upload_info", {
            newIds: missingIds.join(", "),
            existingIds: existingIds.join(", "),
          })
        );
      };
      reader.readAsText(e.target.files[0]);
      setActionPayload({ file: e.target.files[0] });
    },
    [t, dialogueGraphs]
  );

  const adminResponse = useStoreState((state) => state.adminResponse);

  const isBlobResponse = () => {
    return window && "Blob" in window && adminResponse.file instanceof Blob;
  };

  const getBlobFileUrl = () => {
    return (
      window && window.URL && window.URL.createObjectURL(adminResponse.file)
    );
  };
  const handleClose = () => onClose();
  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(actions[selectedAction].action(actionPayload));
  };

  const handleDownloadClick = () => {
    setIsLoading(true);
    dispatch(setAdminResponse(null));
    setActionPayload(undefined);
    setTimeout(() => {
      setIsLoading(false);
      handleClose();
    }, 500);
  };

  useEffect(() => {
    if (adminResponse && adminResponse.response && isBlobResponse()) {
      setIsLoading(false);
    } else if (
      adminResponse &&
      adminResponse.response &&
      (adminResponse.action !== "qna_download" ||
        adminResponse.action === "qna_extraction")
    ) {
      setIsLoading(false);
      handleClose();
    } else if (adminResponse === "error") {
      setIsLoading(false);
    }
  }, [adminResponse]);

  useEffect(() => {
    if (actionPayload && actionPayload.file) {
      setFileName(actionPayload.file.name);
    }
  }, [actionPayload]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": { alignItems: "baseline" },
        "& .MuiPaper-root": {
          minWidth: "400px",
        },
      }}
    >
      <DialogTitle
        sx={{
          ...(isLoading && {
            pointerEvents: "none",
            filter: "blur(1px)",
          }),
        }}
      >
        {t("title")}
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent
        sx={{
          "&>*": {
            ...(isLoading && {
              pointerEvents: "none",
              filter: "blur(1px)",
            }),
          },
        }}
      >
        <Select
          size="small"
          value={selectedAction}
          onChange={(e) => {
            setSelectedAction(e.target.value as number);
            dispatch(setAdminResponse(null));
            setActionPayload(undefined);
          }}
          sx={{ width: "100%" }}
        >
          {actions.map((entry: any, index: number) => (
            <MenuItem key={index} value={index}>
              <Stack direction="row" alignItems="center">
                <Typography>{t(entry.label)}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogContent
        sx={{
          width: "100%",
          "&>*": {
            ...(isLoading && {
              pointerEvents: "none",
              filter: "blur(1px)",
            }),
          },
        }}
      >
        <DialogContentText>
          {t(`${actions[selectedAction].label}_description`)}
        </DialogContentText>
        <DialogContentText>
          {actions[selectedAction]?.additionalInput &&
          (actions[selectedAction].label === "qna_upload" ||
            actions[selectedAction].label === "qna_llm_upload") ? (
            <Box
              sx={{
                boxShadow: "inset 0 2px 14px 0 #d1d1d1",
                backgroundColor: "#e9ecef",
                borderRadius: "4px",
                padding: "4px",
                maxWidth: "80%",
                display: "flex",
                alignItems: "center",
                gap: "12px",
                mt: "8px",
              }}
            >
              {actions[selectedAction].additionalInput}
              {actionPayload && actionPayload.file && fileName ? (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(0,0,0,0.87)",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  {fileName}
                </Typography>
              ) : (
                <Typography
                  sx={{ fontSize: "14px", color: "rgba(0,0,0,0.87)" }}
                >
                  {" "}
                  keine Datei ausgewählt
                </Typography>
              )}
            </Box>
          ) : (
            actions[selectedAction].additionalInput
          )}
        </DialogContentText>
        {infoMessage && actions[selectedAction].label === "graphs_upload" && (
          <DialogContentText
            sx={{ color: "rgba(0,0,0,0.87)", whiteSpace: "pre-line", mt: 2 }}
          >
            {infoMessage}
          </DialogContentText>
        )}
      </DialogContent>
      {adminResponse && (
        <DialogContent>
          {isBlobResponse() && (
            <a
              href={getBlobFileUrl()}
              onClick={() => handleDownloadClick()}
              download={
                actions[selectedAction].label === "graphs_download"
                  ? `dialogue_graphs-${tenant}-${new Date()
                      .toJSON()
                      .slice(0, 10)}.json`
                  : `qna-${tenant}-${new Date().toJSON().slice(0, 10)}.csv`
              }
            >
              {t("download")}
            </a>
          )}
        </DialogContent>
      )}
      <DialogActions
        sx={{
          width: "100%",
          "&>*": {
            ...(isLoading && {
              pointerEvents: "none",
              filter: "blur(1px)",
            }),
          },
        }}
      >
        <Button onClick={handleClose}>{t("cancel")}</Button>
        <Button
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={actions[selectedAction]?.additionalInput && !actionPayload}
        >
          {t("execute")}
        </Button>
      </DialogActions>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "12px",
            backgroundColor: "rgba(255,255,255,0.8)",
          }}
        >
          <CircularProgress
            size={62}
            sx={{
              zIndex: 1,
            }}
          />
          <Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
            {t(`${actions[selectedAction].label}_loading`)}
          </Typography>
        </Box>
      )}{" "}
    </Dialog>
  );
};

export default AdminDialog;
