import Actions from "../constants";

export const setUploadedFileUrl = (currentState, action) => {
  if (action.type !== Actions.UPLOAD_FILE_FULFILLED) {
    return currentState;
  }

  return { url: action.url, formFieldName: action.formFieldName };
};

export const setThemeConfig = (currentState, action) => {
  if (action.type === Actions.UPLOAD_THEME_CONFIG_FULFILLED) {
    return { ...currentState, ...action.newConfig };
  }
  if (action.type !== Actions.FETCH_THEME_CONFIG_FULFILLED) {
    return currentState;
  }

  return action.config;
};
